@import '../../../css/global.variables.scss';

.image-with-overlay-text-listing {
    display: flex;
    flex-direction: column;

    @media (min-width: breakpoint(sm)) {
        flex-direction: row;
    }

    .container {
        .content {
            max-width: 512px;
        }
    }
}

.policies,
.policies-individual-category {
    .image-with-overlay-text-listing {

        @media (max-width: breakpoint(sm)) {
            // max-width overide
            margin-bottom: 1rem;
        }

        @media (min-width: breakpoint(sm)) {
            justify-content: space-between;
        }

        .image-text-cta-container {
            display: flex;
            justify-content: center;

            .inner-content {
                margin: 0;
            }

            @media (min-width: breakpoint(sm)) {
                width: 32.9%;
            }

            &:first-of-type {
                justify-content: flex-start;
            }

            &:nth-of-type(3n) {
                justify-content: flex-end;
            }
        }
    }
}


.careers-lp {
    .image-with-overlay-text-listing {
        margin-bottom: 3rem;

        @media screen and (min-width: breakpoint(lg)) {
            margin-bottom: 6rem;
        }

        @media (max-width: breakpoint(sm)) {
            // max-width overide
            margin-bottom: 1rem;
        }

        @media (min-width: breakpoint(sm)) {
            justify-content: space-between;
        }

        .image-text-cta-container {
            display: flex;
            justify-content: center;

            @media (min-width: breakpoint(sm)) {
                width: 32.9%;
            }

            &:first-of-type {
                justify-content: flex-start;
            }

            &:nth-of-type(3n) {
                justify-content: flex-end;
            }
        }
    }
}