// ********************************************************
// ********************************************************
// **
// **  WYSIWYG 
// **
// **  1. Place any css styles here that the markup is coming from a wysiwyg  
// **  2. Similar to how "shortcodes" in WP would work..
// **
// ********************************************************
// ********************************************************


.full-width-cta-with-background {
    width: 100%;
    background: rgba(103, 138, 34, 0.15);
    padding: 3rem 2rem 3rem 2rem;

    h5,
    a {
        margin: 0;
        padding: 0;
        color: #313830;
        font-weight: 500;
    }

    p {
        margin: 1rem 0rem;
    }
}