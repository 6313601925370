// ********************************************************
// ********************************************************
// **
// **  general component styles
// **
// ********************************************************
// ********************************************************



.box-cta {
  display: block;
  color: white;
  padding: 1.25rem 1.25rem 1.25rem 1.25rem;

  &:hover {
    text-decoration: none;
  }

  div {
    color: white;
    line-height: 2rem;

    &:hover {
      text-decoration: none;
    }
  }

  .title {
    display: block;
    font-size: px-to-rem(16px);
    margin-bottom: .5rem;
    line-height: 26px;
    max-width: 90%;

    @media (min-width: breakpoint(sm)) {
      line-height: 30px;
      font-size: px-to-rem(24px);
    }

    @media (min-width: breakpoint(md)) {
      line-height: 36px;
      font-size: px-to-rem(32px);
    }

    @media (min-width: breakpoint(lg)) {
      margin-bottom: 30px;
    }
  }

  .sub-title {
    display: block;
    margin-bottom: 1.5rem;
    font-size: px-to-rem(18px);
    font-weight: 500;
  }

  .custom-block {
    width:100%;
    display: block;
    font-size: px-to-rem(14px);
    line-height: 18px;
    text-align: left;

    .custom-style{
      height: 100%;
    }

    @media screen and (min-width: 0px) and (max-width: 400px) {
      display: none;
    }

    @media screen and (min-width: 401px) and (max-width: 1024px) {
      display: none;
    }
  }

  .content {
    display: flex;
    font-size: px-to-rem(14px);
    line-height: 18px;

    @media(min-width: breakpoint(sm)) {
      display: block;
    }

    @media (min-width: breakpoint(md)) {
      font-size: px-to-rem(16px);
      line-height: 22px;
      margin-bottom: 30px;
    }
  }

  .link-text {

    @media (min-width: breakpoint(sm)) {
      display: inline;
    }
  }
  .card__minnow-footer{
    display: flex;
    flex: 1;
    align-items: flex-end;
  }

  .bottom-link {
    bottom: 10%;

    @media screen and (max-width: breakpoint(sm)) {
      position: static;
    }
  }
}


.small-box-cta {

  @media (min-width: breakpoint(sm)) {
    // min-width: 112px;
    min-height: 112px;
    padding-top: 1.5rem;
    padding-left: .5rem;
    padding-right: .5rem;
  }

  @media(min-width: breakpoint(md)) {
    // min-width: 200px;
    min-height: 200px;
    padding-top: 2.5rem;
    padding-left: .75rem;
    padding-right: 1.5rem;
  }

  @media(min-width: breakpoint(lg)) {
    // min-width: 210px;
    min-height: 210px;
    padding-left: .75rem;
    padding-right: 1.5rem;
  }

  .title {
    display: flex;
    align-items: baseline;
    margin-bottom: 0;
    line-height: 18px;
    font-weight: 500;

    @media (min-width: breakpoint(sm)) {
      font-size: px-to-rem(16px);
      line-height: 18px;
    }

    @media (min-width: breakpoint(md)) {
      line-height: 25px;
      font-size: px-to-rem(22px);
    }

    @media (min-width: breakpoint(lg)) {
      font-size: px-to-rem(28px);
      line-height: 34px;
    }

    .arrow {

      // bad code -- needed to get this done for qa
      @media (min-width: breakpoint(sm)) {
        font-size: px-to-rem(16px) !important;
      }

      @media (min-width: breakpoint(md)) {
        font-size: px-to-rem(20px) !important;
      }

      @media (min-width: breakpoint(lg)) {
        font-size: px-to-rem(24px) !important;
      }
    }
  }


  .link-text {
    display: none;
  }

  .bottom-link {
    @media (min-width: breakpoint(sm)) {
      display: none;
    }
  }
}


// ********************************************************
// ********************************************************
// **
// **  component specific styles
// **
// ********************************************************
// ********************************************************


.hero-container .box-cta {

  @media (min-width: breakpoint(md)) {
    min-width: 150px;
    min-height: 150px;

    .title {
      font-size: px-to-rem(24px);
    }
  }

  @media (min-width: breakpoint(lg)) {
    min-width: 225px;
    min-height: 225px;

    p,
    a,
    div:not(.title) {
      font-size: px-to-rem(18px);
    }

    .title {
      font-size: px-to-rem(28px);
    }
  }
}

.filtered-cta-grid .box-cta {
  display: flex;
  height: 100%;

  @media (max-width: breakpoint(sm)) {
    justify-content: space-between;
  }

  @media (min-width: breakpoint(sm)) {
    flex-direction: column;
    align-items: flex-start;
  }

  .title {

    @media (max-width: breakpoint(sm)) {
      margin-bottom: 0;
    }
  }

  .arrow {
    font-size: px-to-rem(24px);
    line-height: 0;

    @media (min-width: breakpoint(sm)) {
      margin-left: 5px;
    }
  }

}

.filtered-cta-grid .box-cta:not(.small-box-cta) {


  @media (min-width: breakpoint(sm)) {
    min-height: 232px;
  }

  @media (min-width: breakpoint(md)) {
    min-height: 303px;
  }

  @media (min-height: breakpoint(lg)) {
    min-height: 339px;
  }
}

.filtered-cta-grid.bg-img.only-small-ctas {
  .title .arrow {
    display: none !important;
  }
}


// ********************************************************
// ********************************************************
// **
// **  template / page specific styles
// **
// ********************************************************
// ********************************************************


.industries .box-cta {
  .bottom-link {
    @media (min-width: breakpoint(sm)) {
      display: none;
    }
  }
}

.consulting-category .box-cta {
  // padding: 1.25rem 1.25rem 2.5rem 1.25rem;

  @media screen and (min-width: breakpoint(sm)) {
    // padding: 1.25rem;
    padding: 1.25rem 1.25rem 2.5rem 1.25rem;
  }
}

.consulting-secondary-b .box-cta,
.technology-services-lp .box-cta {
  position: relative;

  @media (min-width: breakpoint(md)) {
    min-height: 303px;
  }

  @media (min-width: breeakpoint(lg)) {
    min-height: 330px;
  }

  .title {
    font-size: px-to-rem(20px);

    @media (min-width: breakpoint(sm)) {
      line-height: 24px;
      font-size: px-to-rem(24px);
    }

    @media (min-width: breakpoint(md)) {
      line-height: 30px;
      font-size: px-to-rem(28px);
    }
  }

  .content {
    display: block;
    max-width: 90%;

    @media (max-width: breakpoint(md)) {
      margin-bottom: 0;
    }

    @media (min-width: breakpoint(sm)) {
      max-width: 100%;
    }
  }

  .link-text {
    @media (min-width: breakpoint(sm)) {
      display: none;
    }

    @media (min-width: breakpoint(md)) {
      display: inline;
    }
  }

  .arrow {
    font-size: 30px;
    position: absolute;
    top: .5rem;
    right: 1.25rem;

    @media (min-width: breakpoint(md)) {
      position: static;
      margin-left: 5px;
      font-size: 16px;
    }
  }
}

.technology-services-lp .box-cta {

  @media (min-width: breakpoint(lg)) {
    // creative has this at 278px
    // it looks smaller on here becuase we are using less place holder content
    // than creative
    min-height: 270px;
  }

  @media screen and (min-width: breakpoint(md)) {
    // I will need to add a link @ the bottoms of the box cta for
    // this form factor only -- technically creative
    // has this at 304px
    min-height: 270px;
  }

  .bottom-link {

    @media (min-width: breakpoint(lg)) {
      display: none;
    }
  }

  .title {

    @media (min-width: breakpoint(lg)) {
      margin-bottom: .75rem;
    }


    @media (min-width: breakpoint(sm)) {
      margin-bottom: 1rem;
    }
  }
}

.staffing-lp .box-cta {
  a {
    color: #fff;
  }


  .content {
    display: none;

    @media (min-width: breakpoint(lg)) {
      display: block;
    }
  }

  .title {
    font-weight: 500;
    @media (min-width: breakpoint(lg)) {
      font-size: px-to-rem(30px);
      line-height: 30px;
      margin-bottom: 1.25rem;
    }
  }

  .content {
    line-height: 22px;
  }

  .sub-title {
    font-weight: 600;

    @media (min-width: breakpoint(lg)) {
      margin-bottom: 1.25rem;
    }
  }

  .link-text,
  .arrow {

    @media (min-width: breakpoint(lg)) {
      display: none;
    }
  }
}

.bg-color-dark {
  background-color: #5F7E19;
}

.bg-color-light {
  background-color: #88AA3C;
}
