
.custom-border {

  border-width: 15px;
  border-style: solid;

  &.overlay {
    border-color: rgba(0, 0, 0, 0.35);
  }

  &.action-item {
    border-color: #e9f7ff;
  }

  &.all {
    border-color: #707070;
  }

  &.commercialinstitutional {
    border-color: #009cbd;

    &.transparent {
      border-color: rgba(0, 156, 189, .85)
    }
  }

  &.life-sciences {
    border-color: rgba(133, 85, 163, 1);

    &.transparent {
      border-color: rgba(133, 85, 163, .85);
    }
  }

  &.energy {
    border-color: rgba(0, 174, 155, 1);

    &.transparent {
      border-color: rgba(0, 174, 155, .85);
    }
  }

  &.mining {
    border-color: rgba(0, 50, 160, 1);

    &.transparent {
      border-color: rgba(0, 50, 160, .85);
    }
  }

  &.manufacturing {
    border-color: rgba(0, 114, 206, 1);

    &.transparent {
      border-color: rgba(0, 114, 206, .85);
    }
  }

  &.industrial,
  &.non-industrial {
    border-color: rgba(239, 99, 35, 1);

    &.transparent {
      border-color: rgba(239, 99, 35, .85);
    }
  }

  &.breeze {
    border-color: rgba(10, 45, 71, 1);

    &.transparent {
      border-color: rgba(10, 45, 71, 1);
    }
  }

  &.ind {
    border-color: #707070;

    &.transparent {
      border-color: rgba(112, 112, 112, 0.85);
    }
  }

  &.policies {
    border-color: #3caa6b;

    &.transparent {
      border-color: rgba(60, 170, 108, 0.73);
    }
  }

  &.executive {
    border-color: rgba(20, 153, 87, 1);

    &.transparent {
      border-color: rgba(20, 153, 87, 0.85);
    }
  }

  &.op-leaders {
    border-color: rgba(17, 114, 61, 1);

    &.transparent {
      border-color: rgba(17, 114, 61, 0.85);
    }
  }

  &.about {
    border-color: rgba(49, 56, 48, 1);

    &.transparent {
      border-color: rgba(49, 56, 48, 0.85);
    }
  }

  &.about_2 {
    border-color: #17964a;

    &.transparent {
      border-color: rgba(23, 150, 74, 0.85);
    }
  }

  &.subscribe {
    border-color: rgba(49, 56, 48, 1);

    &.transparent {
      border-color: rgba(49, 56, 48, 0.85);
    }
  }

  &.contact-us {
    border-color: rgba(49, 56, 48, 1);

    &.transparent {
      border-color: rgba(49, 56, 48, 0.85);
    }
  }

  &.locations {
    border-color: #28aff7;

    &.transparent {
      border-color: rgba(40, 175, 247, 0.85);
    }
  }

  &.hexdam {
    border-color: #9fabbb;
  }

  &.vexdam {
    border-color: #0072ce;
  }

  &.blast {
    border-color: #3E94B4;
  }

  // .eng, .job, and .ex are the same color -- maybe merge into one class later?
  // -- waiting to see how api is first

  &.eng,
  &.engineering {
    border-color: rgb(239, 96, 8);

    &.transparent {
      border-color: rgba(239, 96, 8, 0.85);

      &.lighter {
        border-color: rgba(239, 96, 8, 0.75);
      }
    }
  }

  &.job {
    border-color: rgb(241, 118, 42);

    &.transparent {
      border-color: rgba(241, 118, 42, 0.85);

      &.lighter {
        border-color: rgba(241, 118, 42, 0.75);
      }

      &.sm-up-lighter {
        @media (min-width: breakpoint(sm)) {
          border-color: rgba(241, 118, 42, 0.75);
        }
      }
    }
  }

  &.job-2 {
    border-color: rgb(80, 131, 233);

    &.transparent {
      border-color: rgba(80, 131, 233, 0.85);

      &.lighter {
        border-color: rgba(80, 131, 233, 0.75);
      }

      &.sm-up-lighter {
        @media (min-width: breakpoint(sm)) {
          border-color: rgba(80, 131, 233, 0.75);
        }
      }
    }
  }

  &.explosion,
    // NL # TASK 40
  &.ex {
    border-color: rgba(232, 119, 34, 1);

    &.transparent {
      border-color: rgba(232, 119, 34, .85);
    }
  }

  // Training
  &.training {
    border-color: rgb(39, 98, 78);

    &.transparent {
      border-color: rgba(39, 98, 78, 0.85);
    }
  }

  &.industry {
    border-color: #ef6323;

    &.transparent {
      border-color: rgba(239, 99, 35, 0.85);
    }
  }

  &.software-courses {
    border-color: #05af9a;

    &.transparent {
      border-color: rgba(7, 175, 154, 0.85);
    }
  }

  &.software {
    border-color: rgba(112, 112, 112, 1);

    &.transparent {
      border-color: rgba(112, 112, 112, .85);
    }
  }

  &.category {
    border-color: #0a7da7;

    &.transparent {
      border-color: rgba(10, 125, 167, 0.85);
    }
  }

  &.train {
    border-color: #05af9a;

    &.transparent {
      border-color: rgba(7, 175, 154, 0.85);
    }
  }

  &.training {
    border-color: #05af9a;

    &.transparent {
      border-color: rgba(7, 175, 154, 0.85);
    }
  }

  &.air-dispersion--continuous-release,
  &.air-dispersion--continuous,
  &.air-dispersion,
    // NL # TASK 40
  &.gen {
    border-color: rgba(0, 61, 165, 1);

    &.transparent {
      border-color: rgba(0, 61, 165, .85);
    }
  }

  &.env,
  &.environmental-health-safety {
    border-color: rgb(136, 170, 60);

    &.transparent {
      border-color: rgba(136, 170, 60, 1);
    }

    &.darker {
      border-color: rgba(95, 126, 25, 1);
    }
  }

  &.sci,
  &.science {
    border-color: rgb(74, 155, 184);

    &.transparent {
      border-color: rgba(74, 155, 184, 0.85);
    }
  }

  &.hazard,
    // NL # TASK 40
  &.haz {
    border-color: #da291c;

    &.transparent {
      border-color: rgba(218, 41, 28, 0.85);
    }
  }

  &.air-dispersion--continuous-release,
  &.air-dispersion--continuous,
  &.air-dispersion,
    // NL # TASK 40
  &.airDisCon {
    border-color: rgba(0, 61, 165, 1);

    &.transparent {
      border-color: rgba(0, 61, 165, .85);
    }
  }

  &.air-dispersion--data-visualization,  
  &.data-analysis,
  &.airDisData {
    border-color: rgba(0, 181, 226, 1);

    &.transparent {
      border-color: rgba(0, 181, 226, .85);
    }
  }

  &.health-risk,
    // NL # TASK 40
  &.health {
    border-color: rgba(80, 158, 47, 1);

    &.transparent {
      border-color: rgba(80, 158, 47, .85);
    }
  }

  &.tanks {
    border-color: rgba(99, 102, 106, 1);

    &.transparent {
      border-color: rgba(99, 102, 106, .85);
    }
  }

  &.tech,
  &.technology {
    border-color: rgb(10, 45, 71);

    &.transparent {
      border-color: rgba(10, 45, 71, 0.85);
    }
  }

  &.our-approach {
    border-color: rgba(239, 96, 8, 1);

    &.transparent {
      border-color: rgba(239, 96, 8, 0.85);
    }
  }

  &.staffing {
    border-color: rgb(80, 131, 233);

    &.transparent {
      border-color: rgba(80, 131, 233, 0.85);
    }
  }

  // NL # TASK 40
  &.soft {
    border-color: #00b4cc;

    &.transparent {
      border-color: rgba(0, 180, 204, 0.85);
    }
  }

  &.industries {
    border-color: rgb(40, 175, 247);

    &.transparent {
      border-color: rgba(40, 175, 247, 0.85);
    }
  }

  &.secondary-a {
    border-color: rgba(0, 114, 206, 1);

    &.transparent {
      border-color: rgba(0, 114, 206, 0.85);
    }
  }
}

.custom-background {

  &.overlay {
    border: 5px solid rgba(0, 0, 0, 0.35);
  }

  &.action-item {
    border: 5px solid #e9f7ff;
  }

  &.all {
    border: 5px solid #707070;
  }

  &.commercialinstitutional {
    border: 5px solid #009cbd;

    &.transparent {
      border: 5px solid rgba(0, 156, 189, .85)
    }
  }

  &.life-sciences {
    border: 5px solid rgba(133, 85, 163, 1);

    &.transparent {
      border: 5px solid rgba(133, 85, 163, .85);
    }
  }

  &.energy {
    border: 5px solid rgba(0, 174, 155, 1);

    &.transparent {
      border: 5px solid rgba(0, 174, 155, .85);
    }
  }

  &.mining {
    border: 5px solid rgba(0, 50, 160, 1);

    &.transparent {
      border: 5px solid rgba(0, 50, 160, .85);
    }
  }

  &.manufacturing {
    border: 5px solid rgba(0, 114, 206, 1);

    &.transparent {
      border: 5px solid rgba(0, 114, 206, .85);
    }
  }

  &.industrial,
  &.non-industrial {
    border: 5px solid rgba(239, 99, 35, 1);

    &.transparent {
      border: 5px solid rgba(239, 99, 35, .85);
    }
  }

  &.breeze {
    border: 5px solid rgba(10, 45, 71, 1);

    &.transparent {
      border: 5px solid rgba(10, 45, 71, 1);
    }
  }

  &.ind {
    border: 5px solid #707070;

    &.transparent {
      border: 5px solid rgba(112, 112, 112, 0.85);
    }
  }

  &.policies {
    border: 5px solid #3caa6b;

    &.transparent {
      border: 5px solid rgba(60, 170, 108, 0.73);
    }
  }

  &.executive {
    border: 5px solid rgba(20, 153, 87, 1);

    &.transparent {
      border: 5px solid rgba(20, 153, 87, 0.85);
    }
  }

  &.op-leaders {
    border: 5px solid rgba(17, 114, 61, 1);

    &.transparent {
      border: 5px solid rgba(17, 114, 61, 0.85);
    }
  }

  &.about {
    border: 5px solid rgba(49, 56, 48, 1);

    &.transparent {
      border: 5px solid rgba(49, 56, 48, 0.85);
    }
  }

  &.about_2 {
    border: 5px solid #17964a;

    &.transparent {
      border: 5px solid rgba(23, 150, 74, 0.85);
    }
  }

  &.subscribe {
    border: 5px solid rgba(49, 56, 48, 1);

    &.transparent {
      border: 5px solid rgba(49, 56, 48, 0.85);
    }
  }

  &.contact-us {
    border: 5px solid rgba(49, 56, 48, 1);

    &.transparent {
      border: 5px solid rgba(49, 56, 48, 0.85);
    }
  }

  &.locations {
    border: 5px solid #28aff7;

    &.transparent {
      border: 5px solid rgba(40, 175, 247, 0.85);
    }
  }

  &.hexdam {
    border: 5px solid #9fabbb;
  }

  &.vexdam {
    border: 5px solid #0072ce;
  }

  &.blast {
    border: 5px solid #3E94B4;
  }

  // .eng, .job, and .ex are the same color -- maybe merge into one class later?
  // -- waiting to see how api is first

  &.eng,
  &.engineering {
    border: 5px solid rgb(239, 96, 8);

    &.transparent {
      border: 5px solid rgba(239, 96, 8, 0.85);

      &.lighter {
        border: 5px solid rgba(239, 96, 8, 0.75);
      }
    }
  }

  &.job {
    border: 5px solid rgb(241, 118, 42);

    &.transparent {
      border: 5px solid rgba(241, 118, 42, 0.85);

      &.lighter {
        border: 5px solid rgba(241, 118, 42, 0.75);
      }

      &.sm-up-lighter {
        @media (min-width: breakpoint(sm)) {
          border: 5px solid rgba(241, 118, 42, 0.75);
        }
      }
    }
  }

  &.job-2 {
    border: 5px solid rgb(80, 131, 233);

    &.transparent {
      border: 5px solid rgba(80, 131, 233, 0.85);

      &.lighter {
        border: 5px solid rgba(80, 131, 233, 0.75);
      }

      &.sm-up-lighter {
        @media (min-width: breakpoint(sm)) {
          border: 5px solid rgba(80, 131, 233, 0.75);
        }
      }
    }
  }

  &.explosion,
    // NL # TASK 40
  &.ex {
    border: 5px solid rgba(232, 119, 34, 1);

    &.transparent {
      border: 5px solid rgba(232, 119, 34, .85);
    }
  }

  // Training
  &.training {
    border: 5px solid rgb(39, 98, 78);

    &.transparent {
      border: 5px solid rgba(39, 98, 78, 0.85);
    }
  }

  &.industry {
    border: 5px solid #ef6323;

    &.transparent {
      border: 5px solid rgba(239, 99, 35, 0.85);
    }
  }

  &.software-courses {
    border: 5px solid #05af9a;

    &.transparent {
      border: 5px solid rgba(7, 175, 154, 0.85);
    }
  }

  &.software {
    border: 5px solid rgba(112, 112, 112, 1);

    &.transparent {
      border: 5px solid rgba(112, 112, 112, .85);
    }
  }

  &.category {
    border: 5px solid #0a7da7;

    &.transparent {
      border: 5px solid rgba(10, 125, 167, 0.85);
    }
  }

  &.train {
    border: 5px solid #05af9a;

    &.transparent {
      border: 5px solid rgba(7, 175, 154, 0.85);
    }
  }

  &.training {
    border: 5px solid #05af9a;

    &.transparent {
      border: 5px solid rgba(7, 175, 154, 0.85);
    }
  }

  &.air-dispersion--continuous-release,
  &.air-dispersion--continuous,
  &.air-dispersion,
    // NL # TASK 40
  &.gen {
    border: 5px solid rgba(0, 61, 165, 1);

    &.transparent {
      border: 5px solid rgba(0, 61, 165, .85);
    }
  }

  &.env,
  &.environmental-health-safety {
    border: 5px solid rgb(136, 170, 60);

    &.transparent {
      border: 5px solid rgba(136, 170, 60, 1);
    }

    &.darker {
      border: 5px solid rgba(95, 126, 25, 1);
    }
  }

  &.sci,
  &.science {
    border: 5px solid rgb(74, 155, 184);

    &.transparent {
      border: 5px solid rgba(74, 155, 184, 0.85);
    }
  }

  &.hazard,
    // NL # TASK 40
  &.haz {
    border: 5px solid #da291c;

    &.transparent {
      border: 5px solid rgba(218, 41, 28, 0.85);
    }
  }

  &.air-dispersion--continuous-release,
  &.air-dispersion--continuous,
  &.dispersion,
  &.air-dispersion,
    // NL # TASK 40
  &.airDisCon {
    border: 5px solid rgba(0, 61, 165, 1);

    &.transparent {
      border: 5px solid rgba(0, 61, 165, .85);
    }
  }

  &.air-dispersion--data-visualization,  
  &.data-analysis,
  &.airDisData {
    border: 5px solid rgba(0, 181, 226, .85);

    &.transparent {
      border: 5px solid rgba(0, 181, 226, .85);
    }
  }

  &.health-risk,
    // NL # TASK 40
  &.health {
    border: 5px solid rgba(80, 158, 47, 1);

    &.transparent {
      border: 5px solid rgba(80, 158, 47, .85);
    }
  }

  &.tanks {
    border: 5px solid rgba(99, 102, 106, 1);

    &.transparent {
      border: 5px solid rgba(99, 102, 106, .85);
    }
  }

  &.tech,
  &.technology {
    border: 5px solid rgb(10, 45, 71);

    &.transparent {
      border: 5px solid rgba(10, 45, 71, 0.85);
    }
  }

  &.our-approach {
    border: 5px solid rgba(239, 96, 8, 1);

    &.transparent {
      border: 5px solid rgba(239, 96, 8, 0.85);
    }
  }

  &.staffing {
    border: 5px solid rgb(80, 131, 233);

    &.transparent {
      border: 5px solid rgba(80, 131, 233, 0.85);
    }
  }

  // NL # TASK 40
  &.soft {
    border: 5px solid #00b4cc;

    &.transparent {
      border: 5px solid rgba(0, 180, 204, 0.85);
    }
  }

  &.industries {
    border: 5px solid rgb(40, 175, 247);

    &.transparent {
      border: 5px solid rgba(40, 175, 247, 0.85);
    }
  }

  &.secondary-a {
    border: 5px solid rgba(0, 114, 206, 1);

    &.transparent {
      border: 5px solid rgba(0, 114, 206, 0.85);
    }
  }
}