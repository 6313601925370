@import '../../css/global.variables.scss';

.empty-table {
  .outerDiv {

  }

  .first-line {
    color: #EF6008;
  }

  .second-line {
    color: #9f9f9f;
    font-size: 24px;
    font-weight: 200;
  }

  .third-line {
    margin-top: 30px;
    font-weight: 400;
    color: #2367B1;
  }
}
