@import "../../../css/global.variables.scss";

.payment-container {
  width: 50%;
  margin: 0 auto;

  @media screen and (max-width: breakpoint(sm)) {
    width: 100%;
    margin: 0;
  }

  .payment-inputs {
    display: flex;
    justify-content: space-evenly;
    border: 1px solid #0032a0;
    padding: 1rem;
    text-align: center;
    margin-bottom: 1rem;

    input {
      margin-right: 1rem;
    }
  }

  .button {
    text-align: center;
    margin-top: 3rem;
  }

  .outer-payment-container {
    fieldset {
      //   border: 1px solid #0032a0;
      padding: 2rem;
      border-radius: 6px;
    }

    fieldset legend {
      width: auto;
      margin: 0 auto;
      padding: 0 10px;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: #0032a0;
    }

    fieldset legend + * {
      clear: both;
    }

    .card-only {
      display: block;
    }

    .combine-row {
      display: -ms-flexbox;
      display: flex;
      margin: 0 0 10px;

      @media screen and (max-width: breakpoint(sm)) {
        display: block;
      }
    }

    .field {
      position: relative;
      width: 100%;
      margin-right: 0.75rem;

      @media screen and (max-width: breakpoint(sm)) {
        margin-right: 0;
      }
    }

    label {
      // width: 100%;
      font-size: 13px;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 0;
      color: #313830;
    }

    input {
      width: 100%;
      border: 2px solid #c5cac5;
      padding: 7px 7px 7px 19px;
      border-radius: 19px;
      color: #313830;
      outline: none;
      transition: border-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    input::-webkit-input-placeholder {
      color: #000000;
    }

    input::-moz-placeholder {
      color: #000000;
    }

    input:-ms-input-placeholder {
      color: #000000;
    }

    input.StripeElement--focus,
    input:focus {
      border-color: #f17629;
    }

    input.StripeElement--invalid {
      border-color: #f17629;
    }

    input:-webkit-autofill,
    select:-webkit-autofill {
      -webkit-text-fill-color: #f17629;
      transition: background-color 100000000s;
      -webkit-animation: 1ms void-animation-out;
    }

    .StripeElement--webkit-autofill {
      background: transparent !important;
    }

    // input,
    // button,
    // select {
    //   -webkit-animation: 1ms void-animation-out;
    //   -webkit-appearance: none;
    //   -moz-appearance: none;
    //   appearance: none;
    //   outline: none;
    //   border-top: none;
    //   border-right: none;
    //   border-left: none;
    //   border-radius: 0;
    //   color: #2f9cbd;
    // }

    select.input,
    select:-webkit-autofill {
      background-image: url('data:image/svg+xml;utf8,<svg width="10px" height="5px" viewBox="0 0 10 5" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path fill="#fff" d="M5.35355339,4.64644661 L9.14644661,0.853553391 L9.14644661,0.853553391 C9.34170876,0.658291245 9.34170876,0.341708755 9.14644661,0.146446609 C9.05267842,0.0526784202 8.92550146,-2.43597394e-17 8.79289322,0 L1.20710678,0 L1.20710678,0 C0.930964406,5.07265313e-17 0.707106781,0.223857625 0.707106781,0.5 C0.707106781,0.632608245 0.759785201,0.759785201 0.853553391,0.853553391 L4.64644661,4.64644661 L4.64644661,4.64644661 C4.84170876,4.84170876 5.15829124,4.84170876 5.35355339,4.64644661 Z" id="shape"></path></svg>');
      background-position: 100%;
      background-size: 10px 5px;
      background-repeat: no-repeat;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 20px;
    }

    // button {
    //     display: block;
    //     width: 100%;
    //     height: 40px;
    //     margin: 20px 0 0;
    //     background-color: #fff;
    //     border-radius: 6px;
    //     color: #9169d8;
    //     font-weight: 500;
    //     cursor: pointer;
    // }

    // button:active {
    //     background-color: #cdd0f8;
    // }

    .card-field {
      margin-top: 2rem;
    }

    .SubmitButton {
      display: block;
      font-size: 16px;
      width: calc(100% - 30px);
      height: 40px;
      margin: 40px 15px 0px;
      background-color: #f17629;
      box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06),
        0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #f17629;
      border-radius: 4px;
      color: #fff;
      font-weight: 600;
      cursor: pointer;
      transition: all 100ms ease-in-out;
      will-change: transform, background-color, box-shadow;
    }

    .SubmitButton:active {
      background-color: #cc6423;
      box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06),
        0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #cc6423;
      transform: scale(0.99);
    }

    .SubmitButton.SubmitButton--error {
      transform: translateY(15px);
    }

    .SubmitButton.SubmitButton--error:active {
      transform: scale(0.99) translateY(15px);
    }

    .SubmitButton:disabled {
      opacity: 0.5;
      cursor: default;
      background-color: #7795f8;
      box-shadow: none;
    }

    .ErrorMessage {
      color: red;
      display: flex;
      justify-content: center;
      padding: 0 15px;
      font-size: 13px;
      margin-top: 30px;
      width: 100%;
      transform: translateY(-15px);
      animation: fade 150ms ease-out;
      animation-delay: 50ms;
      animation-fill-mode: forwards;
      will-change: opacity, transform;
    }

    .ErrorMessage svg {
      margin-right: 10px;
      cursor: pointer;
    }

    .Result {
      margin-top: 50px;
      text-align: center;
      animation: fade 200ms ease-out;
    }

    .ResultTitle {
      color: #313830;
      font-weight: 500;
      margin-bottom: 8px;
      font-size: 17px;
      text-align: center;
    }

    .ResultMessage {
      color: #313830;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 25px;
      line-height: 1.6em;
      text-align: center;
    }
  }
}
.react-strap-elements {
  width: 100%;
  border: 2px solid #c5cac5;
  padding: 10px 10px 10px 19px;
  border-radius: 19px;
  color: #313830;
  outline: none;
  transition: border-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.StripeElement--focus {
  border-color: #f17629 !important;
}
