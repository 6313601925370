@import "../../../../css/global.variables.scss";

.location-news-container {
    padding-bottom: 2rem;
    .inner-news-container {
        margin-top: 2rem;
        margin-bottom: 2rem;
        border-top: 4px solid #EBEBE8;
    }

    .title {
        font-weight: 500;
        margin-top: 1rem;
        margin-bottom: 2rem;

        @media screen and (min-width: breakpoint(md)) {
            font-size: px-to-rem(22px);
        }
    }

    .content {
        p {
            margin-bottom: 0;
        }

        a {
            color: #313830;
            font-weight: 500;

            &:hover {
                text-decoration: underline;
            }

        }

        margin-bottom: 2rem;
    }

    a.bottom-link {
        font-size: px-to-rem(18px);
        color: #313830;
        font-weight: 500;
    }
}

.newspointer {
    cursor: pointer;
}