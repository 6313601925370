@import "../../../../css/global.variables.scss";

.subscribe-inner-container {
  background: #E9F7FF;
  padding: 2rem;
  display: grid;
  margin-bottom: 2rem;

  @media screen and (max-width: breakpoint(sm)) {
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
    margin-bottom: 2rem;
  }

  .form-title {
    color: #313830;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 1rem;
    font-size: px-to-rem(26px);
  }

  .subscribe-form {
    display: flex;
    display: -ms-flex;

    .help {
      color: red;
    }

    @media screen and (max-width: breakpoint(md)) {
      display: block;
    }

    .left {
      -ms-flex: 1;
      @media screen and (min-width: 1250px) {
        padding-right: 2rem;
      }


      @media screen and (max-width: breakpoint(sm)) {
        padding-right: 0;
      }
    }

    .subscribe-right {
      -ms-flex: 2;
    }

    .trinity-select-list {
      .select-wrapper {
        margin-bottom: .5rem;
      }
      margin-bottom: .6rem;
    }


    input {
      border: 1px solid #707070;

      margin-bottom: .75rem;
      padding: .5rem;

      &::placeholder {
        font-weight: 500;
      }
    }

    select {
      display: block;
      width: 100%;
      padding: .5rem .5rem .5rem .75rem;
      font-size: 16px;
      background: #fff;
      border: 1px solid #707070;
    }


    textarea {
      display: block;
      margin-top: 1rem;
      margin-bottom: 2rem;
      width: 100%;
      padding: .75rem;
      height: 10rem;
    }


    .upload {
      input {
        border: 0;
        display: none;
      }


      .upload-link {
        cursor: pointer;

        .custom-button {
          background: #0F2748;
          border: 2px solid #0F2748;
          color: #fff;

          &:hover {
            background: #fff;
            color: #0F2748;
          }
        }
      }
    }

    .recaptcha {
      margin-top: 2rem;
      margin-bottom: 2rem;
      width: 100%;
    }

    .link {
      margin-top: 1rem;
      text-align: center;
    }


  }


  .multiselect-padding-rule {
    padding: 0;

    @media screen and (min-width: breakpoint(lg)) {
      padding-left: 1rem;
    }
  }

}

.react-dropdown-select-dropdown-handle {
  margin: 0 7px !important;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 8px solid #737373;
  color: #707070;

  svg {
    display: none;
  }
}

.multi-select-list {
  display: block;
  width: 100%;
  padding: .5rem .5rem .5rem .75rem;
  font-size: 16px;
  background: #fff;
  margin: 0 !important;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 8px solid #737373;

  svg {
    display: none;
  }
}

.react-dropdown-select-item {
  display: block;
  width: 100%;
  padding: .5rem .5rem .5rem .75rem;
  font-size: 16px;
  background: #fff;
  border: 1px solid #707070;
}

.react-dropdown-select-dropdown .react-dropdown-select-item {
  padding: .25rem .5rem;
  border: none;
  color: #707070;
}

.react-dropdown-select-item:hover {
  color: white !important;
  line-height: 1;
  background-color: #F0600E !important;
}

.react-dropdown-select-item-selected {
  color: white !important;
  line-height: 1;
  background-color: #F0600E !important;
}

.react-dropdown-select.multi-select-list {
  padding: .5rem;
  line-height: 1;
  border: 1px solid #707070;
  border-radius: .25rem;
  color: #707070;
  font-weight: 500;
  height: 41px;
}

.react-dropdown-select.multi-select-list:hover, .react-dropdown-select.multi-select-list:focus {
  border-color: #707070;
  box-shadow: none;
}

.subscribe-inner-container-changed{
  input, select, .select-wrapper{
    margin-bottom: 0 !important;
  }
  textarea{
    margin-top: 0 !important;
    margin-bottom: 0.5rem !important;
  }
  @media screen and (max-width: breakpoint(md)) {
    display: block;
    .multi-select-list{
      margin-bottom: 1.25rem !important;
    }
    .link{
      margin-right: 0 !important;
    }
    .recaptcha{
      display: flex;
      justify-content: center;
    }
    textarea{
      margin-bottom: 0 !important;
    }
  }
  @media screen and (max-width: breakpoint(sm)) {
    .recaptcha{
      justify-content: flex-start;
    }
  }
}
.new-subscribe-responsive-changed{
  margin-top: 2rem !important;
  @media screen and (max-width: breakpoint(md)) {
     .form-widget-container{
       margin-right: 0 !important;
     }
  }
  @media screen and (min-width: breakpoint(sm)) and (max-width: breakpoint(md)) {
    .right.subscribe-right > span {
      text-align: center;
      display: block;
    }
  }
  @media screen and (min-width: breakpoint(md)) {
    .left{
      flex: 0 0 40%;
      max-width: 40%;
    }
    .right{
      flex: 0 0 60%;
      max-width: 60%;
    }
  }
}
