@import "../../../../css/global.variables.scss";

.posts-container .tags-container {
    margin-top: 2rem;


    p {
        color: #313830;
        font-weight: 500;
        // margin-bottom: rem;
    }

    @media screen and (min-width: breakpoint(sm)) {
        p {
            font-size: px-to-rem(20px);
            margin-bottom: 1.5rem;
        }
    }

    ul {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding-left: 0px;

        li {
            color: #005777;
            font-weight: 500;
            // border: 1px solid #005777;
            border-radius: 30px;
            padding: .25rem .5rem .25rem 0rem;

            @media (min-width: breakpoint(sm)) {
                padding: .25rem 1rem .25rem 1rem;
                margin: 0 .50rem .50rem 0;

            }

            @media screen and (min-width: breakpoint(lg)) {
                font-size: px-to-rem(14px);
            }

            @media screen and (max-width: breakpoint(sm)) {
                font-size: px-to-rem(14px);
                padding: .75rem;
                border: none;

            }


        }

        .list-item-fed,
        .list-item-ehs {
            background-color: #237DA7;
            color: #fff;
        }

        .list-item-aer {
            background-color: #3DACD5;
            color: #fff;
        }

        .list-item-solid {
            background-color: #155777;
            color: #fff;
        }
    }

    // .bar-seperator {
    //     font-weight: 500;
    //     position: relative;
    //     top: 3px;
    //     // margin-bottom: -3px;
    //     margin-right: .5rem;

    //     @media (min-width: breakpoint(sm)) {
    //         display: none;
    //     }

    //     &:last-of-type {
    //         display: none;
    //     }
    // }

}