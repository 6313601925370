@import '../../../../css/global.variables.scss';

.search-container {
    display: flex;
    align-items: center;
    margin: 12px 0;

    .trinity-search-input {
        display: block;
        width: 100%;
        height: 41px;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:focus {
            color: #495057;
            background-color: #fff;
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
    }

    i {
        margin-left: 1rem;
        font-size: 28px;
        color: #707070;
        font-weight: 100;
        cursor: pointer;
    }
}

.trinity-search-input.xs-sm-only-small-text {
    font-size: px-to-rem(14px);

    @media (min-width: breakpoint(md)) {
        font-size: 1rem;
    }
}


// ********************************************************
// ********************************************************
// **
// **  variable component specific styles
// **
// ********************************************************
// ********************************************************


.hero .search-container {
    max-width: 500px;
    display: none;

    @media (min-width: breakpoint(lg)) {
        display: flex;
    }

    i {
        color: white;
    }
}

.filtered-data-table .search-container {
    max-width: 480px;
}