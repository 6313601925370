.text-input {
    position: relative;

    .error {
        border: 2px solid red !important;
    }

    input {
        display: block;
        width: 100%;
        height: 41px;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:focus {
            color: #495057;
            background-color: #fff;
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
    }

    &.required {
        span.required-text {
            display: none;
            position: absolute;
            top: 5px;
            right: .75rem;
            // transform: translateY(-50%);
            color: #495057;
            font-size: 16px;
            opacity: .7;
        }

        input:invalid {
            // border: 1px solid blue;

            ~span.required-text {
                display: block;
            }
        }
    }
}


.login-form .text-input {
    margin: .25rem 0 .5rem 0;

    &:last-of-type {
        margin-bottom: 1.25rem;
    }
}