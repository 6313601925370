@import '../../../css/global.variables.scss';

.sstitle {
    margin-bottom: 2rem;

    @media screen and (min-width: breakpoint(sm)) {
            font-size: px-to-rem(48px);
    }

    @media screen and (max-width: breakpoint(sm)) {
            font-size: px-to-rem(28px);
    }
}

.ssname {
    font-weight: 400;

    @media screen and (min-width: breakpoint(md)) {
        font-size: px-to-rem(24px);
    }

    @media screen and (max-width: breakpoint(sm)) {
        font-size: px-to-rem(25px);
    }        
}

.ssjob_title {
    display: block;
    font-weight: 400;
    margin: 0px;
    padding: 4px;

    font-size: px-to-rem(20px);
}