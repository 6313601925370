@import '../../../css/global.variables.scss';

.create-account {



    .sidebar {
        position: relative;

        @media (min-width: breakpoint(sm)) {
            padding-left: 3rem;
        } 

        @media (min-width: breakpoint(md)) {
            padding-left: 5rem;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            border-top: 4px solid #EBEBE8;

            @media (min-width: breakpoint(sm)) {
                height: 100%;
                width: auto;
                border-top: none;
                border-left: 4px solid #EBEBE8;
                left: 2rem;
                top: 0;
            }

            @media (min-width: breakpoint(md)) {
                left: 3.5rem;
            }
        }
    }
}
// KT add style for responsive 11-12-2021
.create-account-changed-responsive{
    .create-account-form label{
        margin-top: 0 !important;
    }
    @media (max-width: breakpoint(md)) {
        p{
            div{
                word-break: break-word;
            }
        }
    }
    .col-6 {
        padding-right: 0 !important;
        flex: 0 0 100%;
        max-width: 100%;
    }
}