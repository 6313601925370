@import '../../../css/global.variables.scss';

.featured-product {

  .featured-title {
    padding: .75rem 0;
    background: #3E95B4;
    color: white;
    text-transform: uppercase;
    text-align: center;
    font-size: px-to-rem(16px);
    margin: 0;
  }

  .product-information {
    background: #E9F7FF;
    display: flex;
    flex-direction: column;
    padding: 1.25rem;

    @media (min-width: breakpoint(sm)) {
      flex-direction: row;
    }
  }

  .left {
    display: flex;
    margin-bottom: 1rem;

    @media (min-width: breakpoint(sm)) {
      width: 30%;
    }
  }

  .title {
    font-size: px-to-rem(24px);
    text-decoration: underline;
    font-weight: 500;
    margin: 0;

    @media (min-width: breakpoint(sm)) {
      font-size: px-to-rem(20px);
    }
  }

  .price {
    color: #EF6008;
    font-size: px-to-rem(24px);
    font-weight: 500;
    margin: 0;

    @media (min-width: breakpoint(sm)) {
      font-size: px-to-rem(20px);
    }
  }

  .featured-item-icon > svg {
    width: 36px;
    margin-right: 2.5rem;
    padding-right: 8px;

    @media (min-width: breakpoint(sm)) {
      width: 53px;
    }

    @media (min-width: breakpoint(lg)) {
      width: 70px;
    }
  }

  .learn-more {
    font-weight: 600;
    color: #313830;
  }

  .price-title-container {
    padding-left: 14px;
  }

  .right {
    padding-left: 20px;
  }
}