@import "../../../../css/global.variables.scss";

.policy-form-container {
    .title {
        color: #313830;
        font-weight: 500;
        text-transform: capitalize;
        margin-bottom: 1rem;
        font-size: px-to-rem(26px);
    }

    .content-title {
        font-weight: 500;
        color: #313830;
        margin-bottom: 1rem;
    }

    .email {
        margin-top: 2rem;
        margin-bottom: 0;
    }

    .phone {
        margin-bottom: 2rem;
    }

}