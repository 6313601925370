@import '../../css/global.variables.scss';

* {
  box-sizing: border-box;
}

.bottom-pagination-trinity-site-search {
  padding-top: 1rem;
}

.active-filter-trinity-search {
  width: 7px;
  height: 7px;
  background: red;
  border-radius: 50%;
  position: relative;
  top: 7px;
  right: -14px;
  box-sizing: border-box;
  display: block;
}

.outside-filter-container {
  margin: 20px;


  @media screen and (max-width: breakpoint(sm)) {
    padding: 10px;
  }

  .activity-search-label {
    color: #000;
    font-weight: 500;
    font-size: 20px;
  }

  .activity-dropdown {
    margin-top: 10px;
  }
}

.tree-view-key-font {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  font-family: "Fira Sans Condensed", serif;
}

.tree-view-font-checkbox {
  color: #414142;
  font-family: "Fira Sans Condensed", serif;
  margin-left: 0.5rem;
  font-size: 16px;
}

.tree-view-font-checkbox-count {
  color: #58595B;
  font-family: "Fira Sans Condensed", serif;
  font-size: 16px;
}

.tree-key-element-border {
  border-bottom: 1px solid #bcbcbc;
  //opacity: 70%;
  padding-top: 0.2rem;
  margin-bottom: 0.4rem;
}

.trinity-search-checkbox-elements {
  display: flex;
  align-items: baseline;
  line-height: 1.5;
  font-size: 15px;
  margin-left: 0.2rem;
  font-weight: normal;
  font-family: "Fira Sans Condensed", "fira sans", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  img {
    height: 12px;
    margin-right: 10px;
  }
}


.context-trinitysearch-title {
  color: #414142;
  padding: 5px;
  margin-left: 10px;
  font-weight: 400;
  font-size: px-to-rem(24px) !important;
  line-height: 33px;
  margin-block-end: 0;
  word-break: break-word;

  @media (max-width: breakpoint(md)) {
    font-size: px-to-rem(24px);
    line-height: 30px;
  }

  @media (max-width: breakpoint(sm)) {
    font-size: px-to-rem(20px) !important;
    line-height: 25px;
  }
}

.trinity-site-search-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 50px 0;

  .search-bar {
    background-color: #68A889;
    width: 100%;
  }

  .title-text {
    color: white;
    font-size: 25px;
    padding: 15px;
    padding-left: 90px;
  }

  .main-content {
    margin-top: -1rem;
    min-width: 100%;

    .search-filter-container {

      @media screen and (max-width: breakpoint(sm)) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .search-box {
        background-color: white;
        height: 45px;
        margin-bottom: 2%;
        display: flex;
        align-items: center;
        padding: 10px;
        box-shadow: #efefef 0px 0px 2px 0px, #9f9f9f 0px 2px 8px 0px;


        .search-input {
          flex: 4;
          border: none;
          outline: none;
          margin-right: 40px;

          @media screen and (max-width: breakpoint(sm)) {
            width: 80%;
            flex: 1;
            margin-right: 0;
          }
        }

        @media screen and (max-width: breakpoint(sm)) {
          width: 80%;
          margin-right: 0;
        }
      }
    }

    .filter-btn {
      display: none;
      @media screen and (max-width: breakpoint(sm)) {
        display: flex;
        flex: 1;
        margin-right: 0;
        justify-content: flex-end;

      }

      .button-style {
        padding: .10rem 1.1rem;
        height: 45px;
        color: white;
        background-color: #EF6008;
        box-shadow: 0px 0px 15px rgba(58, 58, 58, 0.29);
      }
    }

    .image-slider-container {
      background-color: white;
      margin-bottom: 20px;
      display: none;
      flex-direction: row;
      overflow: auto;
      box-shadow: 0px 0px 15px rgba(58, 58, 58, 0.29);

      @media screen and (max-width: 600px) {
        margin-top: 20px;
      }

      .image-name-container {
        //width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 10px;

        .image-style {
          // background-color: #999999;
          justify-content: center;
          display: flex;
          align-items: center;

          img {
            padding: 5px;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
            min-height: 90px;
            min-width: 90px;
            max-height: 90px;
            max-width: 90px;
            height: 90px;
            width: 90px;
            background-color: #9f9f9f;
          }
        }

        .image-label {
          text-align: center;
        }
      }
    }

    .activity-container {
      // background-color: gray;
      display: flex;
      flex-wrap: wrap;

      .active-search-filter {
        width: 25%;
        height: 100%;
        margin-right: 40px;
        background-color: white;
        flex: 1;
        box-shadow: 0px 0px 15px rgba(58, 58, 58, 0.29);

        @media screen and (max-width: breakpoint(sm)) {
          width: 100%;
          flex: 1;
          margin-right: 0;
          display: none;
        }

        .desktop-mode {
          display: none;
          @media screen and (max-width: breakpoint(lg)) {
            display: none;
          }
        }


      }

      .date-range-slider {
        width: 100%;
        height: 40px;
        padding: 10px;
        //margin-bottom: 2rem;
      }

      .input-range__slider {
        background-color: #58595B;
      }

      .input-range__label-container{
        color: #58595B;
      }

      .input-range__track--active {
        background-color: #58595B;
      }

      .input-range__label--min {
        display: none;
      }

      .input-range__label--max {
        display: none;
      }

      .active-block {
        // background-color: aquamarine;
        width: 70%;
        height: 100%;
        flex: 3;

        @media screen and (max-width: breakpoint(sm)) {
          width: 100%;
          flex: 1;
        }

        .tab-container {
          background-color: white;
          height: 65px;
          display: flex;

          .tab-btn {
            height: 45px;
            width: 120px;
            border: none;
            outline: none;
            margin: 10px;
            cursor: pointer;
            color: #7F98B7;
            font-weight: 500;
            background-color: white;
          }

          .tab-act-btn {
            height: 45px;
            width: 120px;
            border: none;
            outline: none;
            margin: 10px;
            cursor: pointer;
            font-weight: 500;
            background-color: #00316F;
            color: white;
          }
        }

        .context-container {
          height: 80%;

          .context-card {
            background-color: white;
            padding: 5px;
            display: flex;
            cursor: pointer;
            box-shadow: 0px 0px 10px rgba(58, 58, 58, 0.29);

            .image-container {
              width: 130px;
              max-width: 130px;
              min-width: 130px;
              min-height: 110px;
              background-color: #F0F4F7;
              display: flex;
              align-items: center;
              justify-content: center;
              flex: 1;

              @media screen and (max-width: breakpoint(sm)) {
                display: none;
              }

              .image-present-class {
                width: 130px;
                max-width: 130px;
                min-width: 130px;
                min-height: 110px;
                max-height: 110px;
              }

              .image-not-present-class {
                width: 130px;
                max-width: 130px;
                min-width: 130px;
                min-height: 110px;
                max-height: 110px;
                padding: 1rem
              }

              /*img {
                width: 100%;
                height: 100%;
                padding: 1rem
              }

              img-one {
                width: 100%;
                height: 100%;
                padding: 1rem
              }*/
            }

            .side-data {
              flex: 6;
              padding: 0.2rem 0 0.2rem 0.2rem;

              .title-tag-container {
                display: flex;
                justify-content: space-between;
                padding: 0.5rem 0 0.5rem 0;

                @media (max-width: breakpoint(sm)) {
                  flex-direction: column-reverse;
                }



                .tag-beside-title {
                  height: 1.5rem;
                  margin-right: -0.3rem;
                  padding: 0 2rem 0 2rem;
                  background-color: #58595B;
                  color: white;
                  text-align: center;

                  @media (max-width: breakpoint(sm)) {
                    align-self: flex-end;
                  }
                }
              }

              .context-text {
                color: #313830;
                overflow-y: auto;
                flex-direction: column;
                display: flex;
                padding: 0.5rem 1rem 0.5rem 1rem
              }
            }
          }

          @media screen and (max-width: breakpoint(sm)) {
            margin-top: 1rem;
          }
        }
      }

    }
  }

  .root-search-outer-top-container {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .left-outer-search-container {

    }

    .right-outer-search-container {
      @media screen and (max-width: breakpoint(sm)) {
        margin-top: 0.5rem;
        width: 100%;
      }
    }
  }
}