@import '../../../../css/global.variables.scss';

.assign-attendee-form-listing {

    .submission-button-container {
        display: flex;
        //flex-direction: column;
        align-items: center;
        flex-direction: row;

        /*@media (min-width: breakpoint(sm)) {
            justify-content: flex-end;
            flex-direction: row;
        }*/
    }
}