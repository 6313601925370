@import '../../../css/global.variables.scss';

.carousel {
    display: flex;
    justify-content: center;
    align-items: center;

    &.unslickForMobile {
        flex-direction: column;
    
        @media (min-width: breakpoint(sm)) {
            flex-direction: row;
        }

        .slick-cloned { 
            // bug -- slick leaves cloned items even after calling ('unslick')
            @media (max-width: breakpoint(sm)) {
                display: none;
            }
        }
    }

    &:not(.unslickForMobile){
        div {
            max-height: 100%;
        }
    
        .slick-list {
            padding:0 20% 0 0 !important;
        }
    
        .slick-slider {

            width: calc(100% - 80px);
        }
    
        .slick-slide {
            padding-right: .25rem;
            height: fit-content;
        }
    
        .slick-track {
            display: flex;
            align-items: flex-start;
            height: 100%;
        }
    }

    &.unslickForMobile {
        .slick-list, .slick-track, .slider, .slick-track {
            // slick was being buggy when attempting to unslick on mobile
            // this was to overide buggy slick inline styles
            @media (max-width: breakpoint(sm)) {
                width: 100% !important;
            }
        }

        .slick-track {

            @media (max-width: breakpoint(sm)) {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }

    @media(min-width: breakpoint(sm)) {
        div {
            max-height: 100%;
        }
    
        .slick-list {
            padding:0 20% 0 0 !important;
        }
    
        .slick-slider {
            width: calc(100% - 80px);
        }
    
        .slick-slide {
            padding-right: .25rem;
            height: fit-content;
        }
    
        .slick-track {
            display: flex;
            align-items: flex-start;
            height: 100%;
        }
    }
        
    
}