@import '../../../../css/global.variables.scss';

.contact-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background: rgba(40, 175, 247, 0.94);
    padding-top: 1.5rem;
    color: white;
    opacity: 0;
    animation: .01s linear .45s 1 exitScreen forwards;
    transition: opacity .45s ease;

    @media screen and (min-width: breakpoint(sm)) {
        display: none;
    }

    &.visible {
        opacity: 1;
        animation: unset;
    }

    .close-btn {
        display: block;
        position: relative;
        width: 33px;
        height: 33px;
        margin-left: auto;

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            border-bottom: 3px solid white;
            transform: rotate(45deg);
            top: 50%;
        }

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            border-bottom: 3px solid white;
            transform: rotate(-45deg);
            top: 50%;
        }
    }

    .headline,
    .info-headline {
        font-weight: 500;
    }

    .headline {
        margin-top: 1.5rem;
        padding-right: 3rem;
        font-size: px-to-rem(25px);
    }

    .info-headline {
        text-transform: uppercase;
        font-size: px-to-rem(22px);
        margin-bottom: 0;
    }

    .info-container {
        margin-bottom: 1.5rem;

        &::before {
            content: '';
            display: block;
            width: 96px;
            border-top: 2px solid white;
            margin: 1rem 0;
        }

        a {
            color: #fff;
        }
    }

    p {
        margin-bottom: 2px;
    }
}