@import '../../../css/global.variables.scss';

.technology-cta {
    width: 100%;
    // padding: 3rem 2rem 3rem 2rem;
    color: white;
    min-height: 190px;
    padding-top: 25px;
    padding-bottom: 15px;

    @media (min-width: breakpoint(sm)) {
        min-height: 250px;
        padding: 2rem 2rem 1.5rem 2rem;
    }

    @media (min-width: breakpoint(md)) {
        min-height: 320px;
        padding: 2.25rem 2.75rem 1.65rem 2.75rem;
    }

    @media (min-width: breakpoint(lg)) {
        min-height: 350px;
        padding: 3rem 2.75rem 2rem 2.75rem;
    }

    h2, h1 {
        font-size: px-to-rem(20px);
        line-height: 24px;
        font-weight: 500;
        color: white;
        margin-bottom: 15px;

        @media (min-width: breakpoint(sm)) {
            font-size: px-to-rem(32px);
            line-height: 38px;
            margin-bottom: 20px;
        }

        @media (min-width: breakpoint(md)) {
            font-size: px-to-rem(38px);
            line-height: 46px;
            margin-bottom: 30px;
        }

        @media (min-width: breakpoint(lg)) {
            font-size: px-to-rem(46px);
            line-height: 55px;
            margin-bottom: 35px;
        }
    }

    .content {

        @media (min-width: breakpoint(lg)) {
            max-width: 75%;
        }
    }

    a:hover {
        text-decoration: underline;
    }

    .content, p, a {
        font-size: px-to-rem(14px);
        line-height: 18px;
        color: white;

        @media (min-width: breakpoint(sm)) {
            font-size: px-to-rem(16px);
            line-height: 24px;
        }

        @media (min-width: breakpoint(md)) {
            font-size: px-to-rem(20px);
            line-height: 32px;
        }

        @media (min-width: breakpoint(lg)) {
            font-size: px-to-rem(24px);
            line-height: 36px;
        }
    }    
}

.technology-cta.link-listing {


    .content, p, a {
        font-size: px-to-rem(18px);
        line-height: 18px;
        

        @media (min-width: breakpoint(sm)) {
            font-size: px-to-rem(20px);
            line-height: 24px;
            font-weight: bold;
        }

        @media (min-width: breakpoint(md)) {
            font-size: px-to-rem(24px);
            line-height: 28px;
        }

        @media (min-width: breakpoint(lg)) {
            font-size: px-to-rem(28px);
            line-height: 38px;
        }
    }   

    p, a {
        margin-bottom: 20px;

        @media (min-width: breakpoint(md)) {
            margin-bottom: 30px;
        }

        @media (min-width: breakpoint(lg)) {
            margin-bottom: 35px;
        }
    }
}