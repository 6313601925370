.login-form {
  display: flex;
  flex-direction: column;
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;

  .form-container2 {
    display: flex;
    background-color: #9DDBFF;
    flex-direction: column;
    padding: 1.5rem 1.25rem;
    // border: 2px solid #8f8d8d;
  }

  p {
    padding: 1rem 1rem;
  }

  p.email-text {
    padding: 1rem 0rem;
    margin: 0;
    display: flex;
    justify-content: space-between;

    .change-btn {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .forgot-password {
    cursor: pointer;
  }

  .signed-in-box {
    display: flex;
    align-items: baseline;
  }
}