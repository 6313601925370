@import '../../../../css/global.variables.scss';

.author-details {
    padding-top: 2rem;

    // .title {
    //     text-transform: uppercase;
    //     font-weight: 500;

    //     @media screen and (min-width: breakpoint(lg)) {
    //         font-size: px-to-rem(20px);
    //     }
    // }

    .inner-container {
        display: flex;

        @media screen and (max-width: breakpoint(sm)) {
            display: block;
        }

        .content-wrapper {
            width: 100%;
            padding-right: 2rem;

            @media screen and (max-width: breakpoint(md)) {
                margin-top: 1rem;
            }

        }

        img {
            width: 200px;
            height: 100%;
            margin-right: 2rem;

            @media screen and (max-width: breakpoint(sm)) {
                height: auto;
            }
        }

        .name,
        .position,
        .location {
            font-weight: 500;
            margin-bottom: 0;
        }

        .content {
            margin-top: 1rem;
        }
    }

}