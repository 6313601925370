@import '../../../css/global.variables.scss';

.full-width-text-with-button {
    
    @media (min-width: breakpoint(sm)) {
        display: flex;
        justify-content: space-between;
    }

    .content-container {
        max-width: 800px;
        padding-right: 1rem;
    }

    .title {
        font-size: px-to-rem(28px);
        font-weight: 400;
        
        @media (min-width: breakpoint(md)) {
            font-size: px-to-rem(40px);
            margin-bottom: 1rem;
        }
    }

    .btn-container {
        
        @media (min-width: breakpoint(sm)) {
            display: flex;
            align-items: center;
        }

        
    }
}