@import './../../css/global.variables.scss';

.filter-row-custom {
  justify-content: left !important;
  align-items: center;
  @media (max-width: breakpoint(sm)) {
    margin-left: 20px;
    margin-right: 20px;
  }

}

.admin-box-color{
  background-color: #009cbd;
}

.admin-grid-box-width {

  @media (min-width: breakpoint(sm)) {
    margin-bottom: 0;
    min-width: calc(100% / 6);
    width: calc(100% / 6);
    max-width: calc(100% / 6);
    min-height: 112px;
  }

  @media (min-width: breakpoint(md)) {
    min-height: 200px;
  }

  @media (min-width: breakpoint(xl)) {
    min-width: calc(100% / 7);
    width: calc(100% / 7);
    max-width: calc(100% / 7);
  }
}

