@import "../../../../css/global.variables.scss";

.single-container {
    margin: 2rem 0;

    .name {
        font-size: px-to-rem(22px);
        font-weight: 500;
    }

    .version-container {
        display: flex;

        ul {
            &:first-of-type {
                list-style-type: none;
            }
        }

        @media screen and (max-width: breakpoint(sm)) {
            display: block;

            ul {

                padding-inline-start: 20px;

            }
        }


    }

}