@import "../../../../css/global.variables.scss";

.custom-training-outer-container {
    margin-top: 3rem;
    margin-bottom: 3rem;

    .content {
        margin-bottom: 2rem;
    }

    .right-content {
        padding-left: 4rem;

        @media screen and (max-width: breakpoint(sm)) {
            padding-left: 0;
        }
    }

    ul {
        padding-inline-start: 20px;
    }


    .content-title {
        color: #313830;
        font-weight: 500;
        text-transform: capitalize;
        margin-bottom: 1rem;
        font-size: px-to-rem(26px);
    }
}