@import '../../../../css/global.variables.scss';

.trinity-select-list {
    margin: 0;
    width: 100%;

    .select-list {
        display: block;
        border: 1px solid #707070;
        color: #707070;
        background: white;
        background-position: right 10px center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml;utf8,<svg fill='%23707070' height='28' viewBox='0 0 25 28' width='13' xmlns='http://www.w3.org/2000/svg'><path d='M7 8l10 15 9-15z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        border-radius: 0.25rem;
        width: 100%;
        text-align: left;
        padding: 0.5rem 1rem;
        font-size: 1.0625rem;
        font-weight: 500;
        // this allows custom select styling for all browsers
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    .error {
        border: 2px solid red !important;
    }

    option:disabled {
        color: #b3b3b3;
    }

    span.label {
        //display: block;
        width: 100%;
        text-align: center;
        font-size: px-to-rem(14px);

        @media (min-width: breakpoint(sm)) {
            text-align: left;
            font-size: px-to-rem(16px);
        }
    }

    .select-wrapper {
        width: 100%;
        position: relative;
        select::-ms-expand {
            display: none;
        }
    }

    .arrow {
        border-top: 8px solid #707070;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        position: absolute;
        top: 43.5%;
        right: 1rem;
    }

}

.resources .trinity-select-list .select-list{
    font-size: px-to-rem(14px);

    @media (min-width: breakpoint(md)) {
        font-size: px-to-rem(16px);
    }
}

.filtered-content-well .trinity-select-list .select-wrapper {

    @media (min-width: breakpoint(sm)) {
        max-width: 282px;
    }
}

.software-download .trinity-select-list .select-wrapper{

    @media (min-width: breakpoint(sm)) {
        max-width: 408px;
    }
}


    span.required-text {
        //display: none;
        position: absolute;
        top: 5px;
        right: 0.75rem;
        // transform: translateY(-50%);
        color: #495057;
        font-size: 18px;
        opacity: .7;
    }

    input:invalid {
        // border: 1px solid blue;

        ~span.required-text {
            display: block;
        }
    }

