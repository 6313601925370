@import "../../../../../css/global.variables.scss";

.agency-form-container {
    background: #E9F7FF;
    padding: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;

    @media screen and (max-width: 870px) {
        display: block;
        width: 100vw;
        position: relative;
        left: calc(-50vw + 50%);
        margin-bottom: 2rem;
    }

    .left {
        padding-right: 3rem;

        @media screen and (max-width: breakpoint(sm)) {
            padding-right: 0;
        }
    }

    .help {
        color: red;
    }




    .content-title {
        color: #313830;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 1rem;
        font-size: px-to-rem(26px);
    }

    .content {
        padding-right: 5rem;

        @media screen and (max-width: breakpoint(sm)) {
            padding-right: 0;
        }
    }




    input {
        border: 1px solid #707070;
        margin-bottom: .75rem;
        padding: .5rem;

        &::placeholder {
            font-weight: 500;
        }
    }


    .title {
        color: #313830;
        font-weight: 500;
        margin-bottom: 1rem;
        font-size: 1.325rem;
    }

    .link,
    .recaptcha {
        @media screen and (min-width: breakpoint(sm)) {
            text-align: -webkit-center;
        }

    }


    .recaptcha {
        margin-bottom: 2rem;
        margin-top: 2rem;
    }

}