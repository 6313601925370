@import '../../../css/global.variables.scss';

.product-list-item {
    background: #E9F7FF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    margin-bottom: 10px;

    @media (min-width: breakpoint(sm)) {
        padding: 10px 0;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    select {
        margin-left: 12px;
    }

    .buttons {
        display: flex;
        flex-direction: column;
    }

    .title,
    .retail-price,
    .sale-price {
        font-size: px-to-rem(24px);
        font-weight: 500;
        margin: 0;
        text-align: center;
    }
   .carousel-style{
       width:270px;
   }

    .title {
        text-decoration: underline;
    }

    .prices-container {
        text-align: center;
    }

    .retail {
        margin-bottom: 0;
    }

    .line-through {
        text-decoration: line-through;

    }

    .retail-price {
        color: #303830;
    }


    .sale-price {
        color: #EF6008;
        margin-bottom: 12px;
    }

    .quantity {
        margin-bottom: 5px;
    }

    label {
        font-size: px-to-rem(20px);
        font-weight: 500;
    }
}


// ********************************************************
// ********************************************************
// **
// **  Page / component specific styles
// **
// ********************************************************
// ********************************************************


.carousel .product-list-item {
    width: 100%;
    min-height: 341px;

    @media (min-width: breakpoint(sm)) {
        min-width: 278px;
        width: 278px;

        // height: 341px;
    }

    margin-right: 5px;
}

.product-list-sidebar .product-list-item {

    @media (min-width: breakpoint(sm)) {
        // display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 1.25rem 1.75rem 1.25rem 1.75rem;
    }

    @media (min-width: breakpoint(md)) {
        flex-direction: column;

    }

    .title-price-container {

        @media (min-width: breakpoint(sm)) {
            width: 50%;
        }
    }

    .title,
    p {

        @media (min-width: breakpoint(sm)) {
            text-align: left;
        }

        @media (min-width: breakpoint(md)) {
            text-align: center;
        }
    }
}