@import "../../../../css/global.variables.scss";

.filter-bar-container {
  padding: 1.57em 1.25em 1.57em 1.25em;
  border: 0.07em #ccc solid;
  box-shadow: 0 0 0.31em 0.13em rgba(107, 107, 107, 0.15);
  margin-bottom: 2rem;
  justify-content: space-between;

  .search-title {
    font-size: px-to-rem(20px);
    font-weight: 500;
  }

  .search-wrapper {
   //  display: flex;
    align-items: flex-end;

    @media screen and (max-width: breakpoint(sm)) {
      display: block;
    }
  }

  .search-input {
    display: block;
    // margin-bottom: 1rem;
    // margin-top: 2rem;
    // margin-left: 2rem;
    width: 70%;
    height: 55%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &::placeholder {
      font-weight: 500;
    }

    @media screen and (max-width: breakpoint(sm)) {
      margin-left: 0;
      width: 100%;
      height: 120%;
    }
  }

  .state {
    @media screen and (max-width: breakpoint(sm)) {
      margin-bottom: 2rem;
    }
    .states-countries-container {
      justify-content: space-between;
    }
    .states-container {
      @media screen and (max-width: breakpoint(sm)) {
        margin-bottom: 2rem;
      }
    }
  }

  li {
    list-style: none;
  }

  .optionContainer {
    width: 500px;
    display: flex;
    flex-wrap: wrap;
    background: #e5e9ef;

    @media screen and (max-width: breakpoint(sm)) {
      display: block;
      width: 100%;
    }

    .option {
      color: #31a4e5;
      background: none;
      align-items: flex-start;

      .checkbox {
        width: 16px;
        height: 16px;
      }
    }
  }
}
