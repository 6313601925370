.range-date-picker {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.range-date {
  width: 100%;
}

.range-date-custom {
  width: 95% !important;
}

.react-datepicker-wrapper {
  input {
    width: 100%;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    border: 1px solid #707070;
  }
}
