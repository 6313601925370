@import "../../../../css/global.variables.scss";

.program-widget-container {
    margin-bottom: 3rem;
    margin-top: 4rem;


    .blurb {
        margin-top: 3rem;
        margin-bottom: 0;
        font-size: px-to-rem(20px);
        font-weight: 400;
    }


}