// ********************************************************
// ********************************************************
// **
// **  README
// **
// **  1. All SASS global mixins should be placed here
// **
// ********************************************************
// ********************************************************



@import './_config.variables.scss';

@mixin webFont {
    font-family: $font_base !important;
}

@mixin flexCenterXY {
    display: flex;
    display: -ms-flex;
    align-items: center;
    justify-content: center;
}

@mixin absoluteCenterXY {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@mixin marginCenter {
    margin-left: auto;
    margin-right: auto;
}

// set container by breakpoints (defaults to all being true)
@mixin container ($xs: true, $sm: true, $md: true, $lg: true) { 

    @if ($xs) {
        @media (max-width: breakpoint(sm)) {
            padding-left: px-to-rem(19px);
            padding-right: px-to-rem(19px);
            @include marginCenter;
        }  
    }

    @if ($sm) {
        @media (min-width: breakpoint(sm)) and (max-width: breakpoint(md)) {
            padding-right: px-to-rem(24px);
            padding-left: px-to-rem(24px);
            @include marginCenter;
        }
    } 

    @if ($md) {
        @media (min-width: breakpoint(md)) and (max-width: breakpoint(lg)) {
            padding-right: px-to-rem(63px);
            padding-left: px-to-rem(63px);
            @include marginCenter;
        }
    }

    @if ($lg) {
        @media (min-width: breakpoint(lg)) {
            padding-right: px-to-rem(109px);
            padding-left: px-to-rem(109px);
            max-width: calc(#{$container_max} + 218px);
            @include marginCenter;
        }
    }
}

@mixin moduleSpacer ($xs: true, $sm: true, $md: true, $lg: true, $direction: 'both') { 

    @if ($xs) {
        @media (max-width: breakpoint(sm)) {
            @if ($direction == 'both' or $direction == 'top') {margin-top: 2rem;}
            @if ($direction == 'both' or $direction == 'bottom') {margin-bottom: 2rem;}
        }  
    }

    @if ($sm) {
        @media (min-width: breakpoint(sm)) and (max-width: breakpoint(md)) {
            @if ($direction == 'both' or $direction == 'top') {margin-top: 2rem;}
            @if ($direction == 'both' or $direction == 'bottom') {margin-bottom: 2rem;}
        }
    } 

    @if ($md) {
        @media (min-width: breakpoint(md)) and (max-width: breakpoint(lg)) {
            @if ($direction == 'both' or $direction == 'top') {margin-top: 3rem;}
            @if ($direction == 'both' or $direction == 'bottom') {margin-bottom: 3rem;}
        }
    }

    @if ($lg) {
        @media (min-width: breakpoint(lg)) {
            @if ($direction == 'both' or $direction == 'top') {margin-top: 4rem;}
            @if ($direction == 'both' or $direction == 'bottom') {margin-bottom: 4rem;}
        }
    }
}
