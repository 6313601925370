@import '../../../css/global.variables.scss';

// maybe remove this as a component?
// => place styles in global font-sizing??
.software .section-title {
    font-size: px-to-rem(22px);
    color: #313830;
    text-align: center;

    @media (min-width: breakpoint(sm)) {
        text-align: left;
    }
}