@import '../../../../css/global.variables.scss';


.close-btn {
    display: inline-block;
    position: relative;
    width: 14px;
    height: 14px;
    color: inherit;
    cursor: pointer;

    @media (min-width: breakpoint(sm)) {
        width: 16px;
        height: 16px;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 100%;
        border-bottom: 1px solid;
        left: 0;
        top: 50%;


        // may need to adjust this by adding it to the individual before, and after
        // transform: translateY(-50%);
    }

    // &:hover {
    //     &::before, &::after { 
    //         opacity: ;
    //     }

    // }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }
}