// ********************************************************
// ********************************************************
// **
// **  README 
// **
// **  1. This file controls the entire application view
// **
// ********************************************************
// ********************************************************



// ********************************************************
// ********************************************************
// **
// **  grid -- these breakpoints change bootstrap as well
// **
// ********************************************************
// ********************************************************


$grid-breakpoints: (
    xs: 0px,
    sm: 720px,
    md: 1250px,
    lg: 1650px,
    xl: 1800px, 
);

$container_max: px-to-rem(1710px);

:export { // - exported javascript variables
    breakpointSm: strip-unit(breakpoint(sm));
    breakpointMd: strip-unit(breakpoint(md));
    breakpointLg: strip-unit(breakpoint(lg));
}


// ********************************************************
// ********************************************************
// **
// **  fonts
// **
// ********************************************************
// ********************************************************


$font_primary: 'Fira Sans Condensed';
$font_secondary: "fira sans";
$font_fallbacks: $font_secondary, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font_base: $font_primary, $font_fallbacks !default;

// TODO -- just got complete mockups of phase 1 -- need to make global font sizing classes


// ********************************************************
// ********************************************************
// **
// **  colors
// **
// ********************************************************
// ********************************************************


$widgetBox: #EBEBE8;

// may remove this ??
// Majority of the colors are being kept in '../global-components/_color-scheme.styles.scss'
$colors: (
    text: (
        primary: #313830,
        accent: #FFFFFF,
    ),
);


// ********************************************************
// ********************************************************
// **
// **  animations
// **
// ********************************************************
// ********************************************************


$custom_animation: cubic-bezier(0.85, 0.8, 0.12, 0.99);