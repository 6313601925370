@import "../../../../css/global.variables.scss";

.marker-container {
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: baseline;

    img {
        height: 100%;
        cursor: pointer;
    }

    .marker-content {

        cursor: pointer;
    }

    a {
        font-size: px-to-rem(9px);
        font-weight: bold;
        text-decoration: none;

        // margin: 0;
        color: #333;
        background-color: white;
        display: block;
        margin-left: -50%;
        text-align: center;
        font-weight: bold;
        padding: 0px 3px 0px 3px;
        height: 14px;
        width: auto;
        display: inline-block;
        border: 1px solid #009be0;
        white-space: nowrap;
        box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.15);
        position: relative;
        left: 5px;
    }

}