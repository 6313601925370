@import '../../../css/global.variables.scss';

.software-product-detail {
    margin-bottom: 3rem;

    .main-product-container {
        max-width: 100%;

        @media (min-width: breakpoint(md)) {
            display: flex;
        }
    }

    aside {
        width: 100%;

        @media (min-width: breakpoint(md)) {
            width: 25%;
            min-width: 250px;
            max-width: 310px;
        }
    }

    .main-content {

        @media(min-width: breakpoint(md)) {
            width: 75%;
            flex-grow: 1;
            padding-right: 3%;
        }
    }

    .contact-bottom-classic-block {
        justify-content: flex-start;
        display: flex;
        background-color: #E9F7FF;
        margin: 5px;
        padding: 10px;
    }

    .contact-bottom-classic-block-phone {
        justify-content: center;
        display: flex;
        background-color: #b1e2fb;
        margin: 5px;
        padding: 10px;
    }
}