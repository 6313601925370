 @import "../../../../../css/global.variables.scss";

 .resume-inner-container {
     background: #E9F7FF;
     padding: 2rem;
     display: grid;

     .resume-form {

         .help {
             color: red;
         }


         .form-title {
             color: #313830;
             font-weight: 500;
             text-transform: uppercase;
             margin-bottom: 1rem;
             font-size: px-to-rem(26px);
         }

         .title {
             font-size: px-to-rem(20px);
             font-weight: 500;
         }


         input {
             display: block;
             border: 1px solid #707070;
             width: 100%;
             margin-bottom: .75rem;
             padding: .5rem;

             &::placeholder {
                 font-weight: 500;
             }
         }

         .first-section {

             .title {
                 margin-bottom: 1rem;
             }


         }

         .second-section {

             .title {
                 margin-top: 2rem;
                 margin-bottom: 2rem;
             }

             select {
                 display: block;
                 width: 100%;
                 padding: .5rem .5rem .5rem .75rem;
                 font-size: 16px;
                 background: #fff;
                 border: 1px solid #707070;

             }

             .career {
                 margin-bottom: 1rem;
             }

             .education {
                 margin-bottom: 2rem;
             }
         }

         .third-section {
             .title {
                 margin-top: 2rem;
             }

             textarea {
                 display: block;
                 margin-top: 1rem;
                 margin-bottom: 2rem;
                 width: 100%;
                 padding: .75rem;
                 height: 10rem;
             }


         }

         .upload {
             input {
                 border: 0;
                 display: none;
             }


             .upload-link {
                 //  margin-right: auto;
                 //  width: 40%;
                 //  text-align: left;
                 //  padding: .5rem;
                 cursor: pointer;

                 .custom-button {
                     background: #0F2748;
                     border: 2px solid #0F2748;
                     color: #fff;

                     &:hover {
                         background: #fff;
                         color: #0F2748;
                     }
                 }
             }
         }

         .recaptcha {
             margin-top: 2rem;
             margin-bottom: 2rem;
             width: 100%;
         }

         .link {
             margin-top: 1rem;
             text-align: center;
         }



     }


 }

 .indiv-container .resume-container {
     @media (max-width: breakpoint(sm)) {
         width: 100vw;
         position: relative;
         left: calc(-50vw + 50%);

     }
 }