@import "../../css/global.variables.scss";

.resource-search-outer-filter-button {
  margin-bottom: 1rem;
}

.resource-filter-btn {
  display: none;
  @media screen and (max-width: breakpoint(sm)) {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .button-style {
    padding: .10rem 1.1rem;
    height: 45px;
    color: white;
    background-color: #EF6008;
    box-shadow: 0px 0px 15px rgba(58, 58, 58, 0.29);
  }
}

.resource-search-outer-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 50px 0;

  .search-bar {
    background-color: #68A889;
    width: 100%;
  }

  .title-text {
    color: white;
    font-size: 25px;
    padding: 15px;
    padding-left: 90px;
  }

  .main-content {
    margin-top: -1rem;
    min-width: 100%;

    .image-slider-container {
      background-color: white;
      margin-bottom: 20px;
      display: none;
      flex-direction: row;
      overflow: auto;
      box-shadow: 0px 0px 15px rgba(58, 58, 58, 0.29);

      @media screen and (max-width: 600px) {
        margin-top: 20px;
      }

      .image-name-container {
        //width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 10px;

        .image-style {
          // background-color: #999999;
          justify-content: center;
          display: flex;
          align-items: center;

          img {
            padding: 5px;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
            min-height: 90px;
            min-width: 90px;
            max-height: 90px;
            max-width: 90px;
            height: 90px;
            width: 90px;
            background-color: #9f9f9f;
          }
        }

        .image-label {
          text-align: center;
        }
      }
    }

    .activity-container {
      // background-color: gray;
      display: flex;
      flex-wrap: wrap;

      .active-resource-left-column {
        height: 100%;
        flex: 1;

        @media screen and (max-width: breakpoint(sm)) {
          display: none;
        }
      }

      .side-bar-active-search-button {
        margin-top: 1rem;
        margin-right: 40px;
        display: block;

        @media screen and (max-width: breakpoint(sm)) {
          display: none;
          margin-right: 0;
        }
      }

      .active-search-filter {
        margin-right: 40px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        background-color: #EBEBE8;

        //box-shadow: 0px 0px 15px rgba(58, 58, 58, 0.29);

        @media screen and (max-width: breakpoint(sm)) {
          width: 100%;
          flex: 1;
          margin-right: 0;
          display: none;
        }

        .desktop-mode {
          display: none;
          @media screen and (max-width: breakpoint(lg)) {
            display: none;
          }
        }


      }

      .active-block {
        // background-color: aquamarine;
        width: 70%;
        height: 100%;
        flex: 3;

        @media screen and (max-width: breakpoint(sm)) {
          width: 100%;
          flex: 1;
        }

        .tab-container {
          background-color: white;
          height: 65px;
          display: flex;

          .tab-btn {
            height: 45px;
            width: 120px;
            border: none;
            outline: none;
            margin: 10px;
            cursor: pointer;
            color: #7F98B7;
            font-weight: 500;
            background-color: white;
          }

          .tab-act-btn {
            height: 45px;
            width: 120px;
            border: none;
            outline: none;
            margin: 10px;
            cursor: pointer;
            font-weight: 500;
            background-color: #00316F;
            color: white;
          }
        }

        .context-container {
          height: 80%;

          .context-card {
            background-color: white;
            padding: 5px;
            display: flex;
            cursor: pointer;
            box-shadow: 0px 0px 10px rgba(58, 58, 58, 0.29);

            .image-container {
              width: 130px;
              max-width: 130px;
              min-width: 130px;
              min-height: 110px;
              background-color: #F0F4F7;
              display: flex;
              align-items: center;
              justify-content: center;
              flex: 1;

              @media screen and (max-width: breakpoint(sm)) {
                display: none;
              }

              .image-present-class {
                width: 130px;
                max-width: 130px;
                min-width: 130px;
                min-height: 110px;
                max-height: 110px;
              }

              .image-not-present-class {
                width: 130px;
                max-width: 130px;
                min-width: 130px;
                min-height: 110px;
                max-height: 110px;
                padding: 1rem
              }

              /*img {
                width: 100%;
                height: 100%;
                padding: 1rem
              }

              img-one {
                width: 100%;
                height: 100%;
                padding: 1rem
              }*/
            }

            .side-data {
              flex: 6;
              padding: 0.2rem 0 0.2rem 0.2rem;

              .title-tag-container {
                display: flex;
                justify-content: space-between;
                padding: 0.5rem 0 0.5rem 0;

                @media (max-width: breakpoint(sm)) {
                  flex-direction: column-reverse;
                }


                .tag-beside-title {
                  height: 1.5rem;
                  margin-right: -0.3rem;
                  padding: 0 2rem 0 2rem;
                  background-color: #58595B;
                  color: white;
                  text-align: center;

                  @media (max-width: breakpoint(sm)) {
                    align-self: flex-end;
                  }
                }
              }

              .context-text {
                color: #313830;
                overflow-y: auto;
                flex-direction: column;
                display: flex;
                padding: 0.5rem 1rem 0.5rem 1rem
              }
            }
          }

          @media screen and (max-width: breakpoint(sm)) {
            margin-top: 1rem;
          }
        }
      }

    }
  }


}

.resource-search-pagination-outer {
  display: flex;
  justify-content: flex-start;

  .pagination {
    justify-content: flex-start;
  }
}

.root-search-outer-top-container {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  .left-outer-search-container {
    display: block;

    @media screen and (max-width: breakpoint(sm)) {
      display: none;
    }
  }

  .right-outer-search-container {
    width: auto;

    @media screen and (max-width: breakpoint(sm)) {
      margin-top: 0.5rem;
      width: 100%;
    }
  }
}

.search-filter-container {

  @media screen and (max-width: breakpoint(sm)) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .search-box {
    background-color: white;
    height: 45px;
    margin-bottom: 2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    box-shadow: #efefef 0px 0px 2px 0px, #9f9f9f 0px 2px 8px 0px;

    img {
      width: 20px;
      height: 20px;
      margin: 0 10px;
      cursor: pointer
    }

    .search-input {
      //flex: 4;
      border: none;
      outline: none;
      min-width: 85%;
      //margin-right: 40px;

      @media screen and (max-width: breakpoint(sm)) {
        width: 100%;
        flex: 1;
        margin-right: 0;
      }
    }

    @media screen and (max-width: breakpoint(sm)) {
      width: 100%;
      margin-right: 0;
    }
  }
}

.general-search-date-range-slider {
  width: 100%;
  height: 40px;
  padding: 10px;
  //margin-bottom: 2rem;
}

.input-range__slider {
  background-color: #58595B;
}

.input-range__label-container {
  color: #58595B;
}

.input-range__track--active {
  background-color: #58595B;
}

.input-range__label--min {
  display: none;
}

.input-range__label--max {
  display: none;
}


.training-posts-container {
  .widgetBox-wrapper {
    &:first-child {
      margin-top: 0;
    }
  }

  .widget-wrapper {
    display: flex;
    flex-direction: column;
    //padding: 16px 25px;
    //background-color: #F0F4F7;
    //margin-bottom: 1rem;

    img {
      margin-right: 2rem;
      margin-top: -0.4rem;
      height: 100%;

      @media screen and (max-width: breakpoint(sm)) {
        display: none;
      }
    }

    .custom-button {
      margin: 0;
    }

    .post-heading-title {
      color: #00326F;
      font-size: 30px;
      padding-right: 15px;

      @media screen and (max-width: breakpoint(sm)) {
        width: 100%;
      }
    }

    .post-heading-sub-title {
      color: #00326F;
      font-size: 15px;
      margin: 0;

      @media screen and (max-width: breakpoint(sm)) {
        margin-bottom: 10px;
      }
    }

    .bottom-row-card-container::-webkit-scrollbar {
      display: none;
    }

    .class-dec {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: breakpoint(sm)) {
        flex-direction: column;
      }
    }

    .bottom-row-card-container {
      width: 100%;
      flex-direction: row;
      display: flex;
      //justify-content: space-between;
      flex-wrap: wrap;
      //overflow: auto;

      .classroom {
        background-color: #EFEFEF;
        min-width: 31.5%;
        margin-right: 15px;
        margin-top: 12px;
        padding: 12px 15px;

        @media screen and (max-width: breakpoint(sm)) {
          min-width: 50%;
        }
        @media screen and (max-width: 425px) {
          min-width: 100%;
        }

        .class-name {
          font-size: 20px;
          letter-spacing: 0px;
          color: #EF6008;
          opacity: 1;
          margin-bottom: 5px;
        }

        .class-date {
          font-size: 12px;
          letter-spacing: 0px;
          color: #214C81;
          opacity: 0.5;
          margin: 0;
        }
      }
    }
  }
}


.safe-bridge-post {
  margin: 0.8rem 0;
  box-shadow: 0px 0px 15px #A8A8A829;

  .light-background {
    background-color: #F0F4F7;
  }
}

.safe-bridge-top-heading-row {
  display: flex;
  font-weight: bold;
  flex-direction: row;
  @media screen and (max-width: breakpoint(sm)) {
    display: none;
  }
}

.safe-bridge-post-caption {
  display: none;
  @media screen and (max-width: breakpoint(sm)) {
    display: block;
    font-weight: bold;
  }
}

.safe-bridge-sub-data-column {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: breakpoint(sm)) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.safe-bridge-read-more-button-style {
  padding: 10px;
  color: white;
  background-color: #EF6008;
}
.safe-bridge-post-changed{
  .safe-bridge-button-changed{
    align-self: center;
  }
}
//*KT add below style 10-12-2021 *
  @media screen and (min-width: breakpoint(sm)) and (max-width: breakpoint(md)) {
    .safe-bridge-top-heading-changed {
      font-size: 14px;
      white-space: nowrap;
      word-break: keep-all;
      padding-right: 10px;
      display: block;
    }
    .safe-bridge-post-caption-content-changed{
      word-break: break-word;
    }
  }
.resource-search-outer-container-changed{
  @media screen and (min-width: breakpoint(sm)) and (max-width: breakpoint(md)) {
    .active-search-filter-changed{
      margin-right: 0 !important;
      margin-bottom: 20px;
    }
  }
}
.active-resource-left-column-changed{
  @media screen and (min-width: 800px) and (max-width:1100px) {
    padding-right: 15px;
  }
}