@import "../../../../css/global.variables.scss";

.contact-us-form-container {
  background: #E9F7FF;
  padding: 2rem;
  display: grid;

  @media screen and (max-width: breakpoint(sm)) {
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
    margin-bottom: 2rem;
  }

  .inner-form {
    display: flex;

    .contact-container94 {
      min-width: 100%;
      display: -ms-flexbox;
      background-color: transparent;
    }

    @media screen and (max-width: breakpoint(md)) {
      display: block;
    }

    .help {
      color: red;
    }

    .left {
      padding-right: 2rem;
      @media screen and (max-width: breakpoint(sm)) {
        padding-right: 0;
      }
    }


    .form-title {
      color: #313830;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 1rem;
      font-size: px-to-rem(26px);
    }


    input {
      border: 1px solid #707070;

      margin-bottom: .75rem;
      padding: .5rem;

      &::placeholder {
        font-weight: 500;
      }
    }

    select {
      display: block;
      width: 100%;
      padding: .5rem .5rem .5rem .75rem;
      font-size: 16px;
      background: #fff;
      border: 1px solid #707070;
      margin-bottom: 1.25rem;
    }


    textarea {
      display: block;
      margin-top: 1rem;
      margin-bottom: 2rem;
      width: 100%;
      padding: .75rem;
      height: 10rem;
    }


    .upload {
      input {
        border: 0;
        display: none;
      }


      .upload-link {
        cursor: pointer;

        .custom-button {
          background: #0F2748;
          border: 2px solid #0F2748;
          color: #fff;

          &:hover {
            background: #fff;
            color: #0F2748;
          }
        }
      }
    }

    .checkbox-container {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      // @media screen and (max-width: breakpoint(sm)) {
      //     display: block;
      // }

      label {

        padding-left: 1rem;

      }
    }

    .recaptcha,
    .link {
      @media screen and (min-width: breakpoint(sm)) {
        text-align: -webkit-center;
      }
    }

    .recaptcha {
      margin-top: 2rem;
      margin-bottom: 2rem;
      width: 100%;
    }

    .link {
      margin-top: 1rem;
      width: 100%;
    }
  }


  .multiselect-padding-rule {
    padding: 0;

    @media screen and (min-width: breakpoint(lg)) {
      padding-left: 1rem;
    }
  }
}

.react-dropdown-select-dropdown-handle {
  margin: 0 7px !important;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 8px solid #737373;
  color: #707070;

  svg {
    display: none;
  }
}

.multi-select-list {
  display: block;
  width: 100%;
  padding: .5rem .5rem .5rem .75rem;
  font-size: 16px;
  background: #fff;
  margin: 0 !important;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 8px solid #737373;

  svg {
    display: none;
  }
}

.react-dropdown-select-item {
  display: block;
  width: 100%;
  padding: .5rem .5rem .5rem .75rem;
  font-size: 16px;
  background: #fff;
  border: 1px solid #707070;
}

.react-dropdown-select-dropdown .react-dropdown-select-item {
  padding: .25rem .5rem;
  border: none;
  color: #707070;
}

.react-dropdown-select-item:hover {
  color: white !important;
  line-height: 1;
  background-color: #F0600E !important;
}

.react-dropdown-select-item-selected {
  color: white !important;
  line-height: 1;
  background-color: #F0600E !important;
}

.react-dropdown-select.multi-select-list {
  padding: .5rem;
  line-height: 1;
  border: 1px solid #707070;
  border-radius: .25rem;
  color: #707070;
  font-weight: 500;
  height: 41px;
}

.react-dropdown-select.multi-select-list:hover, .react-dropdown-select.multi-select-list:focus {
  border-color: #707070;
  box-shadow: none;
}

.selected-dropdown-item {
  background-color: #F0600E;
  color: #ffffff;
}

.contact-us-footer-form-container {
  display: block;
  @media screen and (max-width: breakpoint(sm)) {
    display: none;
  }
}

.contact-us-footer-form-container-right {
  display: none;
  @media screen and (max-width: breakpoint(sm)) {
    display: block;
  }
}

//KT add style 11-15-2021
.contact-us-form-container-changed{
  .inner-form {
    input {
      margin-bottom: 0 !important;
    }
    select{
      margin-bottom: 0.5rem !important;
    }
    textarea{
      margin-top: 0 !important;
      margin-bottom: 0.5rem !important;
    }
  }
  @media screen and (max-width: breakpoint(md)) {
    .contact_multi_select-changed{
      margin-bottom: 1.25rem !important;
    }
    textarea{
      margin-bottom: 0 !important;
    }
    .link{
      margin-top: 0 !important;
    }
  }
}
.contact-container-changed{
  @media screen and (max-width: breakpoint(md)) {
    flex-direction: column-reverse;
    .content-widget-container{
      padding-right: 0 !important;
    }
  }
}


