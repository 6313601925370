@import "../../../../css/global.variables.scss";

.job-alert-container {
    background: #E9F7FF;
    padding: 2rem;
    display: grid;

    @media screen and (min-width: breakpoint(sm)) {
        margin-left: 2rem;
    }

    @media screen and (max-width: breakpoint(sm)) {
        position: relative;
        width: 100vw;
        left: calc(-50vw + 50%);
    }

    .job-alert-form {
        .help {
            color: red;
        }

        .form-title {
            color: #313830;
            font-weight: 500;
            text-transform: uppercase;
            margin-bottom: 1rem;
            font-size: px-to-rem(26px);
        }

        input {
            display: block;
            border: 1px solid #707070;
            width: 100%;
            margin-bottom: .75rem;
            padding: .5rem;

            &::placeholder {
                font-weight: 500;
            }
        }

        .link {
            margin-top: 1rem;
            text-align: center;
            cursor: pointer;
        }

        .recaptcha {
            margin-top: 2rem;
            margin-bottom: 2rem;
            width: 100%;
        }


    }
}

.alert-container .job-alert-container {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;

    @media screen and (min-width: breakpoint(sm)) {
        max-width: 420px;
    }
}