@import "../../../../css/global.variables.scss";

.request-demo-container {
    background: #EEEEEE;
    padding: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 2rem;
    margin-bottom: 3rem;

    @media screen and (max-width: breakpoint(sm)) {
        display: block
    }

    .title {
        font-size: px-to-rem(30px);
        font-weight: 500;
        margin: 0;

        @media screen and (max-width: breakpoint(md)) {
            font-size: px-to-rem(20px);
        }
    }

    .custom-button {
        display: block;
        border-radius: 5px;

        @media screen and (max-width: breakpoint(sm)) {
            margin-top: 1rem;
        }
    }

    a {
        text-decoration: none;
    }
}