// ********************************************************
// ********************************************************
// **
// **  Filtered Cta Grid - general style
// **
// ********************************************************
// ********************************************************




.filtered-cta-grid {
  position: relative;

  @media screen and (min-width: breakpoint(sm)) {
    left: 3px;
  }


  // @media (min-width: breakpoint(sm)) {
  //     margin-bottom: 2rem;
  // }

  .filter-row {
    justify-content: flex-start;
  }

  .grid-box-width {

    @media (max-width: breakpoint(sm)) {
      min-width: 100%;
      max-width: 100%;
    }

    @media (min-width: breakpoint(sm)) {
      min-width: calc(100% / 3);
      width: calc(100% / 3);
      max-width: calc(100% / 3);
    }

    @media (min-width: breakpoint(md)) {
      min-width: 25%;
      width: 25%;
      max-width: 25%;
    }

    @media (min-width: breakpoint(lg)) {
      min-width: 20%;
      width: 20%;
      max-width: 20%;
    }
  }

}


// ********************************************************
// ********************************************************
// **
// **  component class variations
// **
// ********************************************************
// ********************************************************


.filtered-cta-grid.only-small-ctas {

  .grid-box-width {

    @media (min-width: breakpoint(sm)) {     
      width: calc(100% / 6);
      max-width: calc(100% / 6);
      min-height: 112px;
    }

    @media (min-width: breakpoint(md)) {
      min-height: 200px;
    }

    @media (min-width: breakpoint(xl)) {
      min-width: calc(100% / 7);
      width: calc(100% / 7);
      max-width: calc(100% / 7);
    }
  }
}


.filtered-cta-grid.bg-img {
  @media (min-width: breakpoint(sm)) {
    padding: 80px 0 50px;
    min-height: 600px;
    margin-bottom: 0;
  }

  @media (min-width: breakpoint(md)) {
    min-height: 800px;
  }

  .bg-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100%;
    z-index: -1;
    display: none;

    @media (min-width: breakpoint(sm)) {
      display: block;
    }
  }
}
