@import "../../../css/global.variables.scss";

.progress-container {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 3rem;
    border-radius: 40px;

    div:first-child {
        margin-left: -2%;
    }


    .progress-step-custom {
        margin: 0;
        margin-left: 0.5%;
        width: 25%;
        position: relative;
        display: inline-block;
        text-align: center;
        line-height: 30px;
        transition: all 0.8s;
        border-radius: 30px;
        padding: 1.2rem 0rem 1.2rem 1.2rem;

        @media screen and (max-width: breakpoint(sm)) {
            width: 25%;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            transition: all 0.8s;
            z-index: -1;
        }

        &::before {
            height: 50%;
            width: 100%;
            top: 0;
            left: 0;
            background: #f3f2f2;
            -webkit-transform: skew(45deg);
            -moz-transform: skew(45deg);
            transform: skew(45deg);
        }

        &::after {
            height: 50%;
            width: 100%;
            top: 50%;
            left: 0;
            background: #f3f2f2;
            -webkit-transform: skew(-45deg);
            -moz-transform: skew(-45deg);
            transform: skew(-45deg);
        }

        &:hover::before,
        &:hover::after {
            background: #4A9CEB;
        }

        &:hover {
            a {
                color: #fff;
                text-decoration: none;
            }

        }

        a {
            text-decoration: none;
            margin-bottom: 0;
        }

        @media screen and (max-width: breakpoint(sm)) {
            border-bottom: 0;
            text-align: center;
            padding: 1.2rem 1.2rem 1.2rem 1.2rem;
            // border-right: 0;

        }

        &.cart_link_active {
            &::before,
            &::after {
                background: #4A9CEB;
            }
        }

        &.cart_link_active a {
            color: #fff;
            text-decoration: none;
        }
    }

    .progress-step-custom-for-on-demand {
        margin: 0;
        margin-left: 0.5%;
        width: 51%;
        position: relative;
        display: inline-block;
        text-align: center;
        line-height: 30px;
        transition: all 0.8s;
        border-radius: 30px;
        padding: 1.2rem 0rem 1.2rem 1.2rem;

        @media screen and (max-width: breakpoint(sm)) {
            width: 25%;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            transition: all 0.8s;
            z-index: -1;
        }

        &::before {
            height: 50%;
            width: 100%;
            top: 0;
            left: 0;
            background: #f3f2f2;
            -webkit-transform: skew(45deg);
            -moz-transform: skew(45deg);
            transform: skew(45deg);
        }

        &::after {
            height: 50%;
            width: 100%;
            top: 50%;
            left: 0;
            background: #f3f2f2;
            -webkit-transform: skew(-45deg);
            -moz-transform: skew(-45deg);
            transform: skew(-45deg);
        }

        &:hover::before,
        &:hover::after {
            background: #4A9CEB;
        }

        &:hover {
            a {
                color: #fff;
                text-decoration: none;
            }

        }

        a {
            text-decoration: none;
            margin-bottom: 0;
        }

        @media screen and (max-width: breakpoint(sm)) {
            border-bottom: 0;
            text-align: center;
            padding: 1.2rem 1.2rem 1.2rem 1.2rem;
            // border-right: 0;

        }

        &.cart_link_active {
            &::before,
            &::after {
                background: #4A9CEB;
            }
        }

        &.cart_link_active a {
            color: #fff;
            text-decoration: none;
        }
    }

    .progress-step {
        margin: 0;
        margin-left: 0.5%;
        // width: 25.5%;
        width: 20%;
        position: relative;
        display: inline-block;
        text-align: center;
        line-height: 30px;
        transition: all 0.8s;
        border-radius: 30px;
        padding: 1.2rem 0rem 1.2rem 1.2rem;

        @media screen and (max-width: breakpoint(sm)) {
            width: 20%;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            transition: all 0.8s;
            z-index: -1;
        }

        &::before {
            height: 50%;
            width: 100%;
            top: 0;
            left: 0;
            background: #f3f2f2;
            -webkit-transform: skew(45deg);
            -moz-transform: skew(45deg);
            transform: skew(45deg);
        }

        &::after {
            height: 50%;
            width: 100%;
            top: 50%;
            left: 0;
            background: #f3f2f2;
            -webkit-transform: skew(-45deg);
            -moz-transform: skew(-45deg);
            transform: skew(-45deg);
        }

        &:hover::before,
        &:hover::after {
            background: #4A9CEB;
        }

        &:hover {
            a {
                color: #fff;
                text-decoration: none;
            }

        }

        a {
            text-decoration: none;
            margin-bottom: 0;
        }

        @media screen and (max-width: breakpoint(sm)) {
            border-bottom: 0;
            text-align: center;
            padding: 1.2rem 1.2rem 1.2rem 1.2rem;
            // border-right: 0;

        }

        &.cart_link_active {
            &::before,
            &::after {
                background: #4A9CEB;
            }
        }
        &.cart_link_active a {
            color: #fff;
            text-decoration: none;
        }
    }

    .sm-d-none {
        padding-left: .5rem;

        @media screen and (max-width: breakpoint(sm)) {
            display: none;
        }

    }


}



// .progress-container {
//     margin-bottom: 2rem;
//     width: 100%;
//     display: flex;
//     justify-content: space-between;
//     border: 2px solid #C8CBCE;

//     border-radius: 40px; // margin-bottom: 4rem;




//     .progress-step {
//         border-right: 2px solid #C8CBCE;
//         border-radius: 30px;
//         padding: 1.2rem 0rem 1.2rem 1.2rem;
//         width: 100%;

//         &::before {
//             right: auto;
//             left: 0;
//             border-left: 17px solid #000;
//             z-index: 0;
//         }

//         .fill {}

//         &:hover {
//             background: #4A9CEB;
//             border-radius: 40px;

//             a {
//                 color: #fff;

//                 text-decoration: none;
//             }

//         }

//         &:last-child {
//             border-right: none;
//             border-bottom: none;
//         }

//         a {
//             color: #7d7d7e;
//             text-decoration: none;
//             margin-bottom: 0;
//         }

//         @media screen and (max-width: breakpoint(sm)) {
//             border-bottom: 0;
//             text-align: center;
//             padding: 1.2rem 1.2rem 1.2rem 1.2rem;
//             // border-right: 0;

//         }

//         .sm-d-none {
//             @media screen and (max-width: breakpoint(sm)) {
//                 display: none;
//             }

//         }
//     }
// }
