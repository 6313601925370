@import '../../../css/global.variables.scss';

.azure-media-player {
  padding: 3rem 1rem 1rem 1rem;

  @media screen and (min-width: breakpoint(sm)) {
    padding: 5rem 15rem;
  }

  .mp-component_azure-mp-container__idgeq {
    width: 90vw;
    height: 45vw;
    padding-bottom: 0 !important;
    @media screen and (min-width: breakpoint(sm)) {
      width: 40vw;
      height: 20vw;
    }
  }
}

.go-back-button {
  margin: 0 0 -0.8rem 0;

  @media screen and (min-width: breakpoint(sm)) {
    margin: 0 0 5rem 0;
  }
}