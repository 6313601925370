@import "../../../css/global.variables.scss";

.filtered-support-info {
    .title-filters {
        display: none;
        border-bottom: 2px solid #313830;

        @media (min-width: breakpoint(lg)) {
            display: flex;
            // margin-bottom: 2.5rem;
        }
    }

    .title-filters-select {

        select {
            display: none;
            width: 70%;
            padding: .5rem .5rem .5rem .75rem;
            font-size: 16px;
            background: #fff;
            border: 1px solid #707070;

            @media screen and (max-width: breakpoint(lg)) {
                display: block;
            }
        }
    }


    .title-filters .filter {
        position: relative;
        font-size: px-to-rem(22px);
        color: #313830;
        margin-right: 40px;
        margin-bottom: 0;
        padding-bottom: 1.25rem;
        overflow: hidden;
        cursor: pointer;

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 8px;
            background: #EF6008;
            transform: translateX(-105%);
            transition: transform .35s $custom_animation;
        }

        &.active,
        &:hover {
            &::before {
                transform: translateX(0);
            }
        }

    }

    .section-title {
        font-size: px-to-rem(22px);
        color: #313830;
    }

    .mobile-seperator {
        display: block;
        width: 100%;
        background: #313830;
        margin: 1.5rem 0;

        @media (min-width: breakpoint(sm)) {
            margin: 1.5rem 0 2rem 0;
        }

        @media (min-width: breakpoint(md)) {
            margin: 1.5rem 0 2rem 0;
        }
    }

    .displays {
        margin-top: 2rem;
    }
}