@import "../../../css/global.variables.scss";

.search-filter-container {
    margin-top: 2rem;

    .title {
        font-weight: 500;
        // margin-bottom: 1rem;

        @media screen and (max-width: breakpoint(sm)) {
            padding: .25rem 1rem;
        }
    }

    ul {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding-left: 0;

        li {
            background-color: #237DA7;
            color: #fff;
            // color: #005777;
            font-weight: 500;
            font-size: px-to-rem(14px);
            display: flex;
            align-items: center;
            border-radius: 30px;
            padding: .25rem 1rem;

            @media screen and (min-width: breakpoint(sm)) {
                margin: 0 .50rem .50rem 0;
                // border: 1px solid #0A7DA7;
                min-height: 39px;
            }

            .close-btn {
                color: #fff;
            }
        }

        .list-item-text {

            @media (min-width: breakpoint(sm)) {
                margin-bottom: -2px;
            }
        }
    }
}