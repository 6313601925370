@import '../../../css/global.variables.scss';

.image-carousel {

    @media (min-width: breakpoint(md)) {
        padding: 2.5rem 0;
    }

    .carousel-container {
        // display: flex;
        // justify-content: space-between;

        @media (min-width: breakpoint(md)) {
            margin-top: 1.5rem;
        }

        .image-content {
            // width: 50%;
            padding: 2rem;

        }

        .image-title {
            margin: 0;
            color: #313830;
            text-decoration: none;
        }
    }
}