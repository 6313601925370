@import "../../../css/global.variables.scss";

.pagination {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;

    @media screen and (max-width: breakpoint(sm)) {
        justify-content: center;
    }

    .page-link {
        color: #313830;
        background: none;
        border: none;

        &:hover {
            text-decoration: underline;
        }
    }

    li {
        a {
            border: none;
            color: #313830;
            padding: .75rem;
            margin-right: 0.3em;
            user-select: none;
            cursor: pointer;

            @media screen and (max-width: breakpoint(sm)) {
                padding: .5rem;
            }
        }

    }

    li.active {
        background: none;
        color: #313830;
        font-weight: 500;
    }
}

.ehs-listing-wrapper .pagination {
    justify-content: center;
}

.ehs-landing .pagination {
    justify-content: center;
}

// // Individual Location Temp
// .individual-location-container .pagination {
//     justify-content: center !important;
// }