@import "../../../../css/global.variables.scss";

.website-problem-container {
    .title {
        font-weight: 500;
        text-transform: capitalize;
        margin-bottom: 0;

        @media screen and (min-width: breakpoint(md)) {
            font-size: px-to-rem(26px);
        }
    }

    .problem-points {
        display: flex;
        flex-wrap: wrap;
    }

    .content {
        margin-bottom: 2rem;
    }

    .point-name {
        font-weight: 500;
        font-size: px-to-rem(18px);
        margin-bottom: .5rem;
    }

    .point-content {
        margin-bottom: 2rem;
    }
}