@import '../../../css/global.variables.scss';

.product-display {

  @media (min-width: breakpoint(md)) {
    display: flex;
  }

  .product-images {
    display: none;

    @media (min-width: breakpoint(md)) {
      display: block;
      width: 40%;
      max-width: 400px;
      // padding-right: 15px;
    }

    @media (min-width: breakpoint(lg)) {
      max-width: 450px;
    }
  }

  .content {

    @media (min-width: breakpoint(md)) {
      // width: 60%;
      flex: 1;
      padding-left: 3%;
    }
  }

  .icon {
    margin-right: 1rem;
  }

  .icon > svg {
    width: 36px;

    @media (min-width: breakpoint(sm)) {
      width: 53px;
    }

    @media (min-width: breakpoint(lg)) {
      width: 70px;
    }
  }

  .top {
    display: flex;
    margin-bottom: -30px;
    @media (max-width: breakpoint(sm)) {
      margin-bottom: 0;
      align-items: center;
      justify-content: center;
    }
  }

  h1 {
    font-size: px-to-rem(28px);
    font-weight: 400;
    margin-top: 4px;

    @media (min-width: breakpoint(sm)) {
      font-size: px-to-rem(36px);
      margin-top: -4px;
    }

    @media (min-width: breakpoint(md)) {
      font-size: px-to-rem(48px);
    }

    @media (min-width: breakpoint(lg)) {
      font-size: px-to-rem(60px);
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: .5rem 0 .75rem 0;

    @media (min-width: breakpoint(sm)) {
      margin: 2rem 0 2rem 0;
      // align-items: flex-start;
      flex-direction: row;
    }

    @media (min-width: breakpoint(md)) {
      margin: 1.5rem 0 2rem 0;
    }
  }

  button.cta {
    background: #F1762A;
    color: white;
    font-weight: 500;
    padding: .5rem 1.5rem;
    outline: none;
    border: none;
    margin-right: 10px;
    transition: all .3s ease;
    border: 2px solid #F1762A;

    &:hover {
      background: white;
      color: #F1762A;
    }
  }

  .custom-button {
    @media (max-width: breakpoint(sm)) {
      width: 70%;
    }
  }
}