@import "../../../../css/global.variables.scss";

.job-request-container {

    margin-bottom: 3rem;

    .content-widget-container {
        @media screen and (min-width: breakpoint(sm)) {
            padding-right: 3rem;
        }

        @media screen and (max-width: 768px) {
            display: block;
        }
    }
}