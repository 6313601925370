@import "../../../css/global.variables.scss";

.webinar-container {
    .bottom-content {
        .title {
            color: #313830;
            font-weight: 500;
            text-transform: capitalize;
            font-size: px-to-rem(20px);
        }

        margin-bottom: 4rem;
    }
}
//KT add style for responsive  11-12-2021
.trinity-container-changed-responsive{
    .existing-login-container{
        margin-top: 2rem;
    }
    @media (max-width:breakpoint(md)) {
        .left{
            padding-right: 0 !important;
        }
        .custom-button{
            height: auto;
        }

    }
    @media (max-width:breakpoint(sm)) {
        .webinar-form-container {
            width: 100vw;
            margin-left: calc(50% - 50vw);
        }
    }
}