@import '../../../css/global.variables.scss';

.content-product-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hide-in-mobile {
  align-self: flex-start;
  @media screen and (max-width: breakpoint(sm)) {
    display: none;
  }
}

.product-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  // max-width: 30rem;
  position: relative;


  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, .1);
  @media screen and (max-width: breakpoint(md)) {
    max-width: 100%;
  }

  .widget-icon {
    width: 35px;
    text-align: left;
  }

  .day {
    display: inline-block;
    font-weight: 500;
  }


  .locations {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: breakpoint(sm)) {
      width: 50%;
    }
  }

  .price-display-in-mobile {
    display: none;
    @media screen and (max-width: breakpoint(sm)) {
      display: flex;
      flex: 0.5
    }
  }


  .warning-text {
    font-weight: 500;
    height: 100%;
    //flex-direction: column;
    margin-top: 1rem;

    .warning-1,
    .warning-2 {
      font-size: px-to-rem(14px);
      color: #F1762A;
    }
  }


  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: breakpoint(sm)) {
      flex-direction: row !important;
      justify-content: space-between;
    }

    .custom-button {
      border-radius: 30px;
      display: block;

      @media screen and (max-width: breakpoint(sm)) {
        width: 140px;
      }
    }
  }

  .price {
    color: #EF6008;
  }


}


.ribbon {
  width: 135px;
  height: 70.5px;
  background-color: #ef6008;
  position: absolute;
  right: 0px;
  left: 0.5px;
  //top:-350px;
  animation: drop forwards .2s 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  animation: shine 5s ease-in-out infinite;
  animation-fill-mode: forwards;

  &:before {
    content: '';
    position: absolute;
    z-index: 2;
    left: 0;
    //bottom: -35px;
    //border-left: 35px solid;
    //border-right: 35px solid;
    //border-bottom: 35px solid transparent;
  }

  &:after {
    content: '';
    width: 200px;
    height: 270px;
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: -120px;
    //background-color;
    transform: skewY(35deg) skewX(0);
  }

  .textlayout {
    //transform: rotate(45deg);
    //top: -25px;
    //padding-top: 25px;
    font-family: sans-serif;
    color: white;
    text-align: right;
    vertical-align: top;
  }
}

.product-title {
  font-size: 1.75rem;
}

.product-subtitle {
  font-size: 1.25rem;
}

@keyframes drop {
  0% {
    top: -250px;
  }
  100% {
    top: 0;
  }
}

