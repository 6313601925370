@import '../../../../css/global.variables.scss';


.filtered-title-map {
    padding: 1rem 0;

    .trinity-select-list span.label {
        font-weight: 500;
        text-align: left;
        font-size: px-to-rem(15px);
    }

    .content {
        margin-top: 1rem;

        @media (min-width: breeakpoint(sm)) {
            margin-top: 3rem;
        }
    }

    .title-filters {
        display: none;
        border-bottom: 2px solid #313830;

        @media (min-width: breakpoint(md)) {
            display: flex;
            margin-bottom: 2.5rem;
        }
    }

    .title-filters .filter {
        font-size: px-to-rem(22px);
        color: #313830;
        margin-right: 40px;
        margin-bottom: 2rem;
        cursor: pointer;

        &.active {
            // color: #EF6008;
            border-bottom: 7px solid #EF6008;
            margin-bottom: 0;
        }
    }



    .mobile-seperator {
        display: block;
        width: 100%;
        background: #313830;
        margin: 1.5rem 0;
    }
}