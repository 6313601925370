@import '../../../css/global.variables.scss';

.training-tertiary .partner-layout {
    margin-top: 1rem;

    .partner-item {
        padding-bottom: 3rem;
        border: 1px solid #707070;

        @media screen and (max-width: breakpoint(sm)) {
            border: none;
        }

        .partner-name-container {
            background: #24674a;
            width: 100%;
            color: #fff;
            padding: 1rem;
            text-align: center;

            h3 {
                align-items: center;
                font-weight: 400;

                @media screen and(min-width: breakpoint(md)) {
                    font-size: px-to-rem(24px);
                }
            }
            h2 {
                align-items: center;
                font-weight: 400;

              
            }
            @media screen and (max-width: breakpoint(sm)) {
                width: 100vw;
                position: relative !important;
            }
        }

        .second-wrapper {
            width: 100%;

            hr {
                width: 90%;
            }

            .content {
                align-items: center;
                padding: 2rem;
                text-align: center;

                @media screen and (min-width: breakpoint(xl)) {
                    font-size: px-to-rem(24px);
                }

                @media screen and (min-width: breakpoint(lg)) {
                    font-size: px-to-rem(22px);
                }

                @media screen and (max-width: breakpoint(md)) {
                    font-size: px-to-rem(22px);
                }

                @media screen and (max-width: breakpoint(sm)) {
                    font-size: px-to-rem(16px);
                }
            }

            .location-cart-wrapper {
                display: flex;
                justify-content: space-between;
                margin-left: 3%;
                margin-right: 3%;
                padding: 1rem;
                border-bottom: 1px solid #9f9f9f;
                
                @media screen and (max-width: breakpoint(sm)) {
                    display: flow-root;
                }

                @media screen and (max-width: breakpoint(lg)) {
                    display: block;
                }

                .locations {
                    .location {
                        font-weight: 500;
                        margin-bottom: 1rem;
                    }

                    .date,
                    .subContent {
                        font-weight: 400;
                        color: #313830;
                    }

                    .date {
                        @media screen and (min-width: breakpoint(sm)) {
                            font-size: px-to-rem(14px);
                        }
                    }

                    .subContent {
                        text-decoration: underline;
                        padding-right: 1rem;

                        @media screen and (min-width: breakpoint(lg)) {
                            font-size: px-to-rem(20px);
                        }

                        @media screen and (max-width: breakpoint(md)) {
                            font-size: px-to-rem(16px);
                        }
                    }

                    p {
                        margin-bottom: 0px;
                    }
                }

                .buttons {
                    flex-direction: column;
                    justify-content: center;

                    @media screen and (min-width: breakpoint(lg)) {
                        font-size: px-to-rem(18px);
                    }

                    @media screen and (max-width: breakpoint(lg)) {
                        margin-top: 0.75rem;
                    }

                    @media screen and (max-width: breakpoint(md)) {
                        font-size: px-to-rem(16px);
                    }
                }
            }
        }
    }
}