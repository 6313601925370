@import "../../../../css/global.variables.scss";


.outer-confirm-container {
    border: 2px solid #999D99;
    width: 100%;
    margin-bottom: 3rem;

    .confirmation-container {
        padding: 2rem;

        .title {
            font-weight: 500;
            font-size: px-to-rem(24px);
            margin-bottom: 2rem;
        }

        .fa-check {
            color: green;
            font-size: px-to-rem(24px);
        }

        .fa-spin {
            font-size: px-to-rem(24px);
            color: #F1C410;
        }

    }

    a {
        color: #303830;
        font-weight: 500;
    }

    .button {
        text-align: center;

        @media screen and (max-width: breakpoint(sm)) {
            text-align: left;
        }
    }

    .custom-button {

        text-align: center;

        @media screen and (max-width: breakpoint(sm)) {
            display: block
        }
    }
}