@import '../../../../css/global.variables.scss';

.contact-cta {
    display: none !important;
    font-family: $font_secondary;
    border: 3px solid rgba(74, 155, 184, 1);
    color: rgba(74, 155, 184, 1);
    padding: .55rem;

    @media screen and (min-width: breakpoint(sm)) {
        display: flex !important;
    }

    @media screen and (min-width: breakpoint(md)) {
        padding: 1.75rem 1rem 1.75rem 1rem;
    }

    .icon {
        width: 53px;
        height: auto;
        display: flex;
        align-items: flex-end;

        @media screen and (min-width: breakpoint(md)) {
            width: 80px;
            align-items: center;
            position: relative;
            top: 3px;
        }

        @media screen and (min-width: breakpoint(lg)) {
            width: 100px;
        }
    }

    .contact-section {
        display: flex;
        justify-content: center;
        padding: .4rem .85rem .1rem .85rem;
        width: 33.33%;
    }

    .content {
        padding-left: 1.1rem;
        width: fit-content;
    }

    p,
    a {
        display: block;
        min-width: 100%;
        color: rgba(74, 155, 184, 1);
        font-weight: bold;
        font-size: px-to-rem(16px);
        line-height: 23px;

        @media (min-width: breakpoint(md)) {
            font-size: px-to-rem(24px);
            line-height: 34px;
        }

        @media (min-width: breakpoint(lg)) {
            font-size: px-to-rem(28px);
            line-height: 32px;
        }
    }

    .contact-btn {
        position: relative;
        font-size: px-to-rem(14px);
        font-weight: 500;

        @media (min-width: breakpoint(md)) {
            top: 12px;
            font-size: px-to-rem(20px);
            line-height: 28px;
        }

        @media (min-width: breakpoint(lg)) {
            position: static;
            margin-bottom: 5px;
        }
    }

    p {
        margin-bottom: .2rem;
    }

    .phone {
        display: block;
    }

    .contact-us {
        width: 33.333%;
        display: flex;
        //flex-direction: row-reverse;
        border-right: 2px solid rgba(74, 155, 184, 1);
        padding-left: 0;

        @media (min-width: breakpoint(md)) {
            padding-left: .85rem;
            width: calc(100% / 3);
            flex-direction: row;
            padding-right: 1.5%;
        }



        .content {
            max-width: 250px;

            @media (min-width: breakpoint(md)) {
                max-width: unset;
                padding-left: 0;
                padding-right: 1.25rem;
            }

            @media (min-width: breakpoint(lg)) {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }

    .phone-numbers-container {
        width: 50%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;

        @media (min-width: breakpoint(md)) {
            width: calc(100% * 2/3);
            flex-direction: row;
        }

        .contact-section {
            &:last-of-type {
                padding-right: 0;
            }
        }

        .content {
            display: flex;
            align-items: center;

            @media (min-width: breakpoint(md)) {
                // padding-left: 5%;
            }
        }
    }

    .within-united-states {
        padding-left: 3%;
        padding-right: 0;
        align-items: center;
        justify-content: center;

        @media (min-width: breakpoint(sm)) {
            border-right: 2px solid rgba(74, 155, 184, 1);
            padding: .4rem .85rem .1rem .85rem
        }

        .content {
            // padding-left: .25rem;

            @media (min-width: breakpoint(md)) {
                // padding-right: .85rem;
                // padding-left: 5%;
            }
        }
    }
}
