@import "../../../css/global.variables.scss";

.safe-bridge-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.safe-bridge-top-heading {
  color: #00326F;
  font-size: px-to-rem(28px);
}

.safe-bridge-row {
  margin-bottom: 15px;
}

.safe-bridge-sub-heading {
  color: #66737F;
  font-size: px-to-rem(16px);
}

.safe-bridge-sub-heading-content {
  color: #00326F;
  font-size: px-to-rem(20px);
}

.safe-bridge-print-button-style {
  padding: .10rem 1.1rem;
  height: 45px;
  color: white;
  background-color: #EF6008;
}

.safe-bridge-tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.safe-bridge-tooltip .safe-bridge-tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 6px;

  position: absolute;
  z-index: 1;
}

.safe-bridge-tooltip:hover .safe-bridge-tooltiptext {
  visibility: visible;
}