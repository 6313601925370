@import '../../../../css/global.variables.scss';

.create-account-form {

    label {
        margin: .5rem 0;
    }

    .help {
        color: red;
    }

    form {
        display: flex;
        flex-direction: column;

        @media (min-width: breakpoint(md)) {
            flex-direction: row;
        }
    }

    .left, .right {

        @media (min-width: breakpoint(md)) {
            width: 50%;
        }
    }

    h2 {
        margin-bottom: .75rem;
    }
    .left {}

    .form-section {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;
    }

    .inner-paragraph {
        margin-bottom: .5rem;
    }
}