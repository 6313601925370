@import '../../../css/global.variables.scss';

.product-carousel {
    // height: 500px;
    @media (max-width: breakpoint(sm)) {
        padding: 0 1.1875rem;
    }

    .carousel-container {
        // margin-top: 1.5rem;

        @media (min-width: breakpoint(md)) {
            margin-top: 2rem;
        }
    }

    .slick-slide {

        @media (max-width: breakpoint(sm)) {
            width: 100%;
        }
    }
}
