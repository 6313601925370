@import '../../../../css/global.variables.scss';

.box-container {
  margin-top: 4rem;

  .title {
    margin-bottom: 2rem;

    @media screen and (min-width: breakpoint(sm)) {
      font-size: px-to-rem(40px);
    }

    @media screen and (max-width: breakpoint(sm)) {
      font-size: px-to-rem(20px);
    }
  }

  .ctas {
    display: flex;
    //flex-wrap: wrap;

    .bkImg {
      background-position: center;
      background-size: cover;
      min-height: 350px;
      width: 300px;
      position: relative;
      margin-right: 1rem;
      margin-bottom: 1rem;

      @media screen and (max-width: breakpoint(sm)) {
        width: 100%;
      }

      .content {
        background-color: rgb(105, 162, 220);
        color: #fff;
        padding: 1rem;
        position: absolute;
        bottom: 0;
        width: 100%;
        opacity: 0.9;
        .name {
          a {
            color: #fff;
            cursor: pointer;
          }
        }

        p {
          margin-bottom: 0px;
        }
      }
    }
  }

}