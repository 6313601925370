@import '../../css/global.variables.scss';

.datagrid-header-column {
  font-size: 16px;
  font-weight: 400;
}

.datagrid-dark-row {
  background-color: #EFEFEF;
}

.user-list-table {

  p {
    margin-bottom: 0rem;
  }

  .wrapper {

    .second-widget {
      margin-top: 5rem;
    }

    .widget-hr {
      border: 2px solid #707070;
    }

  }

  .top-container {
    margin-bottom: 3rem;

    @media screen and (max-width: breakpoint(sm)) {
      position: relative;
      width: 100vw;
      position: relative;
      left: calc(-50vw + 50%);
      margin-top: 1rem !important;
    }

  }

  .first-desc {
    font-weight: bold;
    margin-bottom: 1.5rem;
    text-transform: capitalize;
  }

  .second-desc {
    text-transform: capitalize;
  }

  .top-container {
    margin-top: 4rem;
  }
}

.search-filter-container {

  @media screen and (max-width: breakpoint(sm)) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .search-box {
    background-color: white;
    height: 45px;
    margin-bottom: 2%;
    display: flex;
    align-items: center;
    padding: 10px;
    box-shadow: #efefef 0px 0px 2px 0px, #9f9f9f 0px 2px 8px 0px;


    .search-input {
      flex: 4;
      border: none;
      outline: none;
      margin-right: 40px;

      @media screen and (max-width: breakpoint(sm)) {
        width: 80%;
        flex: 1;
        margin-right: 0;
      }
    }

    @media screen and (max-width: breakpoint(sm)) {
      width: 80%;
      margin-right: 0;
    }
  }
}