@import "../../../../css/global.variables.scss";


.indiv-container {
    margin-top: 0 !important;
    margin-bottom: 3rem;

    .text-content-container {
        margin-bottom: 2rem;

        .text-content {

            width: 100vw;
            position: relative;
            left: calc(-50vw + 50%);
            padding: 2rem 0;
            background-color: rgba(49, 56, 48, 0.85);

            .name {
                font-size: px-to-rem(45px) !important;
                line-height: 50px;
                color: #fff;
                font-weight: 300;

            }

            .title {
                font-size: px-to-rem(24px);
                line-height: 40px;
                color: #fff;
                font-weight: 300;
            }
        }
    }

    .author-hero {
        margin-bottom: 3rem;
        background: #323830;
        padding-top: 3rem;
        padding-bottom: 3rem;

        position: relative;
        width: 100vw;
        left: calc(-50vw + 50%);

        @mixin container ($xs: true, $sm: true, $md: true, $lg: true) {

            @if ($xs) {
                @media (max-width: breakpoint(sm)) {
                    padding-left: px-to-rem(19px);
                    padding-right: px-to-rem(19px);
                    @include marginCenter;
                }
            }

            @if ($sm) {
                @media (min-width: breakpoint(sm)) and (max-width: breakpoint(md)) {
                    padding-right: px-to-rem(24px);
                    padding-left: px-to-rem(24px);
                    @include marginCenter;
                }
            }

            @if ($md) {
                @media (min-width: breakpoint(md)) and (max-width: breakpoint(lg)) {
                    padding-right: px-to-rem(63px);
                    padding-left: px-to-rem(63px);
                    @include marginCenter;
                }
            }

            @if ($lg) {
                @media (min-width: breakpoint(lg)) {
                    padding-right: px-to-rem(109px);
                    padding-left: px-to-rem(109px);
                    max-width: calc(#{$container_max} + 218px);
                    @include marginCenter;
                }
            }
        }




        .hero_name,
        .hero_title {
            color: #fff;

        }

        .hero_name {
            margin-bottom: 0;
            font-size: px-to-rem(35px);

            @media screen and (max-width: breakpoint(sm)) {
                font-size: px-to-rem(28px);
            }
        }

        .hero_title {
            font-size: px-to-rem(28px);

            @media screen and (max-width: breakpoint(sm)) {
                font-size: px-to-rem(20px);
            }
        }

    }
}