@import '../../../../css/global.variables.scss';

.product-filter-controls {
    display: flex;
    justify-content: flex-end; 
    align-items: center;
    flex-direction: column;
    margin: .75rem 0rem 1.25rem 0;
    padding: 0 1rem;

    @media (min-width: breakpoint(sm)) {
        // margin: 1.25rem 0rem;
        align-items: flex-end;
    }

    @media (min-width: breakpoint(md)) {
        // margin: 35px 0;
    }

    @media (min-width: breakpoint(lg)) {
        // margin: 40px 0;
    }

    .filter-buttons {
        justify-content: center;
    }

    .isActive {
        color: #EF6008;
    }
}