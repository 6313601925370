@import '../../../css/global.variables.scss';

.partner-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    // max-width: 30rem;

    @media screen and (max-width: breakpoint(md)) {
        max-width: 100%;
    }

    .locations {
        display: flex;
        flex-direction: column;
    }

    .warning-text {
        font-weight: 500;
        display: flex;
        justify-content: flex-end;
        height: 100%;
        flex-direction: column;
        margin-top: 1rem;

        .warning-1,
        .warning-2 {
            font-size: px-to-rem(14px);
            color: #F1762A;
        }
    }


    .buttons {
        display: flex;
        flex-direction: column;

        .custom-button {
            border-radius: 30px;
            display: block;
        }
    }

    hr.hr {
        border: 1px solid #707070;
    }

    .content {
        color: #24674A;
        margin-bottom: 12px;
    }
}