@import '../../../../css/global.variables.scss';
.job-alert-changed-responsive{
  margin-top: 2rem;
  input{
    margin-bottom: 0 !important;
  }
  .recaptcha{
    display: flex;
    justify-content: center;
  }
  @media screen and (max-width: breakpoint(md)){
    .recaptcha{
      display: flex;
      justify-content: center;
    }
    .text-input{
      &:last-child{
        margin-bottom: 0;
        input{
          margin-bottom: 0;
        }
      }
    }
  }
  @media screen and (max-width: breakpoint(sm)) {
    .recaptcha {
      justify-content: flex-start;
    }
  }
}