@import "../../../../css/global.variables.scss";

.existing-login-container {
    display: flex;
    justify-content: space-between;
    border: 2px solid rgba(0, 0, 0, 0.1);
    padding: 2rem;
    align-items: flex-end;

    @media screen and (max-width: breakpoint(sm)) {
        display: block;
    }

    .content {
        padding-right: 3rem;

        @media screen and (max-width: breakpoint(sm)) {
            padding-right: 0;
        }

        .title {
            color: #313830;
            font-weight: 500;
            text-transform: capitalize;
            font-size: px-to-rem(20px);
        }
    }
}