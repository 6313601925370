@import "../../../../css/global.variables.scss";

.webinar-form-container {
    background: #E9F7FF;
    padding: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;

    .title {
        color: #313830;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 1rem;
        font-size: px-to-rem(26px);
    }

    .webinar-form {
        margin-top: 2rem;

        .help {
            color: red;
        }


        .recaptcha {
            margin-top: 2rem;
            margin-bottom: 1rem;
        }

        .recaptcha,
        .link {
            text-align: -webkit-center;

            @media screen and (max-width: breakpoint(sm)) {
                text-align: -webkit-left;
            }
        }

        .inner-form {


            .left {
                //padding-right: 2rem;

                @media screen and (max-width: breakpoint(sm)) {
                    padding-right: 0;
                }
            }

            .trinity-select-list {
                .select-wrapper {
                    margin-bottom: .5rem;
                }
            }

        }
    }
}