@import "../../../../../css/global.variables.scss";

.bio-profile-container {

    width: 100%;

    .bio-top {
        @media screen and (min-width: breakpoint(md)) {
            display: flex;
        }

        margin-bottom: 3rem;

        .image {
            display: flex;

            img {
                width: 20rem;
            }
        }



        .bio-sub-text {
            text-align: left;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            margin-top: 2rem;


            .content {
                margin-top: 2rem;
            }

            p {
                margin: 0;
            }

            .title {
                @media screen and (min-width: breakpoint(md)) {
                    font-size: px-to-rem(18px);
                }

                font-weight: 400;
            }

            .name {
                @media screen and (min-width: breakpoint(lg)) {
                    font-size: px-to-rem(32px);
                }

                @media screen and (min-width: breakpoint(md)) {
                    font-size: px-to-rem(32px);
                }

                @media screen and (max-width: breakpoint(md)) {
                    font-size: px-to-rem(25px);
                    margin-top: 1rem;
                }





                font-weight: 300;
                margin-bottom: 1rem;
            }

            a.phone {


                @media screen and (min-width: breakpoint(md)) {
                    font-size: px-to-rem(18px);
                }

                font-weight: 400;
            }

            a {
                text-decoration: none;
                color: #313830;
                font-weight: 400;

                @media screen and (min-width: breakpoint(md)) {
                    font-size: px-to-rem(18px);
                }

                &:hover {
                    text-decoration: underline;
                }
            }

            @media screen and (min-width: breakpoint(md)) {
                margin-left: 2rem;
            }
        }
    }
}