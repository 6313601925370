@media screen and (max-width: 320px) {
  .recaptcha {
    transform: scale(0.8);
  }
}
@media screen and (max-width: 767px){
  .recaptcha {
    text-align: center;
    display: flex;
    justify-content: center !important;
  }
}

@media screen and (min-width: 767px) and (max-width: 1200px) {
  .link, .recaptcha {
    text-align: center;
    display: flex;
    justify-content: center;
  }
}
.help {
  display: block;
  position: relative;
  top: -7px;
  margin-bottom: 0;
}
 textarea {
  border: thin solid #ced4da !important;
}
@media screen and (min-width: 767px){
  .error_Form {
    .max-height-input {
      min-height: 78px;
    }
  }
}

