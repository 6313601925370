@import "../../../css/global.variables.scss";

.bg-tags {
    background-color: #237DA7;
    border-color: #237DA7;
}

.bg-news {
    background-color: #398AAF;
    border-color: #398AAF;
}

.bg-industry {
    background-color: #4F97B8;
    border-color: #4F97B8;
}

.bg-pdate {
    background-color: #65A4C1;
    border-color: #65A4C1;
}

.bg-location {
    background-color: #7BB1CA;
    border-color: #7BB1CA;
}

.widget-container {
    background: #EBEBE8;
    padding: 2rem;

    .search-title {
        text-transform: uppercase;
    }

    h2 {
        color: #0F2748;
    }

    .btn {
        background: #0F2748 !important;
        font-size: px-to-rem(18px);
        color: #fff;
    }

    @media screen and (min-width: breakpoint(lg)) {

        h2 {
            font-size: px-to-rem(26px);
        }

    }

}


.training_lp .widget-container,
.training_lp_2 .widget-container {
    height: 100%;
    margin-left: 10px;
    // height: 100%;

    @media screen and (min-width: breakpoint(lg)) {
        h2 {
            margin-bottom: 1rem;
        }

    }

    @media screen and (max-width: breakpoint(lg)) {
        h2 {
            font-size: px-to-rem(20px);
        }

        .form-inline {
            margin-top: 2rem;
        }

    }


    @media screen and (max-width: breakpoint(sm)) {
        margin: 10px 0px 0px 0px;

        h2 {
            font-size: 16px;
        }

    }


    .form-control {
        margin-bottom: 1rem;
    }


    .button-container {
        display: flex;
        justify-content: center;
        margin-top: 1rem;

        @media screen and (max-width: breakpoint(lg)) {
            margin-top: 2rem;
        }

        .btn {

            @media screen and (min-width: breakpoint(lg)) {
                font-size: px-to-rem(18px);
            }

            @media screen and (min-width: breakpoint(sm)) {
                font-size: px-to-rem(16px);
            }

            padding: .5rem 4rem;
            border-radius: 0;
        }
    }
}


.training-advanced-search {
    background: #EBEBE8;    

    @media screen and (max-width: breakpoint(sm)) {
        width: 100vw;
        position: relative;
        left: calc(-50vw + 50%);
        margin-bottom: 2rem;
    }

    .btn {
        background: #0F2748;
        font-size: px-to-rem(18px);
        color: #fff;
    }

    .button-spacer {
        margin: 1.25rem 0 1.75rem 0;

        @media (min-width: breakpoint(md)) {
            margin: 1.75rem 0 2rem 0;
        }
    }

    h2,
    h3 {
        color: #0F2748;
    }

    h2 {
        font-size: px-to-rem(16px);
        font-weight: 500;
        margin-bottom: 1rem;

        @media (min-width: breakpoint(sm)) {
            margin-bottom: 1.1rem;
            font-size: px-to-rem(20px);
        }

        @media (min-width: breakpoint(md)) {
            margin-bottom: 1.5rem;
            font-size: px-to-rem(26px);
        }
    }

    h3 {
        font-size: px-to-rem(14px);
        font-weight: 400;
        margin: 0;

        @media (min-width: breakpoint(sm)) {
            font-weight: 500;
            font-size: px-to-rem(16px);
            margin-bottom: 1rem;
        }

        @media (min-width: breakpoint(md)) {
            margin-bottom: 1.5rem;
            font-size: px-to-rem(20px);
        }
    }

    .active-filters-title {

        @media (max-width: breakpoint(sm)) {
            margin-bottom: 1rem;
        }
    }

    ul {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding-left: 0;

        li {
            color: #fff;
            font-weight: 500;
            font-size: px-to-rem(14px);
            display: flex;
            align-items: center;
            border-radius: 30px;
            padding: .25rem 1rem;

            @media screen and (min-width: breakpoint(sm)) {
                margin: 0 .50rem .50rem 0;
                border: 1px solid #0A7DA7;
                min-height: 39px;
            }
        }

        .list-item-text {

            @media (min-width: breakpoint(sm)) {
                margin-bottom: -2px;
            }
        }
    }
}