@import "../../css/global.variables";

.thankyou-display {
  margin: px-to-rem(20px);

  .outer-container {
    margin: px-to-rem(30px) auto auto;
    width: 50%;
    border: 0 transparent;
    min-height: 200px;
    padding: 10px;
    box-shadow: 0 2px 10px #4E4E4E29;
    text-align: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
  }

  @media screen and (max-width: breakpoint(sm)) {
    .outer-container {
      width: 90%;
      margin: px-to-rem(10px) auto auto;
    }
  }

  .smile-icon {
    color: #5f5f5f;
    font-size: 90px;
    margin: px-to-rem(30px);

    @media screen and (min-width: breakpoint(lg)) {
      font-size: 90px;
    }

    @media screen and (max-width: breakpoint(md)) {
      font-size: 60px;
    }

    @media screen and (max-width: breakpoint(sm)) {
      font-size: 40px;
    }
  }

  .outer-font {
    font-size: 50px;
    color: #5f5f5f;
    margin-top: px-to-rem(20px);

    @media screen and (min-width: breakpoint(lg)) {
      font-size: 50px;
    }

    @media screen and (max-width: breakpoint(md)) {
      font-size: 30px;
    }

    @media screen and (max-width: breakpoint(sm)) {
      font-size: 20px;
    }
  }

  .light-font {
    color: #5f5f5f;
    font-size: 20px;

    @media screen and (min-width: breakpoint(lg)) {
      font-size: 20px;
    }

    @media screen and (max-width: breakpoint(md)) {
      font-size: 18px;
    }

    @media screen and (max-width: breakpoint(sm)) {
      font-size: 15px;
    }
  }

  .light-font-large {
    color: #5f5f5f;
    font-size: 40px;

    @media screen and (min-width: breakpoint(lg)) {
      font-size: 40px;
    }

    @media screen and (max-width: breakpoint(md)) {
      font-size: 35px;
    }

    @media screen and (max-width: breakpoint(sm)) {
      font-size: 30px;
    }
  }
}