@import '../../../../css/global.variables.scss';

.sidebar-product-download {
    background: #E9F7FF;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
    display: none;

    @media (min-width: breakpoint(md)) {
        display: flex;
    }

    a {
        text-decoration: underline;
        color: #313830;
        font-size: px-to-rem(14px);
        margin: .5rem 0;
    }
}