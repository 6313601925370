@import '../../../css/global.variables.scss';

.training-tertiary .product-layout {
    .product-item {
        padding-bottom: 3rem;
        border: 1px solid #707070;

        @media screen and (max-width: breakpoint(sm)) {
            border: none;
        }

        .product-name-container {
            background: #24674a;
            width: 100%;
            color: #fff;
            padding: 1rem;
            text-align: center;

            h3 {
                align-items: center;
                font-weight: 400;
                text-align: center;

                @media screen and(min-width: breakpoint(md)) {
                    font-size: px-to-rem(24px);
                }
                .textLayout{
                    text-align: center;
                    padding-right:200px;
                    flex-wrap:nowrap;
                }
            }
            h2 {
                align-items: center;
                font-weight: 400;
                text-align: center;
               
            }
            @media screen and (max-width: breakpoint(sm)) {
               // position: relative !important; removed to show ribbon on top in mobile
            }
        }

        .second-wrapper {
            width: 100%;

            hr {
                width: 95%;
            }

            .price {
                align-items: center;
                font-weight: 500;
                text-align: center;

                &:before {
                    content: "$";
                    font-size: 1.5rem;
                    vertical-align: text-top;
                }

                &:after {
                    content: "00";
                    font-size: 1.5rem;
                    vertical-align: text-top;
                }

                @media screen and (min-width: breakpoint(lg)) {
                    font-size: px-to-rem(58px);
                }

                @media screen and (min-width: breakpoint(md)) {
                    font-size: px-to-rem(58px);
                }

                @media screen and (max-width: breakpoint(md)) {
                    font-size: px-to-rem(48px);
                }

                @media screen and (max-width: breakpoint(sm)) {
                    font-size: px-to-rem(40px);
                }
            }

            .location-cart-wrapper {
                display: flex;
                justify-content: space-between;
                padding: 1rem;
                border-bottom: 1px solid #9f9f9f;
                margin-left: 3%;
                margin-right: 3%;

                @media screen and (max-width: breakpoint(sm)) {
                    display: flow-root;
                }

                .locations {
                    .location {
                        font-weight: 500;
                    }

                    .date,
                    .time {
                        font-weight: 400;
                    }

                    p {
                        margin-bottom: 0px;
                    }

                    @media screen and (min-width: breakpoint(lg)) {
                        font-size: px-to-rem(20px);
                    }

                    @media screen and (max-width: breakpoint(md)) {
                        font-size: px-to-rem(16px);
                    }

                    @media screen and (max-width: breakpoint(sm)) {
                        font-size: px-to-rem(16px);
                    }
                }

                .buttons {
                    flex-direction: column;

                    @media screen and (min-width: breakpoint(lg)) {
                        font-size: px-to-rem(18px);
                    }

                    @media screen and (max-width: breakpoint(md)) {
                        font-size: px-to-rem(16px);
                    }
                }
            }
        }
    }
}