@import '../../../../css/global.variables.scss';

.filter-button {
    font-size: px-to-rem(17px);
    font-weight: 500;
    padding: .8rem 2rem;
    text-align: center;
    // min-width: 170px;
    color: white;
    font-weight: 700;
    outline: none;
    border-bottom: 1px solid;
    border-radius: 30px;
    margin: .25rem 0;
    text-transform: capitalize;

    @media (min-width: breakpoint(sm)) {
        border-radius: 50px;
        padding: .5rem 2rem;
        color: color(text, accent);
        width: auto;
        margin-right: 10px;
        border: none;

        &:last-of-type {
            margin-right: 0;
        }
    }

    &:last-of-type {
        border: none;
    }



    @media (min-width: breakpoint(md)) {
        margin-right: 10px;
        font-size: px-to-rem(18px);
    }

    @media (min-width: breakpoint(lg)) {
        font-size: px-to-rem(24px);
    }
}