// ********************************************************
// ********************************************************
// **
// **  general component styles
// **
// ********************************************************
// ********************************************************



@import '../../../css/global.variables.scss';

.custom-button {
    position: relative;
    background: rgba(239, 96, 8, 1);
    color: white;
    margin: 5px 0;
    padding: .5rem 1rem;
    transition: all .2s ease;
    font-weight: 500;
    font-size: px-to-rem(18px);
    max-width: 100%;

    .text {
        transition: opacity .15s ease;
    }

    &:not(.variable-width) {
        width: 190px;
        //KT add style for width 100%  on mobile view
        @media screen  and (max-width: breakpoint(sm)){
            width: 100%;
        }
    }

    &:hover:not(.loading) {
        background: white;
        color: rgba(239, 96, 8, 1);
    }

    .arrow {
        margin-left: .25rem;
    }
}

.button-border{
    border: 2px solid rgba(239, 96, 8, 1);
}

.button-border-hide{
    border: none;
}

.custom-button.loading {
    .text {
        opacity: 0;
    }
}

.custom-button.alt-colors {
    background: white;
    color: rgba(239, 96, 8, 1);

    &:hover {
        background: rgba(239, 96, 8, 1);
        color: white;
    }
}

.custom-button.rounded-btn {
    border-radius: 20px;
}

.custom-button.xs-full-width-btn {
    @include container($sm: false, $md: false, $lg: false); // container only on xs breakpoint

    @media (max-width: breakpoint(sm)) {
        //max-width overide
        width: 100%;
    }

    &.has-arrow {
        display: flex;
        justify-content: space-between;

        @media (min-width: breakpoint(sm)) {
            display: block;
        }
    }
}


// ********************************************************
// ********************************************************
// **
// **  variable component specific styles
// **
// ********************************************************
// ********************************************************


.full-width-text-with-button .custom-button {
    width: 100%;
    font-weight: 500;
    text-align: left;
    font-size: px-to-rem(16px);
    margin: 1.5px 0;
    min-height: 43px;
    background: #ef6008;

    &:hover:not(.loading) {
        background: white;
        color: #ef6008;
    }

    @media (min-width: breakpoint(sm)) {
        text-align: center;
        width: 233px;
        font-weight: 600;
    }

    @media (min-width: breakpoint(md)) {
        padding: .75rem 1rem;
        width: 300px;
        font-size: px-to-rem(24px);
    }
}


// ********************************************************
// ********************************************************
// **
// **  page / template specific styles
// **
// ********************************************************
// ********************************************************


.sidebar-with-filters .custom-button {
    background: #0F2748;
    border-color: #0F2748;

    &:hover:not(.loading) {
        background: white;
        color: #0F2748;
    }
}

.software-secondary .custom-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

// ABOUT 
.career-listing-wrapper,
.career-listing-no-pag-wrapper {
    .custom-button {
        height: 100%;

        @media screen and (min-width: breakpoint(sm)) {
            margin-left: auto;
        }

        @media screen and (max-width: breakpoint(sm)) {
            margin-right: auto;
            margin-left: auto;
        }

    }
}

.career-listing-no-pag-wrapper {
    .custom-button {
        @media screen and (max-width: breakpoint(sm)) {
            margin-bottom: 2rem;
        }
    }
}

.ava-container {
    .advanced-search .custom-button {
        background: #0F2748;
        border-color: #0F2748;

        &:hover:not(.loading) {
            background: white;
            color: #0F2748;
        }
    }
}

// Webinar 
.existing-login-container {
    .custom-button {
        height: 100%;
        width: 200px;
    }
}

// Education program
.program-form-container {
    .custom-button {
        width: 230px;
    }
}

// homepage
.locations-content-container {
    .custom-button {
        background: #0F2748;
        border-color: #0F2748;

        &:hover:not(.loading) {
            background: white;
            color: #0F2748;
        }
    }
}