@import "../../../css/global.variables.scss";


.container {
  max-width: 100% !important;

  .image-with-overlay-text {
    width: 100%;

    @media (max-width: breakpoint(sm)) {
      background-image: none !important;
      // background: rgba(31, 123, 121, 0.73);
    }

    @media (min-width: breakpoint(sm)) {
      display: flex;
      align-items: flex-end;
      height: 232px;
    }

    @media (min-width: breakpoint(md)) {
      min-height: 408px;
    }

    @media (min-width: breakpoint(lg)) {
      max-width: 512px;
      min-height: 512px;
    }

    .text-container {
      width: 100%;
      color: white;
      // background: rgba(31, 123, 121, 0.73);
      padding: .75rem .75rem;

      @media (min-width: breakpoint(sm)) {
        margin-bottom: 1.25rem;
        padding: .6rem .75rem;
      }

      @media (min-width: breakpoint(md)) {
        margin-bottom: 1.5rem;
        padding: 1.5rem 1.5rem;
      }

      @media (min-width: breakpoint(lg)) {
        height: 252px;
        margin-bottom: 1.75rem;
        padding: 2rem 2rem;
      }
    }

    h3 {
      margin: 0;
      font-size: px-to-rem(16px);
      font-weight: 500;

      @media (min-width: breakpoint(sm)) {
        font-size: px-to-rem(18px);
      }

      @media (min-width: breakpoint(md)) {
        font-size: px-to-rem(28px);
      }

      @media (min-width: breakpoint(lg)) {
        font-size: px-to-rem(32px);
      }
    }

  }

  .content-container {
    display: none;
    padding-top: 1rem;
    padding-right: 1rem;

    p,
    a {
      color: #313830;
    }

  }
}

.policies,
.policies-individual-category .image-with-overlay-text {

  .text-container {
    @include container($sm: false, $md: false, $lg: false); // container only on xs breakpoint

    @media (max-width: breakpoint(sm)) {
      margin: 1.5px 0;
    }
  }

  h3 {
    display: flex;
    justify-content: space-between;

    @media (min-width: breakpoint(sm)) {
      display: block;
    }
  }
}

.careers-lp {
  .container {
    .image-with-overlay-text {
      .text-container {
        @media screen and (min-width: breakpoint(lg)) {
          margin-bottom: 0;
        }

        @include container($sm: false, $md: false, $lg: false); // container only on xs breakpoint

        @media (max-width: breakpoint(sm)) {
          margin: 1.5px 0;
        }

        .inner-content {
          margin-top: 1rem;

          @media screen and (max-width: breakpoint(lg)) {
            display: none;
          }
        }
      }


      h3 {
        display: flex;
        justify-content: space-between;

        @media (min-width: breakpoint(sm)) {
          display: block;
        }
      }
    }
  }

}