@import "../../../css/global.variables.scss";


.training_lp .image-text-container,
.training_lp_2 .image-text-container {
  background-image: url("../../../images/training/left-first-image.png");
  background-color: #9f9f9f;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  // height: 500px;
  color: #24674A;
  opacity: 0.95;

  h1 {
    font-weight: 400;
    margin-bottom: 2rem;
  }

  p {
    padding-right: 2rem;
  }

  @media screen and (min-width: breakpoint(lg)) {
    h1 {
      font-size: px-to-rem(40px);

    }

    p {
      font-size: px-to-rem(26px);

    }
  }

  @media screen and (max-width: breakpoint(lg)) {
    h1 {
      font-size: px-to-rem(28px);
      margin-bottom: 1rem;
    }

    p {
      font-size: px-to-rem(26px);

    }
  }

  @media screen and (max-width: breakpoint(lg)) {


    p {
      padding-bottom: 3rem;

    }
  }

  @media screen and (max-width: breakpoint(sm)) {
    height: 100%;

    h1 {
      font-size: px-to-rem(20px);
    }

    p {
      font-size: px-to-rem(16px);
    }
  }

  .link-text {
    font-size: 40px;
    color: #FFFFFF;
    @media screen and (max-width: breakpoint(sm)) {
      font-size: 22px;
    }
  }

  .center {
    margin: auto;
    padding: 10px;
  }

  .image-content-outer-wrapper-training-top {
    position: relative;
    height: 100%;
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    padding: 5px;
  }

  .wrapper-inner-training-top-container {
    position: relative;
    height: 50%;
    width: 50%;
    align-items: center;
    justify-content: space-around;
    justify-items: center;
    color: #FFF;
    display: inline-flex;
    padding: 5px;
    flex-direction: row;

    @media screen and (max-width: breakpoint(md)) {
      width: 100%;
      height: 25vh;
    }

    @media screen and (max-width: breakpoint(sm)) {
      width: 100%;
      height: 12vh;
    }
  }

  .inner-sub-wrapper-container {
    height: 100%;
    align-items: center;
    justify-items: center;
    display: flex;
    background-color: rgba(7, 175, 154, 0.85);
    justify-content: center;
    width: 100%;
  }

  .link-button:link, .link-button:visited {
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;

    @media screen and (max-width: 480px) {
      display: flex;
      color: white;
      padding: 0;
      text-decoration: none;
    }
  }
}