@import '../../../css/global.variables.scss';

$animation_time: .45s;

.video-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: opacity $animation_time ease;

    &.hide-modal {
        animation: .01s linear ($animation_time - .01) 1 exitScreen forwards;
        opacity: 0;
    }

    .video-container {
        max-width: 750px;
        width: 90%;

        @media (min-width: breakpoint(lg)) {
            max-width: 900px;
        }
    }
}