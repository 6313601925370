@import "../../../css/global.variables.scss";

.indiv-container {
    margin-top: 2rem;

    .hero {
        display: none;
    }

    hr {


        @media screen and (min-width: breakpoint(lg)) {
            width: 50%;
            position: absolute;
        }
    }

    .second {
        margin-bottom: 0;
    }

    .indiv-image {

        width: 100%;

        @media screen and (max-width: breakpoint(sm)) {
            display: block;
        }

        @media (min-width: breakpoint(sm)) {
            position: sticky;
            top: 30px;
        }
    }

    .title {
        text-align: left;
        margin-bottom: 0;

        @media screen and (min-width: breakpoint(xl)) {
            font-size: px-to-rem(32px);
            font-weight: 300;
        }

        @media screen and (max-width: breakpoint(xl)) {
            font-size: px-to-rem(28px);
            font-weight: 400;
        }
    }

    .date {
        text-align: left;

        @media screen and (min-width: breakpoint(sm)) {
            font-size: px-to-rem(18px);
        }

        @media screen and (max-width: breakpoint(sm)) {
            margin-top: 1rem;
            font-size: px-to-rem(14px);
        }
    }

    .news-image {

        @media (min-width: breakpoint(sm)) {
            position: static;
            top: 30px;
        }
    }

    .top-container {
        display: flex;

        margin-bottom: 4rem;
    }



    .middle-container {
        margin-top: 5rem;
        display: flex;
        // border-top: 1px solid rgba(0, 0, 0, 0.1);
        // border-bottom: 1px solid rgba(0, 0, 0, 0.1);


        @media screen and (max-width: breakpoint(sm)) {
            display: block;
        }
    }

    .author-title {
        text-transform: uppercase;
        font-weight: 500;
        margin: 0;

        @media (min-width: breakpoint(sm)) {
            font-size: px-to-rem(20px);
        }

        @media (min-width: breakpoint(lg)) {
            padding-top: 2rem;
        }

        // @media screen and (min-width: breakpoint(lg)) {

        // }
    }

    .custom-button {
        display: block;
    }

}