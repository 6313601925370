@import "../../../css/global.variables.scss";
@import "node_modules/react-modal-video/scss/modal-video.scss";

.video-cta-container {
    @media screen and (max-width: breakpoint(sm)) {
        position: relative;
        color: #313830;
    }

    position: relative;
    left: 0;
    right: 0;
    margin-left: calc(50% - 50vw);
    width: 100vw;

    img {
        // height: 100%;
        width: 100%;
    }


    .modal-video-container {
        position: absolute;
        top: 30%;
        left: 50%;

        @media screen and (max-width: breakpoint(sm)) {
            top: 10%;
        }

        @media screen and (max-width: 320px) {
            display: none;
        }

        .fa {
            font-size: 5rem;
            color: #0A7DA7;

            @media screen and (max-width: breakpoint(sm)) {
                font-size: 2rem;
            }
        }

        button {
            border-radius: 40px;


        }
    }

    .text-wrapper {
        position: absolute;
        top: 0;
        color: #fff;
        margin-top: 3rem;
        // height: 100%;

        @media screen and (max-width: breakpoint(md)) {
            margin-top: 1rem
        }

        @media screen and (max-width: breakpoint(sm)) {
            position: relative;
            color: #313830;
        }

        .title {
            font-weight: 500;

            @media screen and (min-width: breakpoint(lg)) {
                font-size: px-to-rem(42px);
            }

            @media screen and (max-width: breakpoint(lg)) {
                font-size: px-to-rem(28px);
            }

            @media screen and (max-width: breakpoint(sm)) {
                font-size: px-to-rem(18px);
            }
        }

        .copy {
            font-weight: 500;

            @media screen and (min-width: breakpoint(xl)) {
                font-size: 28px;
            }

            @media screen and (min-width: breakpoint(lg)) {
                font-size: 18px;
            }

            @media screen and (max-width: breakpoint(lg)) {
                font-size: 16px;
            }

            @media screen and (max-width: breakpoint(sm)) {
                font-weight: 400;
            }
        }
    }
}