@import "../../../css/global.variables.scss";

.resources {
    .outer-wrapper {


        .wrapper {
            padding-right: 10%;

            .widgetBox-wrapper {
                a {
                    color: #313830;

                    &:hover {
                        text-decoration: underline;
                    }

                    margin-bottom: 1rem;
                    font-weight: 500;

                    @media screen and (min-width: breakpoint(lg)) {
                        font-size: px-to-rem(20px);
                    }

                    @media screen and (max-width: breakpoint(lg)) {
                        font-size: px-to-rem(16px);
                    }
                }

                .widget-wrapper {


                    .date {
                        margin-bottom: 0px;
                        color: #313830;
                        font-size: px-to-rem(14px);
                    }

                    .name {
                        margin-top: .50rem;
                        font-size: px-to-rem(14px);
                    }

                }

            }

            @media screen and (min-width: breakpoint(lg)) {
                font-size: px-to-rem(18px);
            }

        }

        .arrow {
            border-top: 8px solid #707070;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            position: absolute;
            top: 43.5%;
            right: 1rem;
        }
    }
}

.bg-tags {
    background-color: #237DA7;
    border-color: #237DA7;
}

.bg-news {
    background-color: #398AAF;
    border-color: #398AAF;
}

.bg-industry {
    background-color: #4F97B8;
    border-color: #4F97B8;
}

.bg-pdate {
    background-color: #65A4C1;
    border-color: #65A4C1;
}

.bg-location {
    background-color: #7BB1CA;
    border-color: #7BB1CA;
}