@import "../../../../css/global.variables.scss";

.individual-container {
    margin-bottom: 4rem;

    .top-container {
        padding-right: 3rem;

        @media screen and (max-width: breakpoint(sm)) {
            padding-right: 0;
        }
    }


}