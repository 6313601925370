@import "../../../css/global.variables.scss";

.systems-container {
    .title {
        color: #313830;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 1rem;
        font-size: px-to-rem(26px);
    }

    .inner-wrapper {
        .inner-title {

            color: #313830;
            font-weight: 500;
            text-transform: uppercase;
            margin-top: 1rem;
            font-size: px-to-rem(18px);
            margin-bottom: 0;

        }

        .content {
            margin: 0;
        }
    }
}