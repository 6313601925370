@import "../../../css/global.variables.scss";

.cookie-container {
  background: rgb(103, 108, 115);
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  z-index: 2147483645;
  overflow: hidden;
  animation: Slide_Up 1s ease;
  @media screen and (max-width: breakpoint(sm)) {
    display: block;
  }

  p {
    color: #fff;
    margin: 0 auto;
    padding: 1rem;

    font-size: px-to-rem(18px);

    @media screen and (max-width: breakpoint(sm)) {
      padding-left: 0;
    }

    a {
      color: #fff;
    }
  }

  .cookie-buttons {
    .allow {
      margin-right: 1rem;
    }
  }
}

@keyframes Slide_Up {
  0% {
    transform: translateY(250px);
  }
  100% {
    transform: translateY(0);
  }
}
