@import "../../../css/global.variables.scss";

.cta-container {
    position: relative;
    left: 0;
    right: 0;
    margin-left: calc(50% - 50vw);
    width: 100vw;


    img {
        // height: 100%;
        width: 100%;

    }

    .text-wrapper {
        position: absolute;
        top: 0;
        color: #fff;
        margin-top: 2rem;
        height: 100%;

        @media screen and (max-width: breakpoint(md)) {
            margin-top: 1rem
        }

        @media screen and (max-width: breakpoint(sm)) {
            position: relative;
            color: #313830;
            height: auto;
        }


        .title {
            font-weight: 500;

            @media screen and (min-width: breakpoint(lg)) {
                font-size: px-to-rem(42px) !important;
            }

            @media screen and (min-width: breakpoint(md)) {
                font-size: px-to-rem(24px);
                ;
            }

            @media screen and (max-width: breakpoint(md)) {
                font-size: px-to-rem(18px);
            }

            @media screen and (max-width: 820px) {
                margin-bottom: 0;
            }
        }

        .first-copy {
            width: 70%;

            @media screen and (min-width: breakpoint(xl)) {
                font-size: 28px;
            }

            @media screen and (min-width: breakpoint(lg)) {
                font-size: 18px;
            }

            @media screen and (max-width: breakpoint(lg)) {
                font-size: 16px;
            }

            @media screen and (max-width: 820px) {
                margin-bottom: 0;
            }
        }

        .second-copy {
            font-weight: 300;
            position: absolute;
            bottom: 10%;



            @media screen and (max-width: 820px) {
                position: static;
            }
        }
    }
}