@import "../../../../css/global.variables.scss";

.country-details-container {
    padding: 1.57em 1.25em 1.57em 1.25em;
    border: .07em #ccc solid;
    box-shadow: 0 0 0.31em 0.13em rgba(107, 107, 107, .15);
    margin-bottom: 2rem;

    .country {
        font-size: px-to-rem(30px);
        font-weight: 500;

        @media screen and (max-width: breakpoint(md)) {
            font-size: px-to-rem(20px);
        }
    }

    .region-contact {
        align-items: baseline;
        justify-content: space-between;
    }

    .contact,
    .information {
        font-size: px-to-rem(18px);

        a {
            padding-left: .75rem;
        }
    }

    .fa {
        // padding-right: .75rem;
    }

    .region {
        font-size: px-to-rem(14px);
        font-weight: 500;
        border-bottom: 2px solid #31A4E5;
        width: 90px;
    }
}