@import '../../../css/global.variables.scss';

.image-title {
  word-break: normal;
  width: 280px;
}

.parent_item{
  position: relative;
}

.center_item {
  background-image:url(https://www.iconfinder.com/data/icons/iconic-1/32/play_alt-512.png);
  background-repeat: no-repeat;
  width:50px;
  height:50px;
  position:absolute;
  left:0%;
  right:0%;
  top:0%;
  bottom:0%;
  margin:auto;
  background-size:contain;
  background-position: center;
}