@import "../../../css/global.variables.scss";


// JUST TEMPORARY
.consulting-tertiary .right {
    .row {
        @media screen and (max-width: breakpoint(sm)) {
            position: relative;
            width: 100vw;
            left: calc(-50vw + 50%);
        }
    }
}