@import '../../../../css/global.variables.scss';

.advanced-search-button {
    background: #EBEBE8;
    padding: 2rem;
    margin-bottom: 2rem;

    @media screen and (max-width: breakpoint(sm)) {
        width: 100vw;
        position: relative;
        left: calc(-50vw + 50%);
        margin-bottom: 2rem;
    }

    h2 {
        a {
            color: #0F2748;
        }
    }


}