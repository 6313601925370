@import "../../../css/global.variables.scss";

.software-demo-form-container {


    @media screen and (max-width: breakpoint(sm)) {
        width: 100vw;
        position: relative;
        left: calc(-50vw + 50%);
        margin-bottom: 2rem;
    }

    .back-wrapper {
        margin-top: 2rem;
        margin-bottom: 2rem;

        a {
            color: #379dcc;

            &:hover {
                color: #237DA7;
            }
        }
    }

    .demo-form {
        background: #E9F7FF;
        padding: 2rem;
        // display: grid;

        .help {
            color: red;
        }

        label {
            margin-bottom: 0;
        }

        .left {
            padding-right: 3rem;

            @media screen and (max-width: breakpoint(sm)) {
                padding-right: 0;
            }
        }

        .title {
            color: #313830;
            font-weight: 500;
            text-transform: capitalize;
            margin-bottom: 1rem;
            font-size: px-to-rem(26px);
        }

        .form-title {
            color: #313830;
            font-weight: 500;
            margin-top: 1rem;
            margin-bottom: 1rem;
            font-size: px-to-rem(20px);
        }



        input {
            display: block;
            border: 1px solid #707070;

            margin-bottom: .75rem;
            padding: .5rem;

            &::placeholder {
                font-weight: 500;
            }
        }

        select {
            display: block;
            width: 100%;
            padding: .5rem .5rem .5rem .75rem;
            font-size: 16px;
            background: #fff;
            border: 1px solid #707070;
        }


        textarea {
            display: block;
            margin-top: 1rem;
            margin-bottom: 2rem;
            width: 100%;
            padding: .75rem;
            height: 10rem;
        }

        .recaptcha,
        .link {
            text-align: -webkit-center;

            @media screen and (max-width: breakpoint(sm)) {
                text-align: -webkit-left;
            }
        }

        .recaptcha {
            margin-top: 2rem;
            margin-bottom: 2rem;
            width: 100%;
        }

        .link {
            margin-top: 1rem;
        }
    }
}

.back-link {
    cursor: pointer;
}
.software-demo-form-container-changed{
    .demo-form {
        label{
            margin-bottom: 0 !important;
        }
        input, select{
            margin-bottom: 0.5rem;
        }
        textarea{
            margin-top: 0;
            margin-bottom: 0.5rem !important;
        }
    }
    @media screen and (max-width: breakpoint(sm)) {
        .back-wrapper{
            padding-left: 1.1875rem;
            padding-right: 1.1875rem;
        }

    }
}