// ********************************************************
// ********************************************************
// **
// **  Hero styles
// **
// ********************************************************
// ********************************************************



@import '../../../css/global.variables.scss';

.hero-container {

    .hero {
        background: lightgray;
        position: relative;
        min-height: 170px;

        margin-bottom: 0rem;
        box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.25);

        @media (min-width: breakpoint(sm)) {
            // margin-bottom: 2rem;
        }

        .down-arrow button {
            display: none;
            position: absolute;
            bottom: 1.5%;
            left: 50%;
            width: 28px;
            height: 28px;
            margin-left: -12px;
            border-left: 5px solid #fff;
            border-bottom: 5px solid #fff;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            box-sizing: border-box;
            background: transparent;

            @media screen and (min-width: breakpoint(md)) {
                display: block;
            }

        }

        h1.hero-title {
            font-weight: 300;
            font-size: px-to-rem(25px);

            @media screen and (min-width: breakpoint(sm)) {
                font-size: px-to-rem(32px);
            }

            // the rest of the font sizes are in the "template variations" section
        }

        .hero-description p {
            line-height: 24px;
            font-weight: 300;
            font-size:1.25rem;
            @media screen and (min-width: breakpoint(lg)) {
                font-size: px-to-rem(26px);
            }

            @media (max-width: breakpoint(lg)) {
                font-size: (px-to-rem(20px));
            }

            @media (min-width: breakpoint(md)) {
                line-height: 35px;
            }

            @media (max-width: breakpoint(md)) {
                font-size: px-to-rem(16px);
            }




            // the rest of the font sizes are in the "template variations" section
        }

        .text-content {
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 30px;
            padding: 1rem 0;
            color: white;

            @media (min-width: breakpoint(sm)) {
                bottom: 35px;
                padding: 1.5rem 0;
            }

            @media (min-width: breakpoint(md)) {
                bottom: 40px;
            }

            @media (min-width: breakpoint(lg)) {
                bottom: 45px;
            }

            .default {
                background: rgba(49, 56, 48, 0.85)
            }
        }

        .text-content.default {
            background: rgba(49, 56, 48, 0.85)
        }

        .text-content-inner-container {
            display: flex;
        }

        .side-content {
            display: none;

            @media (min-width: breakpoint(lg)) {
                min-width: 560px;
                display: block;
            }
        }
        .side-content-specialityModel {
          display: block;
            @media (min-width: breakpoint(lg)) {
                min-width: 560px;
                display: block;
            }
        }

        .view-more-link {
            display: none;
            color: white;
            margin-top: 0.2rem;

            @media (min-width: breakpoint(md)) {
                display: block;
            }

            @media (min-width: breakpoint(lg)) {
                margin-top: 0.5rem;
                font-size: px-to-rem(18px);
            }
        }

        .hero-box-cta {
            display: none;

            @media (min-width: breakpoint(md)) {
                display: block;
                position: absolute;
                width: 300px;
                top: 0;
                right: 0;
            }
        }

    }

    .mobile-description {
        margin-bottom: 1rem;

        @media (min-width: breakpoint(sm)) {
            margin-bottom: 0;
            display: none;
        }
    }

    .mobile-title {
        margin-bottom: 1rem;
        font-size: px-to-rem(25px);
        font-weight: 400;
    }

    .hero-ctas-container {
        display: none;

        @media(min-width: breakpoint(md)) {
            display: block;
            position: absolute;
            top: 50px;
            left: 50%;
            width: 100%;
            transform: translateX(-50%);

            >div {
                width: 60%;
                min-width: 800px;
                max-width: 900px;
                margin-left: auto;

                @media (min-width: breakpoint(lg)) {
                    max-width: 950px;
                    width: 65%;
                    top: 85px;
                }
            }

        }

        .hero-ctas-title {
            font-size: px-to-rem(22px);
            font-weight: bold;
            margin-bottom: 1.25rem;

            @media (min-width: breakpoint(lg)) {
                font-size: px-to-rem(28px);
            }
        }

    }

    .mobile-call-icon {
        width: 57px;
        top: 25px;
        right: 0;
        box-sizing: content-box;
        position: absolute;
        z-index: 1000;
    }

    .search-container {
        max-width: 500px;
        display: none;

        @media (min-width: breakpoint(lg)) {
            display: flex;
            align-items: center;
        }

        i {
            margin-left: 10px;
            font-size: px-to-rem(24px);
        }
    }
    .transparent {
        opacity: 1;
    }
}


// ********************************************************
// ********************************************************
// **
// **  template variations
// **
// **  1. All hero component template specific styles will be placed here
// **
// ********************************************************
// ********************************************************



// ********************************************************
// ********************************************************
// **
// **  Landing Template
// **
// ********************************************************
// ********************************************************


.hero-container.landing {

    .hero {
        height: 240px;

        @media (min-width: breakpoint(sm)) {
            min-height: 424px;
            margin-bottom: 0;
        }

        @media (min-width: breakpoint(md)) {
            min-height: 628px; // creative was at 688px
        }

        @media (min-width: breakpoint(lg)) {
            min-height: 628px;
        }
    }

    .mobile-title {

        @media (min-width: breakpoint(sm)) {
            display: none;
        }
    }

    .mobile-description {

        @media (min-width: breakpoint(sm)) {
            display: none;
        }
    }

    .text-content {

        @media (min-width: breakpoint(sm)) {
            //min-height: 188px;
        }

        @media (min-width: breakpoint(md)) {
            //padding: 40px 0;
            //min-height: 237px;
        }

        @media (min-width: breakpoint(lg)) {
            //min-height: 271px;
        }

        .hero-description {
            display: none;

            @media (min-width: breakpoint(sm)) {
                display: block;
            }
        }
    }

    h1.hero-title {
        margin-bottom: 0;
        font-size: px-to-rem(30px);

        @media (min-width: breakpoint(sm)) {
            margin-bottom: .5rem;
            font-size: px-to-rem(32px);
        }

        @media screen and (min-width: breakpoint(md)) {
            font-size: px-to-rem(52px)
        }

        @media screen and (min-width: breakpoint(lg)) {
            font-size: px-to-rem(82px);
            margin-bottom: 1rem;
        }
    }

    .hero-description p {

        @media (min-width: breakpoint(lg)) {
            font-size: px-to-rem(26px);
            line-height: 40px;
        }
    }
}

.hero-container.landing.alternate-layout {
    .hero-ctas-container {

        >div {
            margin-left: 0;
            margin-right: auto;
        }
    }
}

.hero-container.landing.search {
    .text-content {



        .hero-description {

            @media (min-width: breakpoint(lg)) {
                margin: 1.5rem 0 1.5rem 0;
            }

            p {
                @media (min-width: breakpoint(lg)) {
                    font-size: px-to-rem(22px);
                }
            }

        }
    }
}


// ********************************************************
// ********************************************************
// **
// **  Featured Template
// **
// ********************************************************
// ********************************************************


.hero-container.featured {

    .hero {
        height: 240px;

        @media (min-width: breakpoint(sm)) {
            min-height: 300px;
            margin-bottom: 0;
        }

        @media (min-width: breakpoint(md)) {
            min-height: 370px; // creative was at 688px
            margin-bottom: 0;
        }

        @media (min-width: breakpoint(lg)) {
            min-height: 410px;
        }
    }

    .mobile-title {

        @media (min-width: breakpoint(sm)) {
            display: none;
        }
    }

    .mobile-description {

        @media (min-width: breakpoint(sm)) {
            display: none;
        }
    }

    .text-content {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        padding: 1rem 0;
        color: white;

        @media (min-width: breakpoint(sm)) {
            bottom: 0;
            //min-height: 188px;
        }

        @media (min-width: breakpoint(md)) {
            //padding: 40px 0;
            //min-height: 237px;
        }

        @media (min-width: breakpoint(lg)) {
            //min-height: 271px;
        }

        .hero-description {
            display: none;

            @media (min-width: breakpoint(sm)) {
                display: block;
            }
        }
    }

    h1.hero-title {
        //margin-bottom: 0;
        font-size: px-to-rem(30px);

        @media (min-width: breakpoint(sm)) {
            //margin-bottom: .5rem;
            font-size: px-to-rem(32px);
        }

        @media screen and (min-width: breakpoint(md)) {
            font-size: px-to-rem(52px)
        }

        @media screen and (min-width: breakpoint(lg)) {
            font-size: px-to-rem(82px);
            //margin-bottom: 1rem;
        }
    }

    .hero-description p {

        @media (min-width: breakpoint(lg)) {
            font-size: px-to-rem(26px);
            line-height: 40px;
        }
    }
}

.hero-container.featured.alternate-layout {
    .hero-ctas-container {

        >div {
            margin-left: 0;
            margin-right: auto;
        }
    }
}

// ********************************************************
// ********************************************************
// **
// **  Standard Template
// **
// ********************************************************
// ********************************************************


.hero-container.standard {

    .hero {
        height: 150px;

        @media (min-width: breakpoint(sm)) {
            min-height: 215px;
        }

        @media (min-width: breakpoint(md)) {
            min-height: 205px;
        }

        @media (min-width: breakpoint(lg)) {
            min-height: 284px;
        }
    }

    h1.hero-title {
        font-size: 1.25rem; //added font-size for L4

        @media (min-width: breakpoint(sm)) {
            margin: 0;
        }

        @media screen and (min-width: breakpoint(md)) {
            font-size: px-to-rem(40px) 
        }

        @media screen and (min-width: breakpoint(lg)) {
            font-size: px-to-rem(48px)
        }
    }

    .text-content {
        bottom: 0;

        @media (max-width: breakpoint(sm)) {
            display: none;
        }

        @media (min-width: breakpoint(sm)) {
            padding: 1rem 0;
        }

        @media (min-width: breakpoint(md)) {
            padding: 1rem 0;
        }

        @media (min-width: breakpoint(lg)) {
            padding: 2rem 0;
        }
    }

    .mobile-title {

        @media (min-width: breakpoint(sm)) {
            display: none;
        }
    }
}

.hero-container.standard-breadcrumb {

    .hero {
        height: 150px;
        margin-bottom: 0;

        @media (min-width: breakpoint(sm)) {
            min-height: 215px;
        }

        @media (min-width: breakpoint(md)) {
            min-height: 205px;
        }

        @media (min-width: breakpoint(lg)) {
            min-height: 284px;
        }
    }

    h1.hero-title {

        @media (min-width: breakpoint(sm)) {
            margin: 0;
        }

        @media screen and (min-width: breakpoint(md)) {
            font-size: px-to-rem(52px)
        }

        @media screen and (min-width: breakpoint(lg)) {
            font-size: px-to-rem(48px)
        }
    }

    .text-content {
        bottom: 0;

        @media (max-width: breakpoint(sm)) {
            display: none;
        }

        @media (min-width: breakpoint(sm)) {
            padding: 1rem 0;
        }

        @media (min-width: breakpoint(md)) {
            padding: 1rem 0;
        }

        @media (min-width: breakpoint(lg)) {
            padding: 2rem 0;
        }
    }

    .mobile-title {

        @media (min-width: breakpoint(sm)) {
            display: none;
        }
    }
}


// ********************************************************
// ********************************************************
// **
// **  Page specific styles
// **
// ********************************************************
// ********************************************************


// TODO - create a text color scheme in globals
// => remove this (quick fix for qa)
.consulting.environmental-health-safety .hero-container {
    .mobile-title {
        color: #88AA3C;
    }
}

.consulting.science .hero-container {
    .mobile-title {
        color: #0A7DA7;
    }
}

.consulting.engineering .hero-container {
    .mobile-title {
        color: #EF6008;
    }
}

.staffing-lp .hero-container {

    // may make this color change depending on section
    // instead of hardcoding value
    .hero-ctas-title {
        color: #0D51CB;
    }

    .hero-ctas-container {
        top: 20px;
    }

    .mobile-description {
        display: none;
    }

    .hero-ctas-container>div {

        @media (min-width: breakpoint(md)) {
            width: 50%;
            min-width: 700px;
        }

        @media (min-width: breakpoint(lg)) {
            width: 65%;
            min-width: 800px;
        }
    }
}


// Industries

.industrialSecondary-a {

    .hero-container.featured {


        .content {
            h1 {
                @media screen and (min-width: breakpoint(lg)) {
                    margin-bottom: 1rem;
                }
            }
        }
    }


}

// TEMP ONLY: putting these styles here until a <p> tag is added on the admin side for the hero description

.consulting-landing .hero-description,
.training .hero-description {
    line-height: 24px;
    font-weight: 300;

    @media screen and (min-width: breakpoint(lg)) {
        font-size: px-to-rem(26px);
    }

    @media (max-width: breakpoint(lg)) {
        font-size: (px-to-rem(20px));
    }

    @media (min-width: breakpoint(md)) {
        line-height: 35px;
    }

    @media (max-width: breakpoint(md)) {
        font-size: px-to-rem(16px);
    }
}

.locations-container .hero-container {
    .mobile-title {
        color: #28AFF7;
    }
}

.software-demo-container,
.software-request-a-trial-container,
.custom-training-container,
.education-program-container,
.regulatory-agency-program-container,
.data-request-page-container,
.software-request-page-container,
.software-individual-product-tour,
.locations-secondary-container,
.training-location-container,
.global-partners-container,
.release-notes-page {
    .mobile-description {
        @media screen and (min-width: breakpoint(sm)) {
            display: none;
        }
    }
}

.breadcrumb-outer-container {
    display: block;
    @media screen and (max-width: breakpoint(sm)) {
        display: none;
    }
}


.breadcrumb-outer-container-standard {
    display: block;
    margin-bottom: 2rem;
    @media screen and (max-width: breakpoint(sm)) {
        display: none;
    }
}

// homepage
.homepage-container {
    .hero-container .hero .text-content.default {
        background: transparent;
    }

    .hero-container.standard .hero {
        height: 100%;
    }

    .hero-container {
        .custom-object-fit {
            background-size: cover;
            background-position: center center;
        }

        .hero {
            img {
                @media screen and (max-width: breakpoint(sm)) {
                    height: 100vw;
                    width: 100%;
                }
            }
        }
    }
}

.breadcrumb-top-line-outer {
    background-color: #F5F5F5;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    font-size: 16px;
}

.display-hero-table-image-root {
    display: none;

    @media screen and (max-width: breakpoint(md)) {
        display: block;
    }
}

.display-hero-desktop-image-root {
    @media screen and (max-width: breakpoint(md)) {
        display: none;
    }
}

//KT add style for hero widget 10-19-2021
.landing-changed{
    @media screen and (min-width: breakpoint(md)) {
        height: 518px !important;
    }
     .text-content .trinity-container .content{
    h1.hero-title {
        margin-bottom: 0;
        font-size: px-to-rem(30px);

        @media (min-width: breakpoint(sm)) {
            margin-bottom: .5rem;
            font-size: px-to-rem(32px);
        }

        @media screen and (min-width: breakpoint(md)) {
            font-size: px-to-rem(52px)
        }

        @media screen and (min-width: breakpoint(lg)) {
            font-size: px-to-rem(82px);
            margin-bottom: 1rem;
        }
    }
}
}

//KT add style to hide description from mobile 10-28-2021
.hero-description-changed-for-mobile{
    @media screen and (max-width: breakpoint(sm)) {
        display: none;
    }
}

//KT add margin below hero widget 11-11-2021
// .hero-changed{
//     margin-bottom: 2rem !important;
// }