@import "../../../css/global.variables.scss";

.images {
    text-align: center;
    height: 400px;
    width: 100%;
    background: #e5f5f3;

    @media screen and (max-width: breakpoint(sm)) {
        border-top: 1px solid #eeeeee;
        margin-top: 2rem;
    }




    h3 {
        font-weight: 500;
        // text-align: center;
        margin-bottom: 3rem;
        font-size: px-to-rem(50px);
        color: #303030;

        letter-spacing: .25rem;

        @media screen and (max-width: breakpoint(sm)) {
            font-size: px-to-rem(30px);
        }
    }

    .logos-container {
        margin: 0;
        padding-top: 100px;
        height: 100%;

        @media screen and (max-width: breakpoint(sm)) {
            padding-top: 30px;
        }
    }

    .logos {
        display: flex;
        justify-content: space-between;
        width: 50%;
        margin: 0 auto;

        img {
            width: 80%;

            // height: 100%;
            @media screen and (max-width: breakpoint(md)) {
                height: 40px;
                margin-bottom: 1rem;
                width: 100%;
                // display: block;
            }

            @media screen and (max-width: breakpoint(sm)) {
                margin-bottom: 1rem;
            }
        }

        @media screen and (max-width: breakpoint(sm)) {
            display: block;
            width: 100%;
        }
    }
}