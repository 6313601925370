@import "../../../css/global.variables.scss";

.testimonals-container {
  .title {
    font-weight: 500;
    font-size: px-to-rem(28px);
  }

  .testimonal {
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
    // margin-left: auto;
    // margin-right: auto;
    width: 800px;

    .content {
      font-weight: 500;
      margin-bottom: 2rem;

    }

    .name,
    .job-title,
    .company-name {
      font-weight: 300;
      margin: 0;
    }
  }

  .carousel {
    .slick-slider {
      @media screen and (min-width: breakpoint(lg)) {
        width: calc(100% - 900px);
      }
    }
  }

  .pagination {
    justify-content: center;

    @media screen and (max-width: breakpoint(sm)) {
      justify-content: flex-start;
    }
  }

  .slick-prev:before,
  .slick-next:before {
    margin-top: -340%;
  }
}