@import '../../../css/global.variables.scss';

.product-list-sidebar {

  @media (min-width: breakpoint(md)) {
    border: 1px solid #707070;
  }

  .product-list-item {
    @media (max-width: breakpoint(sm)) {
      margin: 0 1.1875rem;
    }
  }
}