@import '../../../../css/global.variables.scss';

.icon-list-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    @media (min-width: breakpoint(sm)) {
        align-items: flex-start;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    @media (min-width: breakpoint(sm)) {
        width: 25%;
        padding-right: 5%;
        margin: 0;
    }

    .custom-button.alt-colors {
        font-size: px-to-rem(15px);
    }

    p, title {
        color: #313830;
        text-align: center;
        height: 6rem;

        @media (min-width: breakpoint(sm)) {
            text-align: left;
        }
    }

    .title {
        margin: 1rem 0 .5rem 0;
        min-height: 3rem;
        font-size: px-to-rem(22px);

        @media (min-width: breakpoint(sm)) {
            margin: 2rem 0 .5rem 0;
        }
    }

    p {
        max-width: 350px;
        margin: .5rem 0;

        @media (min-width: breakpoint(sm)) {
            max-width: 290px;
        }   

        @media (min-width: breakpoint(md)) {
            margin-bottom: 1rem;
        }
    }
}