@import "../../../../css/global.variables.scss";

.sidebar-container {
    overflow-y: scroll;
    height: 800px;
    padding: 0 2rem 0rem 3rem;

    &::-webkit-scrollbar {
        background: #E9F7FF;
        border-radius: 30px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #AAE0FF;
        border-radius: 30px;
    }

    @media screen and (min-width: breakpoint(md)) {
        width: 300px;
    }

    @media screen and (max-width: breakpoint(md)) {
        margin-top: 2rem;
        padding: 0;
    }

    @media screen and (max-width: breakpoint(sm)) {
        width: 100%;
    }




    .inner-wrapper {
        p {
            a {
                color: #0F2748;
            }

            color: #0F2748;
            margin-bottom: 0;
        }

        .each-location {
            margin-bottom: 2rem;
        }

        .state {
            font-weight: 500;
        }
    }
}