@import "../../../../css/global.variables.scss";

.indiv-container .social-links-container {
    margin-bottom: 2rem;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: breakpoint(lg)) {
        display: block;
    }

    @media screen and (max-width: breakpoint(sm)) {
        display: block;
    }

    a {
        margin-right: .50rem;
    }

    .share {
        color: #0A7DA7;
        font-weight: 500;
        margin-right: 1rem;
        margin-bottom: 0px;
        display: flex;
        align-items: flex-end;
    }

    .links {
        display: flex;
    }
}