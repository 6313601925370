@import '../../../../css/global.variables.scss';

.sidebar-contact-cta {
    border: 1px solid #707070;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: breakpoint(md)) {
        border: none;
    }

    .title {
        width: 100%;
        background: #3E95B4;
        color: white;
        font-size: px-to-rem(16px);
        text-align: center;
        padding: .75rem;
        font-weight: 400;
        margin: 0;
        @media (min-width: breakpoint(md)) {
            font-size: px-to-rem(20px);
            padding: 1rem;
        } 
    }

    .phone-number {

        @media (min-width: breakpoint(md)) {
            display: flex;
            flex-direction: column;
            text-align: center;
            font-size: px-to-rem(18px);

            .seperator {
                display: none;
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem 0;

        @media (min-width: breakpoint(sm)) {
            flex-direction: row;
            justify-content: space-between;
            padding: 1rem 1.5rem 1rem 1.5rem;
        }

        @media (min-width: breakpoint(md)) {
            flex-direction: column;
            padding: 1.5rem 0;
        }
    }

    p {
        color: #313830;
        margin: .5rem 0;
    }

    button.custom-button.alt-colors {
        @media (min-width: breakpoint(md)) {
            font-size: px-to-rem(15px);
        }
    }
}