@import "../../../../css/global.variables.scss";

.job-request-form-container {
    background: #E9F7FF;
    padding: 2rem;
    display: grid;



    @media screen and (max-width: breakpoint(sm)) {
        width: 100vw;
        position: relative;
        left: calc(-50vw + 50%);
        margin-bottom: 2rem;
    }

    .inner-form {
        display: flex;


        @media screen and (max-width: breakpoint(md)) {
            display: block;
        }

        .help {
            color: red;
        }

        .left {
            padding-right: 2rem;

            @media screen and (max-width: breakpoint(sm)) {
                padding-right: 0;
            }
        }


        .form-title {
            color: #313830;
            font-weight: 500;
            text-transform: uppercase;
            margin-bottom: 1rem;
            font-size: px-to-rem(26px);
        }


        input {
            display: block;
            border: 1px solid #707070;

            margin-bottom: .75rem;
            padding: .5rem;

            &::placeholder {
                font-weight: 500;
            }
        }

        select {
            display: block;
            width: 100%;
            padding: .5rem .5rem .5rem .75rem;
            font-size: 16px;
            background: #fff;
            border: 1px solid #707070;
        }


        textarea {
            display: block;
            margin-top: 1rem;
            margin-bottom: 2rem;
            width: 100%;
            padding: .75rem;
            height: 10rem;
        }


        .upload {
            input {
                border: 0;
                display: none;
            }


            .upload-link {
                cursor: pointer;

                .custom-button {
                    background: #0F2748;
                    border: 2px solid #0F2748;
                    color: #fff;

                    &:hover {
                        background: #fff;
                        color: #0F2748;
                    }
                }
            }
        }

        .recaptcha {
            margin-top: 2rem;
            margin-bottom: 2rem;
            width: 100%;
        }

        .link {
            margin-top: 1rem;
            text-align: center;
        }



    }


}

// Templates

.contact-form-container {
    background: #E9F7FF;
    display: grid;

    @media screen and (max-width: breakpoint(sm)) {
        width: 100vw;
        position: relative;
        left: calc(-50vw + 50%);
        margin-bottom: 2rem;
    }

    .inner-form {


        .inner-modal {
            display: flex;
        }


        .errorMessage {
            color: red;
        }

        .left {
            padding-right: 2rem;
            border-right: 2px solid black;

            @media screen and (max-width: breakpoint(sm)) {
                padding-right: 0;
            }
        }

        .right {
            padding-left: 2rem
        }


        .form-title {
            color: #313830;
            font-weight: 500;
            text-transform: uppercase;
            margin-bottom: 1rem;
            font-size: px-to-rem(26px);
        }


        input {
            display: block;
            border: 1px solid #707070;

            margin-bottom: .75rem;
            padding: .5rem;

            &::placeholder {
                font-weight: 500;
            }
        }

        select {
            display: block;
            width: 100%;
            padding: .5rem .5rem .5rem .75rem;
            font-size: 16px;
            background: #fff;
            border: 1px solid #707070;
        }


        textarea {
            display: block;
            margin-top: 1rem;
            margin-bottom: 2rem;
            width: 100%;
            padding: .75rem;
            height: 10rem;
        }


        .upload {
            input {
                border: 0;
                display: none;
            }


            .upload-link {
                cursor: pointer;

                .custom-button {
                    background: #0F2748;
                    border: 2px solid #0F2748;
                    color: #fff;

                    &:hover {
                        background: #fff;
                        color: #0F2748;
                    }
                }
            }
        }

        .recaptcha {
            margin-top: 2rem;
            margin-bottom: 2rem;
            width: 100%;
        }

        .link {
            margin-top: 1rem;
            text-align: center;
        }
    }

}