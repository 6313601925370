@import '../../css/global.variables.scss';

.training-list-table {

  .wrapper {

    .second-widget {
      margin-top: 5rem;
    }

    .widget-hr {
      border: 2px solid #707070;
    }

  }

  .top-container {
    margin-bottom: 3rem;

    @media screen and (max-width: breakpoint(sm)) {
      position: relative;
      width: 100vw;
      position: relative;
      left: calc(-50vw +
      50%);
      margin-top: 1rem !important;
    }

  }

  .first-desc {
    font-weight: bold;
    margin-bottom: 1.5rem;
    text-transform: capitalize;
  }

  .second-desc {
    text-transform: capitalize;
  }

  .top-container {
    margin-top: 4rem;
  }

}
