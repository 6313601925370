@import '../../../../css/global.variables.scss';

.category-filter-controls {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    margin: .5rem 0rem;

    @media (min-width: breakpoint(sm)) {
        margin: 1.25rem .3rem 1.25rem 0;
    }

    @media (min-width: breakpoint(md)) {
        margin: 35px 5px 35px 0px;
    }

    @media (min-width: breakpoint(lg)) {
        margin: 40px 5px 40px 0px;
    }

    .isActive {
        color: #EF6008;
    }

    .category-dropdown-toggle {
        border: 1px solid #707070;
        color: #707070;
        background: none;
        padding-left: 20px;
        border-radius: 15px;
        width: 100%;
        text-align: left;
        padding: .5rem 1rem;
        font-size: px-to-rem(17px);
        font-weight: 500;

        @media (min-width: breakpoint(sm)) {
            display: none;
        }
    }

    .filter-buttons {
        justify-content: flex-end;
    }

    #filter-select {
        border: 1px solid #707070;
        color: #707070;
        background: none;
        padding-left: 20px;
        border-radius: 15px;
        width: 100%;
        text-align: left;
        padding: 0.5rem 1rem;
        font-size: 1.0625rem;
        font-weight: 500;
        text-transform: capitalize;

        // this allows custom select styling for all browsers
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        @media (min-width: breakpoint(sm)) {
            display: none;
        }
    }

    .arrow {
        border-top: 8px solid #707070;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        position: absolute;
        top: 43.5%;
        right: 2.5rem;
    }
}

// .training_lp .category-filter-controls {
//     display: none;
// }

.minnow-lp .category-filter-controls {
    display: none;
}