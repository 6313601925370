@import "../../../../css/global.variables.scss";


.change-password-form-container {
    .edit-profile-form {
        background: #E9F7FF;
        padding: 2rem;
        margin-bottom: 3rem;
        min-width: 850px;

        @media screen and (max-width: breakpoint(sm)) {
            width: 100%;
            min-width: 0;
        }

        .section-name{
            margin-bottom: 1.5rem;
        }

        .left {
            padding-right: 2rem;

            @media screen and (max-width: breakpoint(sm)) {
                padding-right: 0;
            }
        }

        .label {
            margin-top: 2rem;
        }
        .help {
            color: red;
            display: block;
            width: 100%;
        }
    }

    .profile-button-container {
        text-align: right;

        @media screen and (max-width: breakpoint(sm)) {
            display: flex;
            flex-direction: column;
            margin: 0 auto;
            text-align: center;
        }

        button {
            @media screen and (max-width: breakpoint(sm)) {
                margin: 0 auto;
            }
        }

        .changes {
            margin-right: 2rem;

            @media screen and (max-width: breakpoint(sm)) {
                margin-bottom: 1rem;
                margin-right: auto;
            }
        }
    }
}