@import "../../../../css/global.variables.scss";

.location-outer-office-container {
    justify-content: space-between;

    @media screen and (max-width: breakpoint(Sm)) {
        display: block;
    }

    .office-details-container {
        margin-bottom: 2rem;
        max-width: 30%;

        @media screen and (min-width: breakpoint(md)) {
            max-width: 30% !important;
        }

        @media screen and (min-width: breakpoint(sm)) {
            max-width: 48%;
        }

        @media screen and (max-width: breakpoint(sm)) {
            max-width: 100%;

        }

        .office-card {
            width: 100%;
            border: .07em #ccc solid;
            box-shadow: 0 0 0.31em 0.13em rgba(107, 107, 107, .15);
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .office-box{
                padding: 0 1.25em;
            }
            .city {

                font-size: px-to-rem(30px);
                font-weight: 500;
                color: #303830;
                border-bottom: 5px solid #31A4E5;
                margin: .72em 0 0;

                @media screen and (max-width: breakpoint(md)) {
                    font-size: px-to-rem(20px);
                }


            }

            .service-label {
                font-weight: 500;
                margin-top: 1.5rem;
                text-align: center;
            }

            .state {
                margin-top: 1rem;
                font-weight: 500;
                font-size: 14px;
                text-transform: uppercase;
                border-bottom: 2px solid #31A4E5;
                width: fit-content;

                @media screen and (max-width: breakpoint(sm)) {
                    width: 50%;
                }
                // text-decoration: underline;
                // text-decoration-color: #31A4E5;
                // text-decoration-style: solid;

            }

            .office-card-footer{
                justify-content: center;
                background: #a8a8a8;
                color: #fff;
                padding: 5px 0;
                margin-top: 1rem;
            }

            .content-container {
                margin-top: 1rem;

                .address-container {
                    padding-right: 1rem;

                    .title {
                        font-weight: 500;
                        margin-bottom: 1rem;
                    }

                    .address1,
                    .address2 {
                        margin: 0;
                        font-weight: 300;
                    }

                    .address1 {
                        margin-bottom: 0.75rem;
                    }

                    .address2 {
                        margin-bottom: 2rem;
                    }
                }

                .office-contact-container {
                    display: flex;
                    flex-direction: column;
                    min-height: 230px;
                    //KT change min height for match card height same as location card
                    &.office-contact-container-new{
                        min-height: 150px;
                        @media screen and (max-width: breakpoint(md)) {
                            min-height: 172px;
                        }
                    }

                    @media screen and (max-width: breakpoint(sm)) {
                        display: block;
                        min-height: 0px;
                    }

                    .title {
                        font-weight: 500;
                        margin-bottom: 1rem;

                        @media screen and (max-width: breakpoint(sm)) {
                            margin-top: 1rem;
                        }
                    }

                    .phone,
                    .fax {
                        a {
                            color: #303830;
                        }
                    }

                    .phone {
                        margin-bottom: 0.75rem;
                    }

                    .fax {
                        margin-bottom: 2rem;
                    }
                }
            }
        }
    }

    .address-card {
        height: 185px;

        @media screen and (max-width: breakpoint(sm)) {
            height: auto;
        }
    }

    .phone-card {
        height: 145px;

        @media screen and (max-width: breakpoint(sm)) {
            height: auto;
        }
    }
}
//KT add style for global card link
.trinity-container-changed {
    .state-changed{
        &:hover{
            text-decoration: none;
        }
    }
    .content-container-changed{
        padding-bottom: 120px;
        margin-top: 0 !important;
        @media screen and (max-width: breakpoint(sm)) {            
            padding-bottom: 0px;
        }
    }
    .office-contact-container-changed{
        min-height: inherit !important;
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
        position: absolute;
        bottom: 0;
        @media screen and (max-width: breakpoint(sm)) {
            min-height: inherit !important;
            position: relative;
            padding-bottom: 25px;
        }
            margin-bottom: 15px;
    }
    .address-container-changed{
        min-height: inherit !important;
        display: flex;
        flex-direction: column;
        position: inherit;
        padding-bottom: 20px;
        @media screen and (max-width: breakpoint(sm)) {
            min-height: inherit !important;
            margin-bottom: 0px;
            position: relative;
        }
        margin-bottom: 15px;
    }
    .wrap-changed{
        width: 100%;
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .common-flex-changed{
        display: flex;
        align-items: center;
        i{
            margin-right: 5px;
        }
    }
    .visit-office-changed, .visit-office-changed{
        margin-top: auto;
        position: absolute;
        bottom: 15px;
        @media screen and (max-width: breakpoint(sm)) {
            bottom: 0px;
        }
    }
}
.office-details-container-changed{
    max-width: 25%;
    padding: 0px 15px;
}

//KT add style for card spacing 10-27-2021
.location-outer-office-container-global-changed {
    justify-content: flex-start;
    .office-details-container{
        @media screen and (min-width: breakpoint(sm)) and (max-width: breakpoint(md)) {
            max-width: 50%;
        }
    }
}