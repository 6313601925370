@import "../../../css/global.variables.scss";

.individual-location-container {
    .left {
        @media screen and (max-width: breakpoint(md)) {
            margin-right: 10px;
        }

        @media screen and (max-width: breakpoint(sm)) {
            margin-right: 0;
        }
    }
}