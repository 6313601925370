@import '../../../../css/global.variables.scss';

.data-table {

    @media (min-width: breakpoint(sm)) {
        margin-top: 1.5rem;
    }

    @media (min-width: breakpoint(md)) {
        margin-top: 2rem;
    }

    .table-titles {
        display: none;

        @media (min-width: breakpoint(sm)) {
            display: flex;
            padding: .5rem;
        }

        span.title {
            font-weight: 600;
        }

        .side-sections {
            display: flex;
        }
    }

    .table-item {
        border-bottom: 1px solid #707070;
        padding-bottom: 1rem;
        margin-bottom: 1rem;

        @media (min-width: breakpoint(sm)) {
            display: flex;
            margin: 0;
            padding: .5rem;
            border: none;
        }

        &:nth-of-type(2n - 1) {

            @media (min-width: breakpoint(sm)) {
                background: #E9F7FF;
            } 
        }
    }

    // maybe change these to generic classes instead of "section-1, section-2, etc.."?
    .section-1 {

        @media (min-width: breakpoint(sm)) {
            width: 50%;
        }

        @media (min-width: breakpoint(md)) {
            width: 75%;
        }
    }

    .section-2, .section-3, .section-4, .section-5 {
        
        @media (min-width: breakpoint(sm)) {
            width: 25%;
            display: none;
        }

        @media (min-width: breakpoint(lg)) {
            display: flex;
        }
    }

    .mobile-listing-title {
        font-weight: 500;
    }

    .data-listing {

        > span {
            display: block;
            margin-bottom: .25rem;
        }
    }

    span.filled-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: none;
        width: 0; 
        height: 0;  
        border-color: #5C615B;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;

        &.prev {       
            left: -15px;    
            border-right: 8px solid; 
        }

        &.next {
            right: -15px;
            border-left: 8px solid; 
        }
    }


    // All content filtering classes
    .hide-search {
        display: none;
    }

    .hide-select {
        
        @media (max-width: breakpoint(sm)) {
            display: none;
        }
    }

    .primary-active-section {

        @media (min-width: breakpoint(sm)) and (max-width: breakpoint(md)) {
            display: flex;

            span.prev {
                display: block;
            }

             + div {
                 display: flex;

                span.next {
                    display: block;
                }
             }
        }

        @media (min-width: breakpoint(md)) and (max-width: breakpoint(lg)) {
            display: flex;

            span.prev, span.next {
                display: block;
            }
        }
    }   
}