// ********************************************************
// ********************************************************
// **
// **  README
// **
// **  1. All SASS global functions should be placed here
// **
// ********************************************************
// ********************************************************



$base-font-size: 16px;

@function px-to-rem($target, $context: $base-font-size) { // example: px-to-rem(16px)
    @return ($target / $context) * 1rem;
}

@function strip-unit($number) {
    @if type-of($number)=='number'and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }

    @return $number;
}

@function breakpoint($key) {
    @if map-has-key($grid-breakpoints, $key) { // example: breakpoint(sm)
        @return map-get($grid-breakpoints, $key);
    }

   // @warn "Unknown `#{$key}` in $grid-breakpoints.";
    @return null;
}

@function color($template, $color) { // example: colors('consulting_ehs', primary)
    @if map-has-key($colors, $template) {
        @return map-deep-get($colors, $template, $color)
    }

  //  @warn "Unknown `#{$key}` in $grid-breakpoints.";
    @return null;
}

@function map-deep-get($map, $keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }
    @return $map;
}