@import "../../../../css/global.variables.scss";

.region-container {
    margin-bottom: 3rem;

    @media screen and (max-width: breakpoint(sm)) {
        margin-top: 3rem;
    }

    .content {
        margin-top: 1rem;

        @media (min-width: breeakpoint(sm)) {
            margin-top: 3rem;
        }
    }

    .title-filters {
        display: none;

        // border-bottom: 1px solid #ccc;


        @media (min-width: breakpoint(sm)) {
            display: flex;
            // margin-bottom: 2.5rem;
        }
    }

    .seperator {
        margin: 0;
        position: relative;
        left: 0;
        right: 0;
        margin-left: calc(50% - 50vw);
        width: 100vw;
        border-top: 1px solid #ccc;
    }

    .title-filters .filter {
        font-size: px-to-rem(22px);
        color: #313830;
        margin-right: 40px;
        cursor: pointer;

        &.active {
            border-bottom: 2px solid #31a4e5;
            margin-bottom: 0;
        }
    }

    .section-title {
        font-size: px-to-rem(22px);
        color: #313830;
    }



    .content-well {
        margin-top: 2.5rem;

        @media screen and (max-width: breakpoint(sm)) {
            display: flex;
            flex-direction: column;
        }

        .more-than-one {
            &::after {
                content: " | ";
                color: #ccc;
                display: inline-block;
                font-size: 100%;
                margin-left: 1rem;
                margin-right: 1rem;

                @media screen and (max-width: breakpoint(sm)) {
                    display: none !important;
                }
            }


        }
    }
}