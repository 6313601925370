@import "../../../../../css/global.variables.scss";


.career-listing-wrapper {
    a {
        color: #313830;
        text-decoration: underline;
    }

    .widget-wrapper {
        display: flex;
        text-align: left;

        @media screen and (max-width: breakpoint(sm)) {
            display: block;
        }

        .department_title {
            margin-bottom: .5rem;
        }


        a {

            font-weight: 500;

            .job_title {

                @media screen and (breakpoint(md)) {
                    font-size: px-to-rem(20px);
                }
            }
        }

        .location {
            margin-top: .5rem;
            margin-bottom: 0;
        }

        .location,
        .multiple-locations {
            font-size: px-to-rem(16px);
            font-style: italic;
        }

    }

    .career-listing-button {
        max-height: 55px;
    }
}

.search-by-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    align-items: center;
}
.search-by-section1 label {
    min-width: 100px;
}
.search-by-section1 p {
 
}
.search-by-section1 .custom-button {
    margin: -31px 0 !important;
}
.search-by-section1 .trinity-select-list .select-list {
    padding: 0.7rem 1rem !important;
}

@media only screen and (max-width: 1021px) and (min-width: 700px)  { 
    .search-by-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        align-items: flex-start;
    }
    .search-by-section1 .custom-button {
        margin: 6px 0 !important;
        min-width: 150px;
        margin-left: 250px !important;
    }
}
@media only screen and (max-width: 699px) and (min-width: 300px)  { 
    .search-by-section {
        display: flex;
        flex-direction: column;
        justify-content: center;        
        align-items: flex-start;
    }
    .search-by-section1 .custom-button {
        margin: 6px 0 !important;        
    }
}
@media only screen and (max-width: 1400px) and (min-width: 1024px)  {  
    .search-by-section .col-md-3 {
        flex: 0 0 25%;
        max-width: 23%;
    }
    .col-sec {
        width: 24%;
    }
}
@media only screen and (max-width:405px) and (min-width: 390px)  {
    .search-by-section1 .custom-button {
        min-width: 280px;
    }
}

@media only screen and (width:428px) and (height: 926px)  {
    .search-by-section1 .custom-button {
        min-width: 320px;
    }
}

@media only screen and (max-width: 389px) and (min-width: 375px)  {

    .search-by-section1 .custom-button {

        min-width: 263px;

    }

}

@media only screen and (max-width: 425px) and (min-width: 401px)  {

    .search-by-section1 .custom-button {

        min-width: 299px;

    }

}

@media only screen and (max-width: 440px) and (min-width: 426px)  {

    .search-by-section1 .custom-button {

        min-width: 318px;

    }

}