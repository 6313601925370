@import "../../../../css/global.variables.scss";

.faq-container {
    // min-height: 100%;
    text-align: center;

    .faq-accordion {
        width: 100%;


        .each-accordion {
            margin-bottom: .25rem;

        }

        .title {
            padding: 30px 30px;
            cursor: pointer;
            transform: translate3d(0, 0, 0);
            color: #313830;
            position: relative;
            font-size: 20px;
            background: #EBEBE8;
            margin-bottom: -1px;
            // border-bottom: 1px solid #0e4671;
            text-align: left;

            &::after {
                content: "+";
                font-size: 18px;
                color: #313830;
                transition: transform .5s ease-in-out;
                position: absolute;
                right: 30px;
                font-family: monospace;
            }

            &.is-expanded {
                transition: background .5s;
                background: #EF6008;
                color: #fff;

                &::after {
                    content: "-";
                    color: #fff;
                    transform: rotate(-360deg);
                }
            }
        }

        .content {
            overflow: hidden;
            max-height: 0;
            transition: max-height .5s;
            margin: 0;
            padding: 0 30px;
            // border: solid 1px #eeeeee;
            border-top: 0;
            // background: #e8f4fc;

            p {
                padding: 30px 0;
                margin: 0;
                opacity: 0;
                transition: .5s;
                text-align: left;
            }

            &.is-expanded {
                max-height: inherit;
                overflow: hidden;

                p {
                    opacity: 1;
                }
            }
        }
    }

}
