@import "../../css/global.variables.scss";

.homepage-container {

    @media screen and (max-width:768px) {
        hr {
            display: none;
        }
    }

    .hero-container {

        .hero {
            margin-bottom: 0;
        }



        .text-content {
            .content {
                padding: 2rem;

                .hero-title {
                    font-weight: 400;

                    @media screen and (min-width: breakpoint(xl)) {
                        font-size: px-to-rem(92px);
                    }

                    @media screen and (max-width: breakpoint(lg)) {
                        font-size: px-to-rem(56px);
                    }

                    @media screen and (max-width: breakpoint(md)) {
                        font-size: px-to-rem(36px);
                    }

                    @media screen and (max-width: 768px) {
                        display: none;
                    }

                    @media screen and (max-width: breakpoint(sm)) {
                        font-size: px-to-rem(22px);
                    }
                }
            }
        }
    }


    @media screen and (min-width: breakpoint(sm)) {
        .grid {
            flex-wrap: nowrap;
        }
    }


    hr {
        width: 100vw;
        position: relative;
        left: calc(-50vw + 50%);
        border-top: 2px solid #707070;

        @media screen and (max-width: breakpoint(sm)) {
            display: none;
        }
    }

    .content-well {
        padding: 2rem 2rem 2rem 0rem;

        @media screen and (max-width: breakpoint(sm)) {
            font-size: px-to-rem(15px);
        }
    }





    .widgetBox-wrapper {
        margin-top: 3rem;
        margin-bottom: 3rem;

        .widgetBox-container {
            @media screen and (min-width: breakpoint(sm)) {
                margin-right: .5rem;
            }

            margin-bottom: 2rem;
        }

        @media screen and (max-width: breakpoint(sm)) {
            position: relative;
            width: 100vw;
            left: calc(-50vw + 50%);
        }

        .widget-content {
            img {
                width: 70px;
                height: 90px;
            }

            .date {
                margin: 0;
            }
        }


    }

    .content-wrapper {
        display: flex;
        margin-top: 2rem;

        a {
            color: #313830;
        }

        h3 {
            font-weight: 300;

            @media screen and (min-width: breakpoint(xl)) {
                font-size: px-to-rem(52px);
            }

            @media screen and (max-width: breakpoint(xl)) {
                font-size: px-to-rem(40px);
            }
        }

        @media screen and (min-width: breakpoint(md)) {
            display: flex !important;
        }

        @media screen and (min-width: breakpoint(sm)) {
            display: block;
        }

        @media screen and (max-width: breakpoint(sm)) {
            display: block !important;
        }

        .left-content {
            img {
                @media screen and (max-width: breakpoint(sm)) {
                    width: 50%;
                }
            }

            @media screen and (min-width: breakpoint(xl)) {
                padding-right: 8rem !important;
            }

            @media screen and (min-width: breakpoint(lg)) {
                padding-right: 5rem;
            }

            @media screen and (min-width: breakpoint(md)) {
                border-right: 2px solid #ebebe8;
                padding-right: 2rem;
            }

            @media screen and (min-width: breakpoint(sm)) {
                text-align: center;
                margin-bottom: 3rem;

                h3 {
                    text-align: left;
                }
            }

            @media screen and (max-width: breakpoint(sm)) {
                text-align: center;
                margin-bottom: 3rem;

                h3 {
                    text-align: left;
                }
            }
        }

        .mobile-wrapper {
            display: flex;

            @media screen and (max-width: breakpoint(sm)) {
                display: block;
            }

            .middle-content {

                @media screen and (min-width: breakpoint(md)) {

                    padding-left: 2rem;
                }

                @media screen and (min-width: breakpoint(sm)) {
                    border-right: 2px solid #ebebe8;
                    margin-left: 0;
                    margin-right: 0;
                    padding-right: 2rem;
                }

                @media screen and (max-width: breakpoint(sm)) {
                    margin-bottom: 2rem;
                }

                .first {
                    font-weight: 500;

                    @media screen and (min-width: breakpoint(md)) {
                        font-size: px-to-rem(20px);
                    }
                }


            }

            .right-content {
                display: flex;
                flex-wrap: wrap;
                font-weight: 500;



                @media screen and (min-width: breakpoint(md)) {
                    font-size: px-to-rem(20px);
                }

                @media screen and (min-width: breakpoint(sm)) {
                    margin-left: 0;
                    padding-left: 2rem;
                }

                @media screen and (max-width: breakpoint(sm)) {
                    a {
                        padding-bottom: 2rem;
                    }
                }
            }
        }
    }
}