@import "../../../css/global.variables.scss";

.review-order-container {
    margin-bottom: 3rem;


    .text-content-container {
        margin-bottom: 1rem;

        .text-content {

            width: 100vw;
            position: relative;
            left: calc(-50vw + 50%);
            padding: 1.25rem 0;
            background-color: rgba(48, 136, 92, 0.73);

            h1 {
                font-size: px-to-rem(25px);
                line-height: 30px;
                color: #fff;
                font-weight: 300;

                @media (min-width: breakpoint(sm)) {
                    font-size: px-to-rem(28px);
                    line-height: 33px;
                }

                @media (min-width: breakpoint(md)) {
                    font-size: px-to-rem(32px);
                    line-height: 38px;
                }

            }
        }
    }

    .submission-button-container{
        @media (max-width: breakpoint(sm)) {
            text-align: center;
        }
    }

    .sidebar {
        position: relative;

        width: 800px;
        // margin-top: 3rem;
        margin-left: auto;
        margin-right: auto;

        .cart-sidebar {
            border: 2px solid #999D99;
            padding: 2rem
        }

        @media (min-width: breakpoint(md)) {
            padding-left: 4rem;
        }

        @media (min-width: breakpoint(lg)) {
            padding-left: 5rem;
        }
    }

    .place-order-bottom {
        margin-top: 3rem;
        text-align: center;

        @media screen and (max-width: breakpoint(sm)) {
            text-align: left;
        }
    }
}