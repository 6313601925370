@import "../../../css/global.variables.scss";


.ehs-listing-wrapper {

    .top-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;

        @media screen and (max-width: breakpoint(sm)) {
            display: block;
        }

        .title {
            @media screen and (min-width: breakpoint(lg)) {
                font-size: px-to-rem(22px);
            }

            text-transform: uppercase;
            font-weight: 500;
        }

        .trinity-select-list {
            width: 20%;

            @media screen and (max-width: breakpoint(sm)) {
                width: auto;
            }
        }
    }


}