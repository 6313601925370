@import "../../../../css/global.variables.scss";

.impersonation-container {
    // height: 30px;
    width: 100%;
    padding: .75rem 0;
    text-align: center;
    background: #E9F7FF;
    border-bottom: 1px solid #EBEBE8;

    .text {
        margin: 0 auto;

        p {
            margin-bottom: 0;
        }

        span {
            font-weight: 500;
        }
    }
}