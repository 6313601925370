@import '../../../css/global.variables.scss';

.breadcrumbs {

    .title-filters {
        display: none;
        border-bottom: 1px solid #313830;
        @media (min-width: breakpoint(md)) {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 2.5rem;
        }
    }

    .title-filters .filter {
        font-size: 0.93rem;
        font-weight: 500;
        color: #313830;
        margin-right: 40px;
        cursor: pointer;

        @media (min-width: breakpoint(md)) {
            margin-bottom: 10px;
        }

        @media (min-width: breakpoint(lg)) {
            font-size: px-to-rem(21px);
        }

        &.active {
            color: #EF6008;
        }
    }

    .section-title {
        font-size: px-to-rem(22px);
        color: #313830;
    }

    .mobile-seperator {
        display: block;
        width: 100%;
        background: #313830;
        margin: 1.5rem 0;
    }
}