@import "../../css/global.variables.scss";

.training-tertiary {

    .mobile-title {
        color: #24674A;
        // font-size: px-to-rem(25px);
        // margin-bottom: 5rem !important;
    }

    // styles on trinity container will throw other things off
    // => maybe add specific class?

    .trinity-container {
        // margin-top: 3rem;
        // margin-bottom: 3rem;
        // max-width: 100%;

        // @media screen and (max-width: breakpoint(sm)) {
        //     margin-top: 1rem;
        // }

        .row {
            @media screen and (max-width: breakpoint(sm)) {
                flex-direction: column-reverse;
            }
        }
    }

    .widget-wrapper {
        @media screen and (max-width: breakpoint(sm)) {
            width: 100vw;
            position: relative;
            left: calc(-50vw + 50%);
        }
    }
}