@import '../../../../css/global.variables.scss';

* {
  box-sizing: border-box;
  font-family: Fira Sans Condensed, fira sans, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial;
}

a {
  color: #28aff7;
  text-decoration: none;
  background-color: transparent;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

button:focus {
  outline: none !important;
}

header {
  position: relative;
  max-width: 100% !important;
}

.st0 {
  fill: #0032A0;
}

.st1 {
  fill: #009CBD;
}

.st2 {
  fill: #00AE9B;
}

.st3 {
  fill: #0072CE;
}

header .header-icons {
  position: relative;
  z-index: 1;
  background: white;
  height: 95px;
  box-shadow: 0 1px 2px rgba(201, 197, 197, 0.35);
  @include flexCenterXY;

  @media screen and (min-width: breakpoint(md)) {
    height: auto;
    box-shadow: none;
  }

  .logo {
    width: 100%;

    @media screen and (min-width: breakpoint(md)) {
      height: auto;
    }
  }


  /* IE 10+ */
  @media screen and (-ms-high-contrast: active),
  (-ms-high-contrast: none) {
    .logo {
      width: 71%;
    }
  }

  .logo-container {
    max-width: 130px;

    @media (min-width: breakpoint(sm)) {
      max-width: 160px;
      min-width: 400px;
    }

    @media (min-width: breakpoint(md)) {
      max-width: 250px;
      min-width: 400px;
    }

    @media (min-width: breakpoint(lg)) {
      max-width: 315px;
      min-width: 400px;
    }
  }

  .icon-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .fa-sign-out {
      font-size: 2rem;
    }
  }
}


.icons-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-login {
  display: none;
}

trinity-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  background: #fff;
}

trinity-navigation .trinity-navigation-icons-container .trinity-navigation-icons a {
  padding-left: 0;
}

trinity-navigation .utility-navigation {
  display: none;
  margin: 0;
}

trinity-navigation .utility-navigation li, .menu-name li {
  list-style-type: none;
}

trinity-navigation .trinity-navigation-icons-container.d-md-none {
  display: none;
}

.logo-container a.login {
  display: none;
}

trinity-navigation a, trinity-navigation p.login {
  display: block;
  color: #8f8d8d;
  padding: 12px 0 12px 1.5rem;
}

.account-container-in .sign-in .fa {
  color: #1b72ce;
}

trinity-navigation .utility-navigation p.login, trinity-navigation .utility-navigation p.logout, trinity-navigation .utility-navigation a {
  position: relative;
  font-size: px-to-rem(16px);
  @media screen and (min-width: breakpoint(lg)) {
    font-size: px-to-rem(18px);
  }
}

> li:last-of-type > a {
  &::before {
    display: none;
  }
}

trinity-navigation .tel {
  color: #ef6008;
}

trinity-navigation .cart {
  position: relative;
  bottom: 5px;
}

trinity-navigation .cart .trinity-navigation-cart {
  overflow: hidden;
  position: relative;
  width: 25px;
}

trinity-navigation .cart .trinity-navigation-cart-count {
  color: #f08804;
  position: relative;
  right: 15px;
  bottom: 20px;
}

trinity-navigation .trinity-navigation-icons-container {
  background: #fff;
  border-top: 1px solid #ccc;
  position: relative;
  z-index: 2;
}

trinity-navigation .trinity-navigation-icons-container .trinity-navigation-icons {
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
}

.visible {
  visibility: visible !important;
}

trinity-navigation .trinity-navigation-animation-container.visible {
  transform: translateY(-100%);
}

.account-container-in {
  text-decoration: none;
  font-size: 1.125rem;
}

.account-container-in .sign-in {
  margin: 0;
  color: #8f8d8d;
  cursor: pointer;
}

.sign-in i {
  padding-left: 5px;
  color: #0032A0;
}

.account-container-in .dropdown-account {
  position: absolute;
  right: -30px;
  top: 2.5em;
  z-index: 2147483647;
  padding: 1rem;
  background: #e5e9ef;
  min-width: 248px;
  min-height: 178px;
}

.account-dropdown-visible {
  display: block;
}

.account-dropdown-hidden {
  display: none;
}

.account-container-in .dropdown-account .outline-container {
  background: #fff;
}

.trinity-subscribe-navigation-button {
  position: relative;
  background: #ef6008;
  color: #fff;
  margin: 5px 0;
  padding: .2rem 1rem;
  transition: all .2s ease;
  font-weight: 500;
  font-size: 1rem;
  margin-right: 10px;
  white-space: nowrap;
}

.trinity-subscribe-navigation-button:before {
  content: '';
  position: absolute;
  right: -8%;
  height: 60%;
  top: 50%;
  transform: translateY(-50%);
  border-right: 1px solid #707070;
}

.trinity-menu-custom-button {
  position: relative;
  background: #ef6008;
  color: #fff;
  margin: 5px 0;
  padding: .5rem 1rem;
  transition: all .2s ease;
  font-weight: 500;
  font-size: 1.125rem;
  border: 2px solid #ef6008;
  white-space: nowrap;
}

.trinity-menu-custom-button:hover {
  background: #FFFFFF;
  color: #ef6008;
}

.outline-container button {
  width: 100% !important;
}

.outline-container a:before {
  border-right: 0 !important;
}

.trinity-menu-custom-button:not(.variable-width) {
  min-width: 190px;
}

trinity-navigation .trinity-navigation-animation-container {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  transition: transform .5s ease;
  padding-left: 0;
  background: transparent;
}

.navigation {
  background: #fff;
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transition: transform .5s ease;
  padding-left: 0;
  margin: 0;
}

//@media (min-width: 1250px) {
//  .navigation {
//    height: 48px;
//    //  margin: 0 0 40px;
//  }
//}

.navigation li {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  font-size: 20px;
  border: 1px solid #aca9a9;
  background: #fff;
}

.navigation .trinity-navigation-breadcrumb {
  background: #ebebe8;
  border-top: 1px solid #aca9a9;
  border-bottom: 1px solid #aca9a9;
}

.navigation .trinity-navigation-breadcrumb .link-wrapper {
  display: flex;
}

.navigation .trinity-navigation-breadcrumb .link-wrapper .mobile-link:hover, trinity-navigation .utility-navigation a:hover {
  color: #8f8d8d !important;
}

.navigation .trinity-navigation-content-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.trinity-navigation-link {
  display: block;
  //line-height: 1.5;
  cursor: pointer;
}

.utility-navigation {
  .trinity-navigation-link {
    padding-left: 1.5rem;

    @media screen and (min-width: breakpoint(md)) {
      padding: .5rem .8rem;
    }

    @media screen and (min-width: breakpoint(lg)) {
      padding: .5rem 1.5rem;
    }
  }
}

.trinity-navigation-content-wrapper:hover .trinity-navigation-link:hover, .menu-name li a:hover, .menu-subtitle a:hover {
  color: #58595B;
  //font-weight: 500;
  //text-decoration: underline;
}

.menu-dropdown-container {
  display: flex;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s;

  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  min-width: 400px;
  padding-top: 46px;
  top: 120px;


  @media screen and (min-width: 0\0) {
    //margin-left:-380px;
    //width: 100vw;
    //top: 33px;
    position: fixed;
    width: 100vw;
    top: auto + 10px;
    z-index: -10;
    // trinity-navigation .trinity-navigation-animation-container {
    // position: static;
    // }
  }
}

.menu-dropdown-left {
  position: relative;
  width: 30%;
  min-height: 400px;
  //background-image: url("../../assets/images/consulting-landingpage-hardhats.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  object-position: center;
  box-shadow: inset 0 7px 7px -7px #efefef;

  span {
    display: block;
    margin-bottom: 10px;
    word-wrap: break-word;
    width: 380px;
  }

  p {
    padding-top: 10px;
    display: block;
    font-size: 24px;
    width: 100%;
    font-weight: 400;
    color: white;
    line-height: 30px;
    word-wrap: break-word;
  }
}

.menu-dropdown-right {
  width: 70%;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: inset 0 7px 7px -7px #efefef;
}

.menu-dropdown-left-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: inset 0 7px 7px -7px #efefef;
  padding: 20px;
}

.menu-dropdown-corner-logo {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  img {
    position: absolute;
    bottom: 10px;
    right: 20px;
    width: 200px;
  }
}

div.navigation li.menu:hover + div.navigation div.menu-dropdown {
  display: block;
}

.menu-dropdown {
  display: flex;
  // display: -ms-grid;
  // -ms-grid-columns: 300px 40px 300px 40px 300px;
  // grid-template-columns: repeat(3, 300px);
  flex-wrap: wrap;
  gap: 10px 40px;
  width: 100%;
}

.menu-dropdown > div {
  //width: 282px;
  width: 31%;
  justify-content: space-between;
}

.menu-column {
  padding: 10px 0;
  min-height: 200px;
}

.menu-column-without-children {
  padding: 10px 0;
}

.menu-data {
  min-height: 300px;
}

.menu-subtitle {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
  color: #8f8d8d;
}

.menu-name {
  padding: 0;
}

.menu-name li {
  line-height: 26px;
  font-size: 16px !important;
  color: #8f8d8d;
  cursor: pointer;
}

.sub-menu-item:before {
  border-right: 0;
}

.navigation .drilldown {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  font-size: 28px;
  font-weight: 300;
  width: 60px;
  background: #ebebe8;
  color: #8f8d8d;
  border-left: 1px solid #aca9a9;
  cursor: pointer;
}

.navigation .drilldown-back {
  border-left: 0;
  border-right: 1px solid #aca9a9;
}

.navigation div.sub-menu-wrapper {
  position: absolute;
  bottom: 0;
}

.navigation .sub-menu-wrapper {
  display: none;
}

.navigation .trinity-navigation-active > .sub-menu-wrapper, .sub-menu > .trinity-navigation-active > .trinity-navigation-content-wrapper > .sub-menu-wrapper {
  left: 0;
  right: 0;
  display: block;
  height: 100%;
  width: 100%;
  padding-top: 1rem;
  background: #ffffff;
  border-left: 1px solid #8896af;
  opacity: 1;
  animation: slide-left .6s ease-in-out;
  transform: translateX(0);

  @media screen and (max-width: 480px) {
    overflow: scroll;
    padding-bottom: 10px;
  }

  @media screen and (max-width: 320px) {
    overflow: scroll;
    padding-bottom: 10px;
  }

  @media screen and (max-width: 768px) {
    overflow: scroll;
    padding-bottom: 10px;
  }

}

@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes slide-left {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

.navigation .trinity-navigation-inactive > .sub-menu-wrapper {
  animation: slide-right .6s ease-in-out;
}

@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes slide-right {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.arrow {
  margin-left: 5px;
  font-size: 14px;
}

.navigation .sub-menu-wrapper ul {
  padding: 0;
  overflow-y: auto;
}

.navigation .trinity-navigation-breadcrumb {
  background: #ebebe8;
  border-top: 1px solid #aca9a9;
  border-bottom: 1px solid #aca9a9;
}


.trinity-navigation-content-wrapper:hover .menu-dropdown-container {
  //display: flex !important;
  visibility: visible;
  opacity: 1;
  transition-delay: 0.5s;
}

@media (min-width: 360px) and (max-width: 1250px) {
  .logo {
    margin: 0;
  }

  .mobile-login {
    display: block;
  }

  @media screen and (max-width: 1024px) {
    header .trinity-header-overlay.visible {
      animation: static;
      opacity: 1;
    }

    header .trinity-header-overlay {
      position: fixed;
      background: rgba(0, 0, 0, .35);
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      transition: opacity .3s ease;
      animation: exitScreen .1s ease .3s 1 forwards;
    }
  }

  trinity-navigation .trinity-navigation-icons-container.d-md-none {
    display: block
  }

  //trinity-navigation, menu {
  //  position: fixed;
  //  bottom: 0;
  //  left: 0;
  //  width: 100%;
  //  z-index: 1;
  //  background: white;
  //
  //  @media screen and (min-width: breakpoint(md)) {
  //    position: static;
  //  }
  //
  //  .utility-navigation {
  //    display: none;
  //    margin: 0;
  //
  //    @media screen and (min-width: breakpoint(md)) {
  //      display: flex;
  //      justify-content: flex-end;
  //    }
  //
  //    li {
  //      list-style-type: none;
  //    }
  //
  //    a,
  //    p.login,
  //    p.logout {
  //      position: relative;
  //      font-size: px-to-rem(16px);
  //
  //      @media screen and (min-width: breakpoint(lg)) {
  //        font-size: px-to-rem(18px);
  //      }
  //    }
  //
  //    >li:last-of-type>a {
  //      &::before {
  //        display: none;
  //      }
  //    }
  //
  //  }
  //
  //
  //
  //  .tel {
  //    color: #EF6008;
  //  }
  //
  //
  //  .navigation-wrapper {
  //    position: relative;
  //
  //    @media (breakpoint(md)) {
  //      position: static;
  //    }
  //  }
  //
  //  .trinity-navigation-animation-container {
  //    background: transparent;
  //    position: absolute;
  //    width: 100%;
  //    top: 0;
  //    left: 0;
  //    transition: transform .5s ease;
  //    padding-left: 0;
  //
  //    &.visible {
  //      transform: translateY(-100%);
  //    }
  //
  //    @media screen and (min-width: breakpoint(md)) {
  //      position: static;
  //    }
  //
  //    .show-submenu-1 {
  //      li {
  //        .trinity-navigation-content-wrapper {
  //          a {
  //            padding: 0.5rem 1rem;
  //          }
  //        }
  //      }
  //    }
  //  }
  //
  //  a,
  //  p.login {
  //    display: block;
  //    color: rgb(143, 141, 141);
  //
  //    @media screen and (min-width: breakpoint(md)) {
  //      position: relative;
  //      padding: .5rem .8rem;
  //
  //      &:hover {
  //        color: #EF6008;
  //        text-decoration: none;
  //      }
  //
  //      &::before {
  //        content: '';
  //        position: absolute;
  //        right: 0;
  //        height: 40%;
  //        top: 50%;
  //        transform: translateY(-50%);
  //        border-right: 1px solid #707070;
  //      }
  //
  //    }
  //
  //    @media screen and (min-width: breakpoint(lg)) {
  //      padding: .5rem 1.5rem;
  //    }
  //  }
  //
  //  .login,
  //  p.login {
  //    color: #28AFF7;
  //    margin: 0;
  //    cursor: pointer;
  //
  //    &:before {
  //      display: none;
  //    }
  //  }
  //
  //  .trinity-navigation-icons-container {
  //    background: #ffffff;
  //    border-top: 1px solid #CCCCCC;
  //    position: relative;
  //    z-index: 2;
  //
  //    .trinity-navigation-icons {
  //      max-width: 500px;
  //      margin: 0 auto;
  //      display: flex;
  //      justify-content: space-between;
  //      align-items: center;
  //      height: 55px;
  //
  //      a {
  //        padding-left: 0;
  //      }
  //
  //      // .hamburger {
  //      //     display: block;
  //      //     padding: 12px 0;
  //      //     color: #8f8d8d;
  //      //     padding-left: 1.5rem;
  //
  //      // }
  //
  //    }
  //  }
  //
  //  .cart {
  //    // padding: .5rem 1.5rem 0rem 1.5rem;
  //    position: relative;
  //    bottom: 5px;
  //
  //    &:hover {
  //      text-decoration: none;
  //    }
  //
  //
  //    .trinity-navigation-cart {
  //      overflow: hidden;
  //      position: relative;
  //      width: 25px;
  //    }
  //
  //    .trinity-navigation-cart-count {
  //      color: #f08804;
  //      position: relative;
  //      right: 15px;
  //      bottom: 20px;
  //
  //
  //
  //    }
  //  }
  //
  //
  //}

  //.trinity-navigation-link {
  //padding: .5rem 1rem;
  //}

  .menu-dropdown-container {
    display: none;
  }

  .trinity-navigation-content-wrapper:hover .menu-dropdown-container {
    display: none !important;
  }

  .navigation div.sub-menu-wrapper {
    padding: 0;
  }
}

@media (max-width: 1350px) and (min-width: 1025px) {
  .navigation li {
    font-size: 16px;
  }
}

@media screen and (min-width: 1250px) {
  header {
    position: relative;
    z-index: 2;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2.5rem 0;

    @media screen and (min-width: 1250px) {
      box-shadow: 0 3px 6px rgba(87, 69, 69, 0.16);
    }

    .menu-dropdown-left {
      width: 30%;
    }

    .menu-dropdown-right {
      width: 70%;
    }
  }

  trinity-navigation {
    position: static;
  }

  .navigation .drilldown {
    display: none;
    background: transparent;
    border: none;
  }

  trinity-navigation .utility-navigation {
    display: flex;
    justify-content: flex-end;
    display: -ms-flexbox;
  }

  trinity-navigation a,
  trinity-navigation p.login {
    display: block;
    color: rgb(143, 141, 141);
    //padding: 12px 0 12px 1.5rem;

    @media screen and (min-width: breakpoint(md)) {
      position: relative;
      padding: .5rem .8rem;

      &:hover {
        color: #EF6008;
        text-decoration: none;
      }
      &::before {
        content: '';
        position: absolute;
        right: 0;
        height: 40%;
        top: 50%;
        transform: translateY(-50%);
        border-right: 1px solid #707070;
      }

    }

    //@media screen and (min-width: breakpoint(lg)) {
    //  padding: .5rem 1.5rem;
    //}
  }

  @media screen and (min-width: 0\0) {
    trinity-navigation a:before {
      content: "";
      position: absolute;
      right: 0;
      height: 40%;
      top: 40%;
      transform: translateY(-11%);
      border-right: 1px solid #707070;
    }
  }

  .utility-navigation a:before, trinity-navigation p.login:before {
    content: "";
    position: absolute;
    right: 0;
    height: 40%;
    top: 50%;
    transform: translateY(-50%);
    border-right: 1px solid #707070;
  }
  .navigation li:last-child a:before {
    border-right: none;
  }


  trinity-navigation a.login:before, trinity-navigation a.careers:before {
    border-right: 0;
  }

  //trinity-navigation a, trinity-navigation p.login {
  //  position: relative;
  //  padding: .5rem .8rem;
  //}

  trinity-navigation .trinity-navigation-animation-container {
    position: static;
  }

  .navigation {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    position: unset;
    padding: unset;
  }

  .navigation.show-submenu-3 {
    transform: unset;
  }

  .navigation.show-submenu-3 {
    transform: translate(-200%);
  }

  .navigation li {
    border: none;
    background: none;
  }
}

@media (min-width: 1650px) {
  .menu-dropdown-right {
    width: 70%;
  }

  .navigation li {
    font-size: 20px;
  }

  trinity-navigation a, trinity-navigation p.login {
    padding: .5rem 1.5rem;
  }
}

@media (max-width: 1400px) {
  .menu-dropdown-left {
    width: 20%;
  }

  .menu-dropdown-right {
    width: 80%;
  }
}

.navbar__link--active {
  color: #EF6008;
  transition: color .5s ease-in-out;
}
