@import '../../../css/global.variables.scss';

.software-download {
    
    .section-seperator {

        @media (min-width: breakpoint(md)) {
            margin: 2rem 0;
        }
    }
}