@import "../../../../../css/global.variables.scss";

.links-container {
    background: $widgetBox;
    margin-bottom: 2rem;
    padding: 2rem;

    @media screen and (max-width: breakpoint(sm)) {
        position: relative;
        width: 100vw;
        left: calc(-50vw + 50%);
    }

    .title {
        font-weight: 500;
        text-transform: uppercase;

        @media screen and (min-width: breakpoint(md)) {
            font-size: px-to-rem(26px);
        }

        @media screen and (max-width: breakpoint(md)) {
            font-size: px-to-rem(20px);
        }
    }

    .content-links {
        ul {
            padding-inline-start: 20px;

            li {
                cursor: pointer;

                @media screen and (min-width: breakpoint(md)) {
                    font-size: px-to-rem(20px);
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }


}