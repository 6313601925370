@import "../../../../css/global.variables.scss";

.request-data-form {
    background: #E9F7FF;
    padding: 2rem;
    display: grid;
    margin-bottom: 3rem;

    .help {
        color: red;
    }


    @media screen and (max-width: breakpoint(sm)) {
        width: 100vw;
        position: relative;
        left: calc(-50vw + 50%);
        margin-bottom: 2rem;
    }

    .title {
        font-size: px-to-rem(26px);
        font-weight: 500;
    }

    .left {
        padding-right: 2rem;

        @media screen and (max-width: breakpoint(sm)) {
            padding-right: 0;
        }
    }


    .date-year-container {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: breakpoint(sm)) {
            display: block;
        }
    }




    .form-title {
        color: #313830;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 1rem;
        font-size: px-to-rem(18px);
    }


    .input {
        display: block;
        margin-bottom: 1rem;
        width: 100%;
        height: 41px;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &::placeholder {
            font-weight: 500;
        }
    }

    select {
        display: block;
        width: 100%;
        padding: .5rem .5rem .5rem .75rem;
        font-size: 16px;
        background: #fff;
        border: 1px solid #707070;
        margin-bottom: 1rem;
    }


    textarea {
        display: block;
        margin-top: 1rem;
        margin-bottom: 2rem;
        width: 100%;
        padding: .75rem;
        height: 10rem;
    }

    .recaptcha {
        margin-top: 2rem;
        margin-bottom: 2rem;
        width: 100%;
        display: flex;
        justify-content: center;

        @media screen and (max-width: breakpoint(sm)) {
            justify-content: left;
        }
    }

    .link {
        margin-top: 1rem;
        width: 100%;
        text-align: center;

        @media screen and (max-width: breakpoint(sm)) {
            text-align: left;
        }

        .custom-button {
            @media screen and (max-width: breakpoint(sm)) {
                display: block;
            }
        }
    }
}

//KT add style for responsive 11-12-2021
.request-data-form-changed-responsive{
    select, .text-input{
        margin-bottom: 0.5rem !important;
    }
    margin-top: 2rem;
   textarea{
       margin-top: 0;
       margin-bottom: 0.5rem !important;
   }
    @media screen and (max-width: breakpoint(md)) {
        .right{
            textarea{
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }

    }
    @media screen and (max-width: breakpoint(sm)) {
        display: block;

    }
}
