@import "../../../css/global.variables.scss";

.software-individual-product-tour {
    margin-bottom: 2rem;

    .text-content-container {
        margin-bottom: 1rem;

        .text-content {

            width: 100vw;
            position: relative;
            left: calc(-50vw + 50%);
            padding: 1.25rem 0;
            background-color: rgba(48, 136, 92, 0.73);

            h1 {
                font-size: px-to-rem(25px);
                line-height: 30px;
                color: #fff;
                font-weight: 300;

                @media (min-width: breakpoint(sm)) {
                    font-size: px-to-rem(28px);
                    line-height: 33px;
                }

                @media (min-width: breakpoint(md)) {
                    font-size: px-to-rem(32px);
                    line-height: 38px;
                }

            }
        }
    }

    .back-wrapper {
        margin-top: 2rem;
        margin-bottom: 2rem;

        a {
            color: #379dcc;

            &:hover {
                color: #237DA7;
            }
        }
    }

    .image-carousel {
        h2 {
            display: none;
        }
    }
}