// ********************************************************
// ********************************************************
// **
// **  General component styles
// **
// **  1. global font styling is in the 'css/_font-styling.styles.scss'
// **  2. This uses the ".the-content" class for generic font styling from the WYSIWIG
// **
// ********************************************************
// ********************************************************

@import "../../../css/global.variables.scss";

.content-well {
  text-align: left;
  padding-bottom: 2rem;

  p {
    font-size: 1rem;
    line-height: 30px;

    @media screen and (max-width: breakpoint(sm)) {
      font-size: 1rem;
      line-height: 24px;
    }
  }

  ul {
    padding-inline-start: 15px;

    li {
      font-size: 1rem;
      line-height: 30px;

      @media screen and (max-width: breakpoint(sm)) {
        font-size: 1rem;
        line-height: 24px;
      }
    }
  }
}

// ********************************************************
// ********************************************************
// **
// **  Template / Page specific styles
// **
// ********************************************************
// ********************************************************

// TRAINING
.training-tertiary .content-well {
  @media screen and (min-width: breakpoint(lg)) {
    padding-right: 3rem;
  }

  @media screen and (max-width: breakpoint(lg)) {
    padding-right: 2rem;
  }
}

// INDUSTRIES
.industrialSecondary-a .content-well {
  font-size: px-to-rem(16px);
  // padding-right: 7rem;
  font-weight: 400;
  line-height: 2;
  margin-bottom: 4rem;

  @media screen and (max-width: breakpoint(md)) {
    .content-well {
      margin-top: 1rem;
      padding-right: 0;
      font-size: px-to-rem(16px);
    }
  }

  @media screen and (max-width: breakpoint(sm)) {
    padding: 0;
  }
}

// consulting
.consulting .content-well {
  @media screen and (max-width: breakpoint(lg)) {
    margin-bottom: 3rem;
  }
}

// consulting job request (TESTING: WILL REMOVE ONCE API IS CONNECTED TO THIS COMPONENT)
.job-request-container .content-well {
  .title {
    margin-bottom: 3rem;

    @media screen and(min-width: breakpoint(xl)) {
      font-size: px-to-rem(48px);
    }

    @media screen and(min-width: breakpoint(lg)) {
      font-size: px-to-rem(48px);
    }

    @media screen and (max-width: breakpoint(lg)) {
      font-size: px-to-rem(40px);
    }

    @media screen and (max-width: breakpoint(md)) {
      font-size: px-to-rem(28px);
    }

    @media screen and (max-width: breakpoint(sm)) {
      display: none;
    }
  }

  .sub-title {
    line-height: 100%;

    @media screen and(min-width: breakpoint(xl)) {
      font-size: px-to-rem(48px);
    }

    @media screen and(min-width: breakpoint(lg)) {
      font-size: px-to-rem(48px);
    }

    @media screen and (max-width: breakpoint(lg)) {
      font-size: px-to-rem(40px);
    }

    @media screen and (max-width: breakpoint(md)) {
      font-size: px-to-rem(28px);
    }
  }
}

// resources
// .indiv-container {
//     .content-well {
//         ul {
//             padding-inline-start: 15px;
//         }

//     }
// }

.indiv-container .content-well {
  @media screen and (min-width: breakpoint(sm)) {
    padding-right: 2rem;
  }
}