// ********************************************************
// ********************************************************
// **
// **  general styles
// **
// ********************************************************
// ********************************************************



@import '../../../../css/global.variables.scss';

.speciality-hero-cta {

    .title {
        font-size: px-to-rem(20px);
        margin-bottom: 1rem;
        font-weight: 500;
        
        @media (min-width: breakpoint(md)) {
            font-size: px-to-rem(26px);
        }
    }
}


// ********************************************************
// ********************************************************
// **
// **  Page specific styles
// **
// ********************************************************
// ********************************************************


.software .speciality-hero-cta {
    padding: 2.5rem 2rem;
    //background: #E9F7FF;
    color: #313830;

    @media (min-width: breakpoint(sm)) {
        margin-left: 1rem;
    }


    .custom-button {
        position: relative;
        background: rgba(239, 96, 8, 1);
        color: white;
        margin: 5px 0;
        padding: .5rem 1rem;
        transition: all .2s ease;
        font-weight: 500;
        font-size: px-to-rem(18px);
        max-width: 100%;

        .text {
            transition: opacity .15s ease;
        }

        &:not(.variable-width) {
            width: 250px;
        }

        &:hover:not(.loading) {
            background: white;
            color: rgba(239, 96, 8, 1);
        }

        .arrow {
            margin-left: .25rem;
        }
    }

}

.software-secondary .speciality-hero-cta {

    @media (min-width: breakpoint(sm)) {
        position: sticky;
        top: 50px;
    }

    @media (min-width: breakpoint(lg)) {
        width: 560px;
        position: static;
    }
}