@import '../../../css/global.variables.scss';

.product-thumb-carousel {
    display: flex;
    flex-direction: column;
    
    @media (min-width: breakpoint(lg)) {
        flex-direction: row-reverse;
    }

    img {
        width: 100%;
        height: 100%;
        // object-fit: cover; RP - SD116 : Software Product Images
    }

    .main-image {
        width: 100%;
        height:auto; //RK on-behalf of Satish Task -- SD116 on 03/15/2022
        // height: 300px; RP (Sathish) - SD116 : Software Product Images Changes
        @media (min-width: breakpoint(lg)) {
            width: 80%;
            max-width: 400px;
        }
    }

    .thumbnail-images {
        display: flex;
        flex-wrap: wrap;
        padding-top: 1rem;

        @media (min-width: breakpoint(lg)) {
            width: 20%;
            flex-direction: column;
            padding-top: 0;
            padding-right: 3%;
        }

        .thumb {
            width: 25%;
            height: 61px;
            padding-right: 8px;
            cursor: pointer;

            &:last-of-type {
                padding-right: 0;
            }

            @media (min-width: breakpoint(lg)) {
                padding-right: 0;
                width: 100%;
                padding-bottom: 5px;
                height: 71px;
            }
        }
    }
}