@import '../../../../css/global.variables.scss';

.single-widget-container {
    background: #88AA3C;
    color: #fff;
    // max-width: 25rem;
    display: block;
    margin-bottom: 2rem;
    height: 8rem;
}


.single-content {
    padding: 1rem 2rem 0rem 2rem;
    display: flex;
}

.single-content img {
    width: 3rem;
    margin-right: 2.25rem;
    margin-top: .75rem;
}



.single-text .single-content-text {
    @media screen and (min-width: breakpoint(md)) {
        font-size: px-to-rem(20px);
    }

    p {
        margin-bottom: 0px;
    }

    a {
        color: #fff;

        text-decoration: underline;
    }
}


.single-text .single-sub-content {
    font-family: "Fira Sans Condensed";
    text-decoration: underline;
    font-weight: 400;
    font-size: 0.875rem;
    padding-top: .50rem;
}

.single-sub-content a {
    color: color('text', accent);
    font-size: px-to-rem(14px);
}

.single-widget-container {

    p {
        color: color('text', accent);
    }
}


// TECHNOLOGY
.technology-services-lp .widget-wrapper {
    margin-left: auto;

    .single-widget-container {
        background: rgba(10, 45, 71, 1);
        margin-top: 3rem;
        margin-bottom: 3rem;
        height: 300px;

        .single-content img {
            width: auto;
        }

        @media screen and (min-width: breakpoint(md)) {

            // height: 240px !important;

            .single-content img {
                width: 4rem;

            }
        }

        @media screen and (min-width: breakpoint(sm)) {
            // height: 215px;

            .single-content img {
                width: 6rem;
                height: 90%;
                margin-top: 0;
            }
        }

        @media screen and (max-width: breakpoint(sm)) {
            position: relative;
            width: 100vw;
            left: calc(-50vw + 50%);

            // height: 200px;

            .single-content img {
                width: 5rem;
            }
        }

        .single-content {
            padding: 1rem 2rem 1rem 2rem;

            @media screen and (max-width: breakpoint(sm)) {
                .single-content {
                    padding: 1rem 2rem 0 2rem;
                }
            }
        }

        .single-text {
            .single-content-text {
                a {
                    text-transform: uppercase;
                    font-size: px-to-rem(26px);
                    text-decoration: none;
                    font-weight: 500;
                }

                p {
                    font-size: px-to-rem(22px);
                    font-weight: 300;
                    margin-top: 1rem;
                }
            }

            .single-sub-content {
                text-decoration: none;
                padding-top: 1rem;

                a {
                    font-size: px-to-rem(22px);
                    font-weight: 500;

                }
            }

            @media screen and (max-width: breakpoint(lg)) {
                .single-content-text {
                    a {
                        text-transform: uppercase;
                        font-size: px-to-rem(26px);
                        text-decoration: none;
                        font-weight: 500;
                    }

                    p {
                        font-size: px-to-rem(22px);
                        font-weight: 300;
                    }
                }

                .single-sub-content {
                    text-decoration: none;

                    a {
                        font-size: px-to-rem(22px);
                        font-weight: 500;

                    }
                }
            }

            @media screen and (max-width: breakpoint(md)) {
                .single-content-text {
                    a {
                        text-transform: uppercase;
                        font-size: px-to-rem(20px);
                        text-decoration: none;
                        font-weight: 500;
                    }

                    p {
                        font-size: px-to-rem(14px);
                        font-weight: 300;
                    }
                }

                .single-sub-content {
                    text-decoration: none;

                    a {
                        font-size: px-to-rem(14px);
                        font-weight: 500;

                    }
                }
            }

            @media screen and (max-width: breakpoint(sm)) {


                .single-content-text {
                    a {
                        text-transform: uppercase;
                        font-size: px-to-rem(20px);
                        text-decoration: none;
                        font-weight: 500;
                    }

                    p {
                        font-size: px-to-rem(14px);
                        font-weight: 300;
                        margin: 0;
                    }
                }

                .single-sub-content {
                    text-decoration: none;
                    padding-top: .50rem;

                    a {
                        font-size: px-to-rem(14px);
                        font-weight: 500;

                    }
                }
            }

        }
    }

}

.training-tertiary .single-widget-container {
    background: #24674a;
    color: #fff;
    // max-width: 30rem;
    display: block;
    margin-bottom: 1rem;
    height: 9rem;
    margin-left: 0px !important;

    @media screen and (min-width: breakpoint(lg)) {
        margin-left: 5rem;
        height: 7rem;

    }

    @media screen and (max-width: breakpoint(lg)) {
        height: 8rem;
    }

    @media screen and (max-width: breakpoint(md)) {
        height: 10rem;
        max-width: 100%;
    }

    @media screen and (max-width: breakpoint(sm)) {
        height: 8rem;
    }

    .single-content {
        padding: 1rem 1.5rem 0rem 1.5rem;
        display: flex;

        img {
            color: #fff;
            width: 4rem;
            margin-right: 2.25rem;
            margin-top: 0.50rem;
        }
    }

    .single-text {

        @media screen and (min-width: breakpoint(lg)) {
            padding-left: 2rem;
        }

        .single-content-text p {
            font-weight: 400;
            margin-bottom: 0px;

            @media screen and (min-width: breakpoint(lg)) {
                font-size: px-to-rem(16px);
            }

            @media screen and (max-width: breakpoint(lg)) {
                font-size: px-to-rem(16px);
            }
        }

        .single-content-text a {
            font-weight: 400;
            color: #fff;
            text-decoration: underline;

            @media screen and (min-width: breakpoint(lg)) {
                font-size: px-to-rem(20px);
            }

            @media screen and (max-width: breakpoint(lg)) {
                font-size: px-to-rem(16px);
            }
        }

        .single-sub-content {
            text-decoration: underline;
            font-weight: 300;
            padding-top: 0.5rem;
        }

        .single-sub-content a {
            color: #fff;

            @media screen and (min-width: breakpoint(lg)) {
                font-size: px-to-rem(14px);
            }
        }
    }
}