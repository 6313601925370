@import '../../../../css/global.variables.scss';

.filtered-content-well {
    padding: 2.5rem 0;




    .content {
        margin-top: 1rem;

        @media (min-width: breeakpoint(sm)) {
            margin-top: 3rem;
        }
    }

    .title-filters {
        display: none;
        border-bottom: 1px solid #313830;

        @media (min-width: breakpoint(md)) {
            display: flex;
            margin-bottom: 2.5rem;
        }
    }

    .title-filters .filter {
        font-size: px-to-rem(22px);
        color: #313830;
        margin-right: 40px;
        cursor: pointer;

        &.active {
            border-bottom: 7px solid #EF6008;
            margin-bottom: 0;
        }
    }

    .section-title {
        font-size: px-to-rem(22px);
        color: #313830;
    }

    .mobile-seperator {
        display: block;
        width: 100%;
        background: #313830;
        margin: 1.5rem 0;
    }
}

.software .filtered-content-well h2 {
    font-size: px-to-rem(22px);
    color: #313830;
    text-align: center;
    margin-bottom: 1rem;

    @media (min-width: breakpoint(sm)) {
        text-align: left;
    }

    @media (min-width: breakpoint(md)) {
        margin-bottom: 3rem;
    }
}

.software-product-detail .filtered-content-well {

    @media (max-width: breakpoint(md)) {
        padding: 0;
    }
}