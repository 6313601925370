// ********************************************************
// ********************************************************
// **
// **  Content With Sidebar styles
// **
// ********************************************************
// ********************************************************



@import '../../css/global.variables.scss';

.content-with-sidebar {
  //padding-top: 80px;

    >.right {



        @media (min-width: breakpoint(lg)) {
            padding-left: 8%;
        }

        @media (max-width: breakpoint(lg)) {
            padding-left: 14%; // RP - Sathish Resolution changes
        }


        @media (max-width: breakpoint(md)) {
            padding-left: 0;
            margin-top: 2rem;
        }

        // @media (max-width: breakpoint(md)) {
        //     padding-left: 5%;
        // }

        @media (max-width:breakpoint(sm)) {
            padding-left: 0;
        }


    }
}


.content-with-sidebar-custom {
    padding-top: 3rem;
    >.right {



        @media (min-width: breakpoint(lg)) {
            padding-left: 8%;
        }

        @media (max-width: breakpoint(lg)) {
            padding-left: 16%;
        }


        @media (max-width: breakpoint(md)) {
            padding-left: 0;
            margin-top: 2rem;
        }

        // @media (max-width: breakpoint(md)) {
        //     padding-left: 5%;
        // }

        @media (max-width:breakpoint(sm)) {
            padding-left: 0;
        }


    }
}

// ********************************************************
// ********************************************************
// **
// **  Page / Template specific styles
// **
// ********************************************************
// ********************************************************


.training_lp .content-with-sidebar {
    margin-bottom: 50px;

    >.right {
        @media screen and (max-width: breakpoint(md)) {
            padding-left: 0;
        }

        @media screen and (max-width: breakpoint(sm)) {
            position: relative;
            width: 100vw;
            left: calc(-50vw + 50%);

        }
    }
}

.training-secondary .content-with-sidebar {
    >.right {
        @media screen and (max-width: breakpoint(md)) {
            padding-left: 5%;
        }

        @media screen and (max-width: breakpoint(sm)) {
            padding-left: 0;
        }
    }
}

.consulting-data-table .content-with-sidebar {

    >.right {

        @media (min-width: breakpoint(md)) {
            padding-left: 4%;
        }

        @media (min-width: breakpoint(lg)) {
            padding-left: 4%;
        }
    }
}

.ava-container .content-with-sidebar>.right {

    @media (min-width: breakpoint(md)) {
        padding-left: 2%;
    }

    @media (max-width: breakpoint(md)) {
        padding-left: 0%;
    }


}

.individual-location-container .content-with-sidebar>.right {
    @media (min-width: breakpoint(lg)) {
        padding-left: 5%;
    }
}

.sidebar-right{
    max-width: 40em;
}