// ********************************************************
// ********************************************************
// **
// **  Grid
// **
// ********************************************************
// ********************************************************



.trinity-container {
    @include container();
}
  
.xs-only-trinity-container {
    @include container($sm: false, $md: false, $lg: false)
}

.xs-sm-trinity-container {
    @include container($md: false, $lg: false);
}

.sm-up-trinity-container {
    @include container($xs: false)
}

.md-up-trinity-container {
    @include container($xs: false, $sm: false);
}

.lg-up-trinity-container {
    @include container($xs: false, $sm: false, $md: false);
}
  
.col {
    flex: 1;
}


// this class is the standard vertical spacing between larger components (top / bottom)

// Top and bottom spacing
.module-spacer {
    @include moduleSpacer();
}

.sm-up-module-spacer {
    @include moduleSpacer($xs: false);
}

.md-up-module-spacer {
    @include moduleSpacer($xs: false, $sm: false);
}

// Bottom spaccing only
.module-spacer-bottom {
    @include moduleSpacer($direction: 'bottom');
}

.md-up-module-spacer-bottom {
    @include moduleSpacer($xs: false, $sm: false, $direction: 'bottom');
}

// Top spacing only
.sm-up-module-spacer-top {
    @include moduleSpacer($xs: false, $direction: 'top');
}