// ********************************************************
// ********************************************************
// **
// **  Animations
// **
// ********************************************************
// ********************************************************



@keyframes exitScreen {
    100% {
      transform: translate(-9999px);
    }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}