@import "../../../css/global.variables.scss";

.outer-container {
    border: 2px solid #999D99;
    margin-bottom: 3rem;

    width: 100%;

    .custom-button {
        display: block;
        margin: 0 auto;
    }

    .shopping-cart-sidebar-container {
        padding: 2rem;

        .top-container {
            text-align: center;

            .order-blurb {
                margin-top: 1rem;
            }
        }

        .order-summary {
            hr {
                margin-top: 0;
            }

            .title {
                font-weight: 500;
                font-size: px-to-rem(24px);
                margin-bottom: 2rem;
            }

            .summary-inner {
                display: flex;
                justify-content: space-between;

                p {
                    margin-bottom: 0;
                }

                hr {
                    margin-top: .5rem;
                    margin-bottom: 1rem;
                }

                .left {
                    .shipping-title {
                        margin-bottom: 1rem;
                    }
                }

                .right {
                    text-align: right;
                }
            }

            .order-total-container {
                display: flex;
                justify-content: space-between;

                p {
                    font-weight: 500;
                    font-size: px-to-rem(24px);
                }

                .order-total-amount {
                    color: #EF6008;
                }
            }
        }

    }


    .discount-information {
        background: #f3f2f2;
        padding: 2rem;

        .discount-code {
            text-align: left;

            span {
                color: #EF6008;
                margin: 0 .25rem;                
                text-transform: uppercase;
                  
            }



            .fa-times {
                cursor: pointer;

                &::before {
                    content: "\f00d";
                    font-family: "FontAwesome";
                    color: #999D99;
                    font-weight: 400;
                }
            }
        }

        .discount-blurb {
            margin-top: 1rem;
        }
    }


}