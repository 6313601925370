

// This loader was made to be used inside of buttons, and small components
// =>set to posiiton: absolute for now
.small-loader {
    color: #ffffff;
    font-size: 10px;
    margin: -25px auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-indent: -9999em;
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;

    &::before, &::after, & {
        border-radius: 50%;
        width: .75rem;
        height: .75rem;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation: small-loader 1.8s infinite ease-in-out;
        animation: small-loader 1.8s infinite ease-in-out;
    }

    &::before, &::after {
        content: '';
        position: absolute;
        top: 0;
    }

    &::before {
        left: -2em;
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }

    &::after {
        left: 2em;
        animation-delay: 0.18s;
    }
}


@-webkit-keyframes small-loader {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes small-loader {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}