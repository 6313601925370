@import "../../../../css/global.variables.scss";
.services{
  display:none;
}
.services-container {
    background: #EBEBE8;
    padding: 2rem;
   display: block;

    @media screen and (min-width: breakpoint(lg)) {
        margin-left: 0 !important;
    }

    @media screen and (min-width: breakpoint(md)) {
        margin-left: 2rem;
    }

    .title {
        font-weight: 500;

        @media screen and (min-width: breakpoint(md)) {
            font-size: px-to-rem(20px);
        }
    }

    .inner-services {
        a {
            font-weight: 500;
            text-decoration: underline;
            color: #313830;
        }

        .content {
            font-weight: 300;
        }
    }
}