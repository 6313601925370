@import "../../../css/global.variables.scss";

.request-key-form {
    background: #E9F7FF;
    padding: 2rem;
    display: grid;
    margin-bottom: 3rem;

    @media screen and (max-width: breakpoint(sm)) {
        width: 100vw;
        position: relative;
        left: calc(-50vw + 50%);
        margin-bottom: 2rem;
    }

    .title {
        font-size: px-to-rem(26px);
        // font-weight: 500;
        margin-bottom: 2rem;
    }

    .inner-form {
       // display: flex;

        .help {
            color: red;
        }


        @media screen and (max-width: breakpoint(md)) {
            display: block;
        }





        .left {
            padding-right: 2rem;

            @media screen and (max-width: breakpoint(sm)) {
                padding-right: 0;
            }
        }


        .form-title {
            color: #313830;
            font-weight: 500;
            text-transform: uppercase;
            margin-bottom: 1rem;
            font-size: px-to-rem(18px);
        }


        input {
            display: block;
            border: 1px solid #707070;

            margin-bottom: .75rem;
            padding: .5rem;

            &::placeholder {
                font-weight: 500;
            }
        }

        select {
            display: block;
            width: 100%;
            padding: .5rem .5rem .5rem .75rem;
            font-size: 16px;
            background: #fff;
            border: 1px solid #707070;
            margin-bottom: 1rem;
        }


        textarea {
            display: block;
            margin-top: 1rem;
            margin-bottom: 2rem;
            width: 100%;
            padding: .75rem;
            height: 10rem;
        }


        .upload {
            input {
                border: 0;
                display: none;
            }


            .upload-link {
                cursor: pointer;

                .custom-button {
                    background: #0F2748;
                    border: 2px solid #0F2748;
                    color: #fff;

                    &:hover {
                        background: #fff;
                        color: #0F2748;
                    }
                }
            }
        }

        .recaptcha {
            margin-top: 2rem;
            margin-bottom: 2rem;
            width: 100%;
            display: flex;
            justify-content: center;

            @media screen and (max-width: breakpoint(sm)) {
                justify-content: left;
            }
        }

        .link {
            margin-top: 1rem;
            width: 100%;
            text-align: center;

            @media screen and (max-width: breakpoint(sm)) {
                text-align: left;
            }
        }
    }
}