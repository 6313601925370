@import '../../../../css/global.variables.scss';

.review-pay-assign-attendee-form {
    margin-bottom: 2.5rem;
    margin-top: 3rem;

    @media (min-width: breakpoint(sm)) {
        margin-bottom: 3.5rem;
    }

    @media (min-width: breakpoint(md)) {
        margin-bottom: 4rem;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    label {
        margin: .5rem 0;
    }

    form {
        display: flex;
        flex-direction: column;

        @media (min-width: breakpoint(md)) {
            flex-direction: row;
        }
    }

    .left,
    .right {

        @media (min-width: breakpoint(md)) {
            width: 50%;
        }
    }

    h2 {
        margin-bottom: .75rem;
    }


    .form-section {
        display: flex;
        flex-direction: column;
        // margin-bottom: 1.5rem;
    }

    .inner-paragraph {
        margin-bottom: .5rem;
    }

    .form-title {
        font-size: px-to-rem(20px);
        line-height: 26px;
        font-weight: 500;
    }

    .edit-profile {
        .edit {
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    // .edit-profile {
    //     display: flex;
    //     width: 10%;
    //     justify-content: space-between;

    //     .edit,
    //     .delete {
    //         cursor: pointer;
    //     }


    // }


}