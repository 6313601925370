// ********************************************************
// ********************************************************
// **
// **  Font Styling
// **
// ********************************************************
// ********************************************************



// ********************************************************
// ********************************************************
// **
// **  General font styles
// **
// ********************************************************
// ********************************************************


main {
    p {
        font-size: px-to-rem(16px);

    }


}

a {
    color: rgba(40, 175, 247, 1);
}


// ********************************************************
// ********************************************************
// **
// **  Font styles for WYSIWIG
// **  
// **  1. add the class ".the-content" to component content coming from a WYSIWIG (ex: <ContentWell />)
// **
// ********************************************************
// ********************************************************


.the-content {

    p {
        color: color('text', primary);
        margin-bottom: 1.25rem;
        line-height: 22px;

        @media (min-width: breakpoint(md)) {
            margin-bottom: 1.75rem;
            line-height: 24px;
        }
    }

    h1 {
        font-weight: 400;
        font-size: 28px;
        line-height: 33px;
        margin-bottom: 1rem;

        @media (min-width: breakpoint(md)) {
            font-size: px-to-rem(40px);
            line-height: 48px;
            margin-bottom: 2rem;
        }
    }

    h2 {
        // changing this font size effects all templates
        line-height: 33px;
        font-size: px-to-rem(28px);
        font-weight: 400;
        margin-bottom: .75rem;

        @media (min-width: breakpoint(md)) {
            font-size: px-to-rem(40px);
            line-height: 48px;
            margin-bottom: 2rem;
        }
    }

    a {
        &:hover {
            text-decoration: underline;
        }
    }

}


// ********************************************************
// ********************************************************
// **
// **  Page specific changes
// **
// ********************************************************
// ********************************************************


.staffing .the-content {
    li {
        color: color('text', primary);
        margin-bottom: 1.25rem;
        line-height: 22px;

        @media (min-width: breakpoint(md)) {
            margin-bottom: 1.75rem;
            line-height: 24px;
        }
    }

}