@import "../../css/global.variables.scss";

.radio-design {
  display: flex;
  justify-content: space-between;
  color: #a39d99;
  border-bottom-style: solid;
  border-bottom-color: #dddddd;
  border-bottom-width: thin;

  @media screen and (max-width: 480px) {
    display: block;
    color: #a39d99;
    border-bottom-style: solid;
    border-bottom-color: #dddddd;
    border-bottom-width: thin;
  }
  @media screen and (max-width: 950px) {
    display: block;
    color: #a39d99;
    border-bottom-style: solid;
    border-bottom-color: #dddddd;
    border-bottom-width: thin;
  }
}
.radio-div {
  align-content: center;
  padding-top: 10px;  
}

.question-text {
  display: flex;
  padding-top: 10px;
  color: #665b54;
  font: 500 14px/1.6 'Raleway', Arial, Helvetica, sans-serif;
  font-weight: 700;
}

.area {
  width: 50%;

  @media screen and (max-width: 480px) {
    width: 100%;
  }
}

.main-content {
  color: #827973;
}

.que-heading {
  color: #003468;
  border-bottom-style: solid;
  border-bottom-color: #dddddd;
  border-bottom-width: thin;
  padding: 0;
  margin: 0;
}

.sub-title {
  color: #313830;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: 1.625rem;
}

.sub-content {
  color: #827973;
}

.outer-div {
  background-color: #E9F7FF;
  padding: 2rem;
  padding-top: 0.8rem;
}

.question-text-box {
  color: #003468;
  font: 400 14px/1.6 'Oswald', Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
}

.sub-div {
  padding-top: 1rem;
  border-bottom-style: solid;
  border-bottom-color: #dddddd;
  border-bottom-width: thin;
  padding-bottom: 0.5rem;

}

.sub-title-content {
  padding-top: 0.5rem;
}
.recaptcha-div {
  display: flex;
  justify-content: center;
  padding-top: 10px;

  @media screen and (max-width: 480px) {
    justify-content: center;
  }
}

.custome-button-div {
  display: flex;
  justify-content: center;

  @media screen and (max-width: 480px) {
    justify-content: center;
  }
}

.feedback {
  margin-bottom: 2.5rem;
}

@media screen and (max-width: 767px) {
  .form-check-inline {
    display: block; 
 }
 .question-text{
   margin-bottom: 0;
 }
}
// input[type="radio"] + *::before {
//   content: "";
//   display: inline-block;
//   vertical-align: bottom;
//   width: 1rem;
//   height: 1rem;
//   margin-right: 0.3rem;
//   border-radius: 50%;
//   border-style: solid;
//   border-width: 0.1rem;
//   border-color: gray;
// }

//KT add style for responsive 11-16-2021
.feedback-responsive-changed{
  margin-top: 2rem;
  .recaptcha-div {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  @media screen and (max-width: breakpoint(md)) {
    .outer-div{
      width: 100vw;
      margin-left: calc(50% - 50vw);

    }
  }
}