@import "../../../../css/global.variables.scss";

.program-form-container {
    background: #E9F7FF;
    padding: 2rem;
    margin-bottom: 3rem;
    display: grid;

    .help {
        color: red;
    }


    @media screen and (max-width: breakpoint(sm)) {
        position: relative;
        width: 100vw;
        left: calc(-50vw + 50%);
    }

    .inner-form {
        .left {
            padding-right: 2rem;

            @media screen and (max-width: breakpoint(sm)) {
                margin-bottom: 2rem;
            }
        }

        .title {
            color: #313830;
            font-weight: 500;
            text-transform: capitalize;
            font-size: px-to-rem(18px);
        }

        input {
            display: block;
            border: 1px solid #707070;
            width: 100%;
            margin-bottom: .75rem;
            padding: .5rem;

            &::placeholder {
                font-weight: 500;
            }
        }

        .upload {
            input {
                border: 0;
                display: none;
            }


            .upload-link {
                //  margin-right: auto;
                //  width: 40%;
                //  text-align: left;
                //  padding: .5rem;
                cursor: pointer;

                .custom-button {
                    background: #0F2748;
                    border: 2px solid #0F2748;
                    color: #fff;

                    &:hover {
                        background: #fff;
                        color: #0F2748;
                    }
                }
            }
        }

        textarea {
            display: block;
            margin-top: 1rem;
            margin-bottom: 2rem;
            width: 100%;
            padding: .75rem;
            height: 10rem;
        }
    }

    .recaptcha {
        margin-top: 3rem;
    }

    .recaptcha,
    .link {
        @media screen and (min-width: breakpoint(sm)) {
            text-align: -webkit-center;
        }
    }
}