@import "../../../css/global.variables.scss";

.notes-container {


    .name {
        font-size: px-to-rem(22px);
        font-weight: 500;
    }

    .version-container {
        display: flex;
        flex-wrap: wrap;

        @media screen and (max-width: breakpoint(sm)) {
            display: block;

            ul {

                padding-inline-start: 20px;

            }
        }


    }

}