// ********************************************************
// ********************************************************
// **
// **  general component styles
// **
// ********************************************************
// ********************************************************



@import '../../../../css/global.variables.scss';

.software-box-cta {
    display: block;
    color: #58595B !important;
    text-align: center;
    padding: 1.25rem 1.25rem 1.25rem 1.25rem;

    border: 5px solid;
    border-color: #58595B;

    @media (min-width: breakpoint(sm)) {
        height: 100px;
    }
    @media (min-width: 1000px) {
        height: 190px;
    }
    @media (min-width: breakpoint(lg)) {
        height: 325px;
    }

    &:hover {
        text-decoration: none;
    }

    div {
        color: #58595B !important;

        &:hover {
            text-decoration: none;
        }
    }

    .title {
        display: block;
        font-size: px-to-rem(16px);
        margin-bottom: .5rem;
        color: #58595B;
        line-height: 26px;
        max-width: 90%;

        @media (min-width: breakpoint(sm)) {
            line-height: 30px;
            font-size: px-to-rem(24px);
        }

        @media (min-width: breakpoint(md)) {
            line-height: 36px;
            font-size: px-to-rem(32px);
        }

        @media (min-width: breakpoint(lg)) {
            margin-bottom: 30px;
        }
    }

    .sub-title {
        display: block;
        color: #58595B;
        margin-bottom: 1.5rem;
        font-size: px-to-rem(18px);
        font-weight: 500;
    }

    .custom-block {
        display: block;
        color: #58595B;
        font-size: px-to-rem(14px);
        line-height: 18px;

        @media screen and (min-width: 0px) and (max-width: 400px) {
            display: none;
        }

        @media screen and (min-width: 401px) and (max-width: 1024px) {
            display: none;
        }
    }
    .content {
        display: none;
        font-size: px-to-rem(14px);
        line-height: 18px;

        @media(min-width: breakpoint(sm)) {
            display: block;
        }

        @media (min-width: breakpoint(md)) {
            font-size: px-to-rem(16px);
            line-height: 22px;
            margin-bottom: 30px;
        }
    }

    .link-text {
        display: block;
        color: #58595B;

        @media (min-width: breakpoint(sm)) {
            display: inline;
        }
    }

    .bottom-link {
        position: absolute;
        bottom: 10%;
        color: #58595B;
        transform: translateX(100%);

        @media screen and (max-width: breakpoint(sm)) {
            position: static;
            display: none;
        }
    }
}


.small-software-box-cta {

    @media (min-width: breakpoint(sm)) {
        // min-width: 112px;
        min-height: 112px;
        padding-top: 1.5rem;
        padding-left: .5rem;
        padding-right: .5rem;
    }

    @media(min-width: breakpoint(md)) {
        // min-width: 200px;
        min-height: 200px;
        padding-top: 2.5rem;
        padding-left: .75rem;
        padding-right: 1.5rem;
    }

    @media(min-width: breakpoint(lg)) {
        // min-width: 210px;
        min-height: 210px;
        padding-left: .75rem;
        padding-right: 1.5rem;
    }

    .title {
        display: flex;
        align-items: baseline;
        margin-bottom: 0;
        line-height: 18px;
        font-weight: 500;

        @media (min-width: breakpoint(sm)) {
            font-size: px-to-rem(16px);
            line-height: 18px;
        }

        @media (min-width: breakpoint(md)) {
            line-height: 25px;
            font-size: px-to-rem(22px);
        }

        @media (min-width: breakpoint(lg)) {
            font-size: px-to-rem(28px);
            line-height: 34px;
        }

        .arrow {

            // bad code -- needed to get this done for qa
            @media (min-width: breakpoint(sm)) {
                font-size: px-to-rem(16px) !important;
            }

            @media (min-width: breakpoint(md)) {
                font-size: px-to-rem(20px) !important;
            }

            @media (min-width: breakpoint(lg)) {
                font-size: px-to-rem(24px) !important;
            }
        }
    }



    .link-text {
        display: none;
    }

    .bottom-link {
        @media (min-width: breakpoint(sm)) {
            display: none;
        }
    }
}