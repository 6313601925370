@import '../../../css/global.variables.scss';

.widget-container {
    background: #EBEBE8;
    padding: 2rem;

    .search-title {
        text-transform: uppercase;
    }

    h2 {
        color: #0F2748;
    }

    .btn {
        background: #0F2748;
        font-size: px-to-rem(18px);
        color: #fff;
    }

    @media screen and (min-width: breakpoint(lg)) {

        h2 {
            font-size: px-to-rem(26px);
        }

    }

}


.training_lp .widget-container,
.training_lp_2 .widget-container {
    height: 100%;
    margin-left: 10px;
    // height: 100%;

    @media screen and (min-width: breakpoint(lg)) {
        h2 {
            margin-bottom: 1rem;
        }

    }

    @media screen and (max-width: breakpoint(lg)) {
        h2 {
            font-size: px-to-rem(20px);
        }

        .form-inline {
            margin-top: 2rem;
        }

    }


    @media screen and (max-width: breakpoint(sm)) {
        margin: 10px 0px 0px 0px;

        h2 {
            font-size: 16px;
        }

    }


    .form-control {
        margin-bottom: 1rem;
    }


    .button-container {
        display: flex;
        justify-content: center;
        margin-top: 1rem;

        @media screen and (max-width: breakpoint(lg)) {
            margin-top: 2rem;
        }

        .btn {

            @media screen and (min-width: breakpoint(lg)) {
                font-size: px-to-rem(18px);
            }

            @media screen and (min-width: breakpoint(sm)) {
                font-size: px-to-rem(16px);
            }

            padding: .5rem 4rem;
            border-radius: 0;
        }
    }
}