@import '../../../css/global.variables.scss';

.image-left-with-content-well {

    @media (min-width: breakpoint(sm)) {
        display: flex;
    }

    .left {
        display: block; 
        margin: 0 auto 1rem auto;

        @media (min-width: breakpoint(sm)) {
            padding-right: 3rem;
            width: 35%;
            max-width: 288px;
        }

        @media (min-width: breakpoint(md)) {
            padding-right: 1rem;
        }

        img {
            display: block;
            margin: 0 auto;
            max-width: 50%;

            @media (min-width: breakpoint(sm)) {
                max-width: 100%;
            }
        }
    }

    .right {

        @media (min-width: breakpoint(sm)) {
            width: 65%;
            flex: 1;
        }
    }

}