@import "../../../../css/global.variables.scss";



.indiv-container .hero-widget-container {

    min-height: 280px;
    background: #E9F7FF;
    padding: 1.75rem 1.25rem;
    display: flex;
    flex-direction: column;
    // height: 100%;

    @media screen and (max-width: breakpoint(sm)) {
        margin-top: 2rem;
    }

    .inputs {
        input {
            border: 1px solid #707070;
        }

        .name {
            display: block;
            width: 100%;
            margin-bottom: .5rem;
            padding: .5rem;

            &::placeholder {
                font-weight: 500;
            }
        }

        .email {
            width: 100%;
            padding: .5rem;

            &::placeholder {
                font-weight: 500;
            }
        }


    }

    .title {
        color: #5C615B;
        text-transform: capitalize;
        margin-bottom: 1.25rem;
        font-size: px-to-rem(20px);
        font-weight: 500;
    }

    .link {
        margin-left: auto;
        margin-top: .75rem;

        @media screen and (max-width: breakpoint(sm)) {
            margin-right: auto;
        }
    }

    @media screen and (min-width: breakpoint(lg)) {


        .link {
            a {
                font-size: px-to-rem(18px);
                font-weight: 500;
            }

        }
    }

    @media (max-width: breakpoint(lg)) {
        width: 100%;
    }



    @media (max-width: breakpoint(md)) {
        width: 100%;
        // position: absolute;
        // bottom: 0;

        .link {
            // margin-left: 0;
        }
    }

    @media (max-width: breakpoint(sm)) {
        position: static;
        width: 100vw;
        position: relative;
        left: calc(-50vw + 50%);

    }


}