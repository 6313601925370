@import "../../../../../css/global.variables.scss";

.leadership-container {
    background: $widgetBox;
    margin-bottom: 2rem;
    padding: 2rem;

    @media screen and (max-width: breakpoint(sm)) {
        position: relative;
        width: 100vw;
        left: calc(-50vw + 50%);
    }

    .title {
        font-weight: 500;
        text-transform: uppercase;

        @media screen and (min-width: breakpoint(md)) {
            font-size: px-to-rem(26px);
        }

        @media screen and (max-width: breakpoint(md)) {
            font-size: px-to-rem(20px);
        }
    }

    .content-wrapper {
        display: flex;
        align-items: start;
        margin-bottom: 1rem;

        @media screen and (max-width: 420px) {
            display: block;
        }

        .image {

            img {
                width: 260px;

                @media screen and (max-width: breakpoint(md)) {
                    display: block;
                }
            }
        }

        .content {
            color: #313830;
            font-size: px-to-rem(18px);
            margin-left: 1rem;

            @media screen and (max-width: breakpoint(md)) {
                margin-left: 1rem;
            }

            @media screen and (max-width: breakpoint(sm)) {
                margin-left: 0;
                margin-top: 1rem;
            }

            p {
                margin-bottom: 0;
            }

            .name {
                font-weight: 500;

                @media screen and (min-width: breakpoint(md)) {
                    font-size: px-to-rem(24px);
                }

                @media screen and (max-width: breakpoint(md)) {
                    font-size: px-to-rem(18px);
                }
            }

            .company-title {
                margin-bottom: 1rem;
            }

            .address-2 {
                margin-bottom: 1rem;
            }

            a {
                color: #313830;
                display: block;
            }

            .custom-a {
                color: #57abe3;
                display: block;
            }
        }
    }
}