@import "../../../../../../css/global.variables.scss";

.bio-widget-container {
    background: #EBEBE8;
    padding: 2rem;
    text-align: left;

    @media screen and (min-width: breakpoint(lg)) {
        margin-left: 0 !important;
    }

    @media screen and (min-width: breakpoint(sm)) {
        margin-left: 2rem;
    }

    .title {
        font-weight: 500;
        margin-bottom: 2rem !important;

        @media screen and (min-width: breakpoint(md)) {
            font-size: px-to-rem(35px) !important;
        }
    }

    .leaders-info {
        a {
            font-weight: 500;
            text-decoration: underline;
            color: #313830;
        }

        .job-title {
            font-weight: 300;
        }
    }
}