@import "../../../../../css/global.variables.scss";

.problem-form-container {
    background: #E9F7FF;
    padding: 2rem;
    display: grid;
    margin-right: 2rem;



    @media screen and (max-width: breakpoint(sm)) {
        position: relative;
        width: 100vw;
        left: calc(-50vw + 50%);
        margin-bottom: 2rem;
        margin-right: 0;
    }

    .inner-form {
        .help {
            color: red;
        }

        .form-title {
            color: #313830;
            font-weight: 500;
            text-transform: capitalize;
            margin-bottom: 2rem;
            font-size: px-to-rem(26px);
        }

        input {
            display: block;
            border: 1px solid #707070;
            width: 100%;
            margin-bottom: .75rem;
            padding: .5rem;

            &::placeholder {
                font-weight: 500;
            }
        }

        textarea {
            display: block;
            margin-top: 1rem;
            margin-bottom: 2rem;
            width: 100%;
            padding: .75rem;
            height: 10rem;
        }



        .upload {
            margin-bottom: 2rem;

            input {
                border: 0;
                display: none;
            }


            .upload-link {
                cursor: pointer;

                .custom-button {
                    background: #0F2748;
                    border: 2px solid #0F2748;
                    color: #fff;

                    &:hover {
                        background: #fff;
                        color: #0F2748;
                    }
                }
            }
        }
    }
}