@import '../../../css/global.variables.scss';

$footer_main_color: #313830;
$footer_accent_color: #28AFF7;

footer {
    margin-top: 30px;
    margin-bottom: 83px;
    background: $footer_main_color;
    color: white;
    text-align: center;

    @media (min-width: breakpoint(sm)) {
        margin-top: 0;
    }

    @media screen and (min-width: breakpoint(md)) {
        text-align: left;
        margin: 0;
    }

    .footer-content-container {
        margin: 0 auto;
        padding-top: 2rem;
        padding-bottom: 0rem;

        @media screen and (min-width: breakpoint(md)) {
            padding-bottom: 0rem;
        }

        @media screen and (min-width: breakpoint(lg)) {
            padding-bottom: 0rem;
        }

        .breadcrumbs-path {
            display: flex;

            .logo {
                margin-right: .7rem;
            }

            ol {
                display: flex;
                width: 100%;
                padding-inline-start: 0px;

                li.item {
                    list-style: none;
                    margin-right: .7rem;


                    &::before {
                        width: 8px;
                        height: 36px;
                        margin-right: 11px;
                        content: ">";
                        display: inline-block;

                        margin-top: -1px;
                        height: 18px;
                        background-position-y: 100%;
                    }
                }

            }


        }


    }

    .newsletter,
    .social-icons {

        &::after,
        &::before {
            content: '';
            display: block;
            margin: 1rem auto;
            width: 100px;
            border-bottom: 1px solid $footer_accent_color;

            @media screen and (min-width: breakpoint(md)) {
                display: none;
                margin: 1.25rem 0;
            }
        }


        &::before {
            display: none;
        }

        svg {
            margin: 0 12px;

            @media screen and (min-width: breakpoint(md)) {
                width: 24px;
                margin: 0 0 0 8px;
            }
        }
    }

    .newsletter.mobile {
        @media (min-width: breakpoint(md)) {
            display: none;
        }
    }

    .subscribe {
        position: absolute;
        margin-top: 40px;
    }

    .newsletter.desktop {
        display: none;
        position: relative;

        @media (min-width: breakpoint(md)) {
            display: block;
            margin-top: 3rem;

            &::before {
                display: block;
                width: 100%;
            }
        }
    }

    div.social-icons {
        &::before {
            border: 1px solid $footer_main_color;
        }

        @media (min-width: breakpoint(md)) {
            display: flex;
            align-items: center;
        }
    }

    .speciality-container {

        .social-text,
        .bottom-bar-icon {
            display: none;

            @media (min-width: breakpoint(md)) {
                display: flex;
            }
        }

        .social-text {

            > p {
                margin-bottom: 0;
                margin-right: 1rem;
            }
        }

        @media screen and (min-width: breakpoint(md)) {
            display: flex;
            align-items: center;
            border-top: 1px solid #707070;
            margin-top: 2rem;
            padding: 1rem 0;

            .left,
            .right {
                width: 50%;
                display: flex;
                align-items: center;
            }

            .right {
                padding-left: 1.5rem;
                justify-content: space-between;
            }
        }


    }

    .social-icons {
        &::after {
            // xd file had it set this way -- possible mistake ??
            margin-top: .5rem;
        }
    }

    ul {
        margin: 0;
        padding: 0;
    }

    a {
        font-size: px-to-rem(16px);
        color: white;

        @media screen and (min-width: breakpoint(lg)) {
            font-size: px-to-rem(18px);
        }

        &:hover {
            color: white;
        }
    }

    .footer-menu {
        display: none;

        @media screen and (min-width: breakpoint(md)) {
            display: flex;
        }

        .col1 {
            width: 25%;
        }

        .col2 {
            width: 25%;
        }

        .col3 {
            width: 25%;
        }

        .col4 {
            width: 25%;
        }


        > div {
            border-right: 1px solid #707070;
            padding-left: 1.5rem;
            padding-right: 1rem;

            &:last-of-type {
                border: none;
            }

            &:first-of-type {
                padding-left: 0;
            }
        }

        li {
            margin: 1rem 0;
            list-style-type: none;
        }

        > li {
            color: $footer_accent_color;
        }
    }

    .newsletter p {
        // max-width: 190px;
        margin-left: auto;
        margin-right: auto;

        @media screen and (min-width: breakpoint(md)) {
            max-width: unset;
        }
    }

    button {
        //background: white;
        background: #EF6008;
        color: #ffffff;
        padding: .5rem 2.5rem;
        border-radius: 30px;
        font-size: px-to-rem(16px);
        font-weight: 500;
        border: none;

        @media screen and (min-width: breakpoint(md)) {
            color: white;
            padding: 0;
            background: transparent;
        }

        @media screen and (min-width: breakpoint(lg)) {
            font-size: px-to-rem(18px);
        }
    }

    .headline {
        font-size: px-to-rem(14px);
        color: $footer_accent_color;
        margin-bottom: 10px;
        font-weight: 500;

        @media screen and (min-width: breakpoint(md)) {
            margin-bottom: 2px;
            font-size: px-to-rem(16px);
        }

        @media screen and (min-width: breakpoint(lg)) {
            font-size: px-to-rem(18px);
        }

        &:hover {
            color: $footer_accent_color;
        }
    }

    p {
        font-size: px-to-rem(13px);

        @media screen and (min-width: breakpoint(md)) {
            font-size: px-to-rem(16px);
        }
    }

    .copyright-contact-container {

        @media screen and (min-width: breakpoint(md)) {
            display: flex;
            justify-content: center;
            background: white;
            padding: 1rem 0 0 1rem;

            p {
                font-size: px-to-rem(12px);

            }
        }
    }

    .contact-information {
        max-width: 310px;
        margin: 0 auto;
        padding-bottom: 2rem;

        @media screen and (min-width: breakpoint(md)) {
            max-width: 525px;
            padding-bottom: 0;
            color: $footer_main_color;
            margin: 0;
            margin-right: 8px;
        }
    }

    .copyright {
        background: white;
        color: #313830;
        padding: 1rem 10% 1rem 10%;
        font-size: px-to-rem(12px);

        .copyright-links {
            background: white;
            color: #313830;
            font-size: px-to-rem(12px);
        }

        @media screen and (min-width: breakpoint(xs)) {
            .copyright-links {
                display: none;
            }
        }

        @media screen and (min-width: breakpoint(sm)) {
            padding: 1rem 1rem 1rem 1rem;
            .copyright-links {
                display: contents;
            }
        }

        @media screen and (min-width: breakpoint(md)) {
            padding: 0;
            .copyright-links {
                display: contents;
            }
        }

        > p {
            margin: 0;
        }
    }

    .footer-links {
        margin-left: 5px;
    }

    .footer-empty-line {
        border-bottom: 1px solid $footer_accent_color;

        @media screen and (min-width: breakpoint(md)) {
            margin: 1.25rem 0;
        }
    }
}