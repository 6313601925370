@import "../../../css/global.variables.scss";

.posts-container {

    @media screen and (min-width: breakpoint(lg)) {
        margin-right: 2rem;
    }

    .widgetBox-wrapper {
        &:first-child {
            margin-top: 0;
        }
    }

    .widget-wrapper {
        display: flex;

        img {
            margin-right: 2rem;
            margin-top: -0.4rem;
            height: 100%;

            @media screen and (max-width: breakpoint(sm)) {
                display: none;
            }
        }

    }
}