@import '../../../css/global.variables.scss';

.minnow-lp {
  .title-custom {
    margin-bottom: 2rem;

    @media screen and (min-width: breakpoint(sm)) {
      font-size: px-to-rem(40px);
    }

    @media screen and (max-width: breakpoint(sm)) {
      font-size: px-to-rem(28px);
      //KT change margin left margin-left: 1rem; to 5px for mobile view
      margin-left: 5px;
    }
  }

  .first-content {

    .title-custom {
      margin-bottom: 2rem;

      @media screen and (min-width: breakpoint(sm)) {
        font-size: px-to-rem(40px);
      }

      @media screen and (max-width: breakpoint(sm)) {
        margin-bottom: 0.5rem;
        font-size: px-to-rem(28px);
        margin-left: -0.2rem;
      }
    }

    @media screen and (min-width: breakpoint(lg)) {
      .title {
        font-size: px-to-rem(32px);
        margin-bottom: .50rem;
      }

      p {
        font-size: px-to-rem(16px);
        overflow-wrap: break-word;
        // margin-bottom: 2rem;
      }
    }

    @media screen and (max-width: breakpoint(lg)) {
      .title {
        font-size: px-to-rem(32px);
        margin-bottom: .50rem;
      }

      p {
        font-size: px-to-rem(16px);
        // margin-bottom: 2rem;
      }
    }

    @media screen and (max-width: breakpoint(md)) {
      .title {
        font-size: px-to-rem(22px);
        margin-bottom: .50rem;
        padding-right: 2rem;
      }

      p {
        font-size: px-to-rem(16px);
        // margin-bottom: 2rem;
      }
    }

    @media screen and (max-width: breakpoint(sm)) {
      .title {
        font-size: px-to-rem(16px);
        margin-bottom: .50rem;
      }

      p {
        padding-left: px-to-rem(15px);
        padding-right: px-to-rem(15px);
        font-size: px-to-rem(15px);
        // margin-bottom: 2rem;
      }
    }

    .title {
      font-weight: 500;
    }

  }

  .courses-container {
    margin: 1rem;
    margin-left: 0;
    margin-bottom: 2rem;
    box-shadow: 0 2px 10px #2b71b84D;
    border-top: 5px solid #2b71b8;

    @media screen and (max-width: breakpoint(sm)) {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .top-heading {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .light-blue {
      color: #2b71b8;
    }

    .outer-row {
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #efefef;
      padding: 5px 5px 0 5px;
    }
  }
}
// add minnow-event-content-page class to apply same design as Related News.
.minnow-news-section, .minnow-event-content-page {
  .outer-view {
    padding: 20px 20px 20px;
    background-color: #F0F4F7;
    margin-left: 5px;    
    flex: 0 0 32%;
    max-width: 32%;
    @media screen and (min-width: breakpoint(lg)) {
      flex: 0 0 24%;
      max-width: 24%;
    }
    @media screen and (max-width: breakpoint(md)) {
      flex: 0 0 49%;
      max-width: 49%;
    }
    @media screen and (max-width: breakpoint(sm)) {
      padding-left: 20px;
      margin-bottom: 5px;
      flex: 0 0 100%;
      max-width: 100%;
    }    
    
  }

  .news-heading-font {
    font-size: 20px;
    margin-bottom: 10px;
  }
}

//KT - Add class for a tag in service brand page
.content {

  a {
      font-size:16px;
      font-weight: 400;

    @media screen and (max-width: breakpoint(sm)) {
      font-size: 15px;      
    }
    @media screen and (max-width: breakpoint(lg)) {
      font-size: 16px;      
    }
    @media screen and (max-width: breakpoint(md)) {
      font-size: 15px;      
    }
  }
}
//KT add style for hero component 11-18-2021
//RK -- CG -- Old designer has added minnow-lp-changed class.So removing it as it was not needed based on ticket from marketing -- 02/21/2022
//.minnow-lp-changed{
  //@media screen and (min-width: 1250px){
   // .hero{
     // min-height: 530px !important;
    //}
  //}
//}