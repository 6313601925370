@import '../../../css/global.variables.scss';

.filtered-image-content-well {
    padding: 2.5rem 0;

    p {
        margin-bottom: 0;
    }

    span {
        margin-bottom: 2rem;
        display: block;
    }

    ul {
        margin-bottom: 2rem;
    }

    ol {
        padding-inline-start: 15px;
    }

    .library-container {
        // width: 70%;

        // @media screen and (max-width: breakpoint(sm)) {
        //     width: 100%;
        // }

        .individual-article {
            // display: flex;
            justify-content: space-between;
            margin-bottom: 2rem;

            // @media screen and (max-width: breakpoint(sm)) {
            //     display: block;
            // }

            .date {
                // margin-bottom: 1rem;
                font-weight: 500;
                font-size: px-to-rem(20px);
            }

            .article-name {}
        }
    }



    .content {
        margin-top: 1rem;
        //margin-bottom: -30px;
        @media (min-width: breeakpoint(sm)) {
            margin-top: 3rem;
        }
    }

    .title-filters {
        display: none;
        border-bottom: 1px solid #313830;

        @media (min-width: breakpoint(md)) {
            display: flex;
            margin-bottom: 2.5rem;
        }
    }

    .title-filters .filter {
        font-size: px-to-rem(22px);
        color: #313830;
        margin-right: 40px;
        cursor: pointer;

        &.active {
            border-bottom: 7px solid #EF6008;
            margin-bottom: 0;
        }
    }

    .section-title {
        font-size: px-to-rem(22px);
        color: #313830;
    }

    .mobile-seperator {
        display: block;
        width: 100%;
        background: #313830;
        margin: 1.5rem 0;
    }

    .content-group-container {
        .custom-button {
            margin-top: 2rem;
        }

    }
}