@import "../../../../../css/global.variables.scss";

.custom-training-form-container {
    background: #E9F7FF;
    padding: 2rem;
    margin-top: 2rem;

    @media screen and (max-width: 870px) {
        display: block;
        width: 100vw;
        position: relative;
        left: calc(-50vw + 50%);
        margin-bottom: 2rem;
    }

    .help {
        color: red;
    }


    .upload {
        margin-bottom: 2rem;

        input {
            border: 0;
            display: none;
        }


        .upload-link {
            cursor: pointer;

            .custom-button {
                background: #0F2748;
                border: 2px solid #0F2748;
                color: #fff;

                &:hover {
                    background: #fff;
                    color: #0F2748;
                }
            }
        }
    }

    .form-title {
        color: #313830;
        font-weight: 500;
        text-transform: capitalize;
        margin-bottom: 2rem;
        font-size: px-to-rem(26px);
    }

    .content-title {
        color: #313830;
        font-weight: 500;
        text-transform: capitalize;
        margin-bottom: 1rem;
        font-size: px-to-rem(16px);
    }

    .content {
        padding-right: 5rem;

        @media screen and (max-width: breakpoint(sm)) {
            padding-right: 0;
        }
    }




    input {
        border: 1px solid #707070;
        margin-bottom: .75rem;
        padding: .5rem;

        &::placeholder {
            font-weight: 500;
        }
    }




    textarea {
        display: block;
        margin-top: 1rem;
        margin-bottom: 2rem;
        width: 100%;
        padding: .75rem;
        height: 10rem;

        &::placeholder {
            font-weight: 500;
        }
    }

    .title {
        color: #313830;
        font-weight: 500;
        margin-bottom: 1rem;
        font-size: 1.325rem;
    }

    .training-container {
        @media screen and (max-width: breakpoint(sm)) {
            margin-top: 2rem;
        }
    }




    .bottom-container {
        @media screen and (min-width: breakpoint(sm)) {
            margin-left: auto;
            margin-right: auto;
        }



        .link {
            .custom-button {
                width: 250px;
            }
        }

        .recaptcha {
            margin-bottom: 2rem;

            @media screen and (min-width: breakpoint(sm)) {
                margin-right: 2rem;
            }

            @media screen and (max-width: breakpoint(sm)) {
                margin-bottom: 1rem;
            }
        }
    }

}