@import '../../../../css/global.variables.scss';

.email-login-form {

    display: flex;
    flex-direction: column;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;

    .form-container {
        display: flex;
        flex-direction: column;
        padding: 1.5rem 1rem;
        padding-bottom: 2.5rem;

        h2 {
            text-align: center;
            margin-bottom: 2rem;
        }

        // border: 2px solid #8f8d8d;
        p {
            margin-top: .75rem;
        }
    }

    p.contact {
        padding: 1rem 1rem;
    }

    .right {
        padding: 1.5rem 1rem;
    }

    .left {
        border-right: 2px solid #8f8d8d;

        @media screen and (max-width: 720px) {
            border-bottom: 2px solid #8f8d8d;
            border-right: 0;
        }
    }

    .create-account-cta {
        padding: 1.5rem 1rem;

        p {
            font-weight: 500;
            font-size: px-to-rem(22px);
            padding: 0 .5rem;
            text-align: center;
        }

        button {
            margin-top: 1rem;
        }
    }
}