@import "../../../css/global.variables.scss";

.ehs-landing {
  .ehs-container {
    margin-top: 3rem;
  }

  .content-well {
    @media screen and (min-width: breakpoint(sm)) {
      margin-right: 7rem;
    }

  }

}