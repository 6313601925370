@import '../../../css/global.variables.scss';

.training-search {

    @media screen and (max-width: breakpoint(md)) {
        .row {
            flex-wrap: nowrap;
        }
    }

    @media screen and (max-width: breakpoint(sm)) {
        .row {
            flex-wrap: wrap;
            flex-direction: column-reverse;
        }
    }
}