@import "../../../css/global.variables.scss";

.edit-profile-page {
    margin-bottom: 3rem;


    .text-content-container {
        margin-bottom: 1rem;

        .text-content {

            width: 100vw;
            position: relative;
            left: calc(-50vw + 50%);
            padding: 1.25rem 0;
            background-color: rgba(48, 136, 92, 0.73);

            h1 {
                font-size: px-to-rem(25px);
                line-height: 30px;
                color: #fff;
                font-weight: 300;

                @media (min-width: breakpoint(sm)) {
                    font-size: px-to-rem(28px);
                    line-height: 33px;
                }

                @media (min-width: breakpoint(md)) {
                    font-size: px-to-rem(32px);
                    line-height: 38px;
                }

            }
        }
    }

}