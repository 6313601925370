@import '../../../css/global.variables.scss';

.main-div {
  display: flex;
  Height: 30vh;
  Width: 40vh;
  padding-bottom: 8rem;
  justify-content: center;
  align-content: center;
  justify-items: center;
}

.border-image {
  border-color: red;
  border-radius: 1px;
}

