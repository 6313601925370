@import "../../../../css/global.variables.scss";

.request-a-key-form-container {
    background: #E9F7FF;
    padding: 2rem;
    margin-bottom: 2rem;
    display: grid;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: breakpoint(sm)) {
        width: 100vw;
        position: relative;
        left: calc(-50vw + 50%);
        margin-bottom: 2rem;
    }

    .inner-form {

        @media screen and (max-width: breakpoint(md)) {
            display: block;
        }

        .help {
            color: red;
        }

        .trinity-select-list .select-wrapper {
            margin-bottom: .5rem;
        }

        .checkbox-container {
            display: flex;

            .inner-title {
                margin-right: 4rem;
            }

            .first-input,
            .second-input {
                display: flex;
                align-items: baseline;
            }

            .first-input {
                margin-right: .5rem;
            }

            input {
                margin-right: .5rem;
            }
        }



        .form-title {
            color: #313830;
            font-weight: 500;
            text-transform: uppercase;
            margin-bottom: 1rem;
            font-size: px-to-rem(26px);
        }


        input {
            display: block;
            border: 1px solid #707070;
            margin-bottom: .75rem;
            padding: .5rem;

            &::placeholder {
                font-weight: 500;
            }
        }

        select {
            display: block;
            width: 100%;
            padding: .5rem .5rem .5rem .75rem;
            font-size: 16px;
            background: #fff;
            border: 1px solid #707070;
        }

        textarea {
            display: block;
            margin-top: 1rem;
            margin-bottom: 2rem;
            width: 100%;
            padding: .75rem;
            height: 10rem;

            &::placeholder {
                font-weight: 500;
            }
        }

        .recaptcha {
            margin-top: 2rem;
            margin-bottom: 2rem;
            width: 100%;
            text-align: -webkit-center;
        }

        .link {
            margin-top: 1rem;
            text-align: center;
        }

    }


}