@import "../../../../css/global.variables.scss";

.ehs-posts-container {
    .widgetBox-wrapper {
        &:nth-child(odd) {
            background: #EBEBE8;
        }

        padding: 2rem;
    }

    .widget-wrapper {
        display: flex;

        a {
            color: #313830;
            font-weight: 500;
            text-decoration: underline;
        }

        img {
            margin-right: 2rem;
            height: 100%;

            @media screen and (max-width: breakpoint(sm)) {
                display: none;
            }
        }

        .content {
            display: flex;
            justify-content: space-between;

            @media screen and (max-width: breakpoint(sm)) {
                display: block;
            }

            .first-section {
                width: 60%;

                @media screen and (max-width: breakpoint(sm)) {
                    width: 100%;
                }
            }

            .label {
                font-weight: 500;
            }
        }

    }
}
