// ********************************************************
// ********************************************************
// **
// **  README
// **
// **  1. Place any file that you want globally available here 
// **     -- Make sure it isn't already imported into another component 
// **  2. This file must be imported into 'app.js'
// **     -- This file should not be imported anywhere else
// **
// ********************************************************
// ********************************************************



// ********************************************************
// ********************************************************
// **
// **  Fonts
// **
// ********************************************************
// ********************************************************


@import url('https://fonts.googleapis.com/css?family=Fira+Sans:300,400,500,700|Fira+Sans+Condensed:300,400,500,700&display=swap');


// ********************************************************
// ********************************************************
// **
// **  Vendor
// **
// ********************************************************
// ********************************************************


@import './vendor/bootstrap/scss/bootstrap.scss';
@import './vendor/slick/slick.scss';


// ********************************************************
// ********************************************************
// **
// **  Global Components
// **
// ********************************************************
// ********************************************************


@import './global-components/theme-globals.styles.scss';
@import './global-components/helper-classes.styles.scss';
@import './global-components/grid.styles.scss';
@import './global-components/font-styling.styles.scss';
@import './global-components/color-scheme.styles.scss';
@import './global-components/animations.styles.scss';
@import './global-components/wysiwyg-modules.scss';
@import './global-components/box-cta-styles.scss';
@import './global-components/scheme_of_color.scss';
//KT add global style for recaptcha 11-18-2021
@import './global.recaptcha.style';