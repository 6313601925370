@import '../../../css/global.variables.scss';

.review-shopping-cart-items {

    .head-titles {
        background: #E9F7FF;
    }

    .edit {
        .fa-pencil {
            cursor: pointer;
        }
    }

    .name {
        .product-name {
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .section-name {}

    .price {
        color: #F1762A;
    }



    .partner-course-container {
        border: 2px solid #999D99;
        padding: 2rem;
        margin-bottom: 2rem;
        width: 100%;

        .title {
            font-size: px-to-rem(24px);
            color: #F1762A;
        }

        .lower-content {
            display: flex;
            justify-content: space-between;

            .title-course {
                font-weight: 500;
            }

            .location {
                font-weight: 300;
            }

            @media screen and (max-width: breakpoint(sm)) {
                display: block;
            }
        }

        .blurb {
            font-style: italic;
            margin-bottom: 0;
            font-size: px-to-rem(14px);
        }
    }


    .image {
        @media screen and (max-width:breakpoint(sm)) {
            display: none;
        }
    }


    .table-header-align {
        text-align: center;
    };

    .discounts-container {

        @media (max-width: breakpoint(sm)) {
            max-width: 70%;
            min-width: 235px;
        }

        >div {

            @media (min-width: breakpoint(sm)) {
                margin-right: .5rem;
            }
        }

        .apply-discounts-btn {
            width: 100%;

            @media (min-width: breakpoint(sm)) {
                width: auto;
                height: 40px;
                padding-top: 0;
                padding-bottom: 0;
                display: flex;
                align-items: center;
            }
        }
    }

    .discount-input-container {

        @media (min-width: breakpoint(sm)) {
            width: 200px;
            max-width: 200px;
        }

        @media (min-width: breakpoint(lg)) {
            width: 278px;
            max-width: 278px;
        }
    }



    .privacy {
        text-align: center;
        font-size: px-to-rem(15px);

        @media (min-width: breakpoint(sm)) {
            text-align: left;
            font-size: px-to-rem(16px);
        }
    }

    .section-seperator {
        margin-bottom: 1.5rem;

        @media (min-width: breakpoint(sm)) {
            margin: 1.75rem 0 1.75rem 0;
        }

        @media (min-width: breakpoint(md)) {
            margin: 2rem 0;
        }

        @media (min-width: breakpoint(lg)) {
            margin: 2.25rem 0;
        }
    }

    .place-order-bottom {
        text-align: -webkit-center;
        margin-top: 3rem;
        margin-bottom: 3rem;

        @media screen and (max-width: 1250px) {
            display: none;
        }
    }

    .fa-exclamation-circle {
        cursor: pointer;
        padding-left: .5rem;
        padding-top: .2rem;
        font-size: px-to-rem(20px);
        color: #ef6008;
    }

    .training-item {
        background-color: #F3F2F2;

    }


}