@import '../../../css/global.variables.scss';

.resources {


    @media screen and (max-width: breakpoint(md)) {
        .row {
            flex-wrap: nowrap;
        }
    }

    @media screen and (max-width: breakpoint(sm)) {
        .row {
            flex-wrap: wrap;
            flex-direction: column-reverse;
        }

    }


    .top-container {
        @media screen and (max-width: breakpoint(sm)) {
            width: 100vw;
            position: relative;
            left: calc(-50vw + 50%);
        }
    }
}