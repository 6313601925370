@import '../../css/global.variables.scss';

* {
  box-sizing: border-box;
}

//
//.row {
//  display: flex;
//  flex-wrap: wrap;
//  justify-content: space-between;
//}
//
//.col-md-3 {
//  flex: 1 1 18%;
//  max-width: 252px;
//
//}
//
//.col-lg-2 {
//  flex: 0 0 20%;
//  max-width: 20%;
//}

.ehs-custom-content {
  margin: 30px 0;
}

.issue-block-1 {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  //border-radius: 20px;
  width: 100%;
  box-shadow: 0px 0px 15px rgba(58, 58, 58, 0.16);
}


.issue-block-below {
  flex-wrap: wrap;
  //border-radius: 20px;
  box-shadow: 0px 0px 15px rgba(58, 58, 58, 0.16);
  height: 100%;
  display: table;
  width: 100%;
}

.ehs-featured-tab {
  display: flex;
  height: 1.5rem;
  padding: 0 1.3rem;
  margin-top: 1rem;
  background-color: #58595b;
  color: #fff;
  text-align: center;
}

.issue-detail-container {
  flex: 0 0 78%;
  max-width: 78%;

  @media screen and (max-width: breakpoint(lg)) {
    flex: 0 0 70%;
    max-width: 70%;
  }
  @media screen and (max-width: breakpoint(md)) {
    flex: 0 0 60%;
    max-width: 60%;
  }
}

.issue-detail-container-below {
  flex: 0 0 10%;
}

.issue-detail-col {
  max-width: fit-content;
  display: -ms-flexbox;
}

.issue-detail-heading {
  font-size: 24px;
  font-weight: 600;
  //color: #00316F;
  margin-bottom: 1.5em;
}

.issue-detail-block {
  padding: 20px 20px 0;
}

.common-button {
  padding: 10px 20px;
  color: white;
  background-color: #EF6008;
  border: none;
  //border-radius: 7px;
  outline: none;
}

.issue-detail-button {
  margin-top: 10px;
}

.issue-detail-subheading {
  margin: 10px 0;
  font-size: px-to-rem(14px);
  color: #000000;
}

.issue-detail-points {
  display: flex;
  align-items: baseline;

  img {
    height: 12px;
    margin-right: 5px;
  }
}

.issue-ehs-detail-image {
  flex: 0 0 22%;
  max-width: 22%;
  min-height: 402px;
  //border-radius: 19px;
  overflow: hidden;

  @media screen and (max-width: breakpoint(lg)) {
    flex: 0 0 30%;
    max-width: 30%;
  }
  @media screen and (max-width: breakpoint(md)) {
    flex: 0 0 40%;
    max-width: 40%;
  }

  img {
    width: 100%;
    //object-fit: cover;
  }
}


.issue-ehs-detail-image-below {
  //border-radius: 19px;
  overflow: hidden;

  img {
    max-width: 100%;
    object-fit: contain;
    //height: 420px;

    @media screen and (max-width: breakpoint(lg)) {
      height: 315px;
    }
    @media screen and (max-width: 768px) {
      height: 445px
    }
    @media screen and (max-width: 425px) {
      height: 480px
    }
    @media screen and (max-width: 375px) {
      height: 420px
    }
  }
}

.issue-block-2 {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
}

.issue-custom-list {
  width: 100%
}

.issue-item {
  flex: 0 0 100%;
  max-width: 100%;
  min-width: 180px;
  padding: 5px;

  /*div {
    height: 300px;
    background-color: lightblue;
    border: 1px solid #212163;
    border-radius: 15px;
  }*/
}

.view-more-button{
  background: transparent;
  //color: #EF6008;
  color: #28AFF7;
  padding: 0;
  font-size: 14px;
}
.search-ehs-container {
  width: calc(20% - 10px);
  background-color: #ffffff;
  //border-radius: 15px;
  box-shadow: 0px 0px 15px rgba(58, 58, 58, 0.16);
  //overflow: hidden;
  margin-bottom: 40px;
  display: block;
  @media screen and (max-width: breakpoint(sm)) {
    display: none;
  }
}

.search-ehs-container-mobile {
  background-color: #ffffff;
  margin-bottom: 20px;
  display: none;
  width: 100%;
  align-self: flex-end;

  @media screen and (max-width: breakpoint(sm)) {
    display: block;
  }
}

.filter-btn {
  display: none;
  @media screen and (max-width: breakpoint(sm)) {
    display: flex;
    flex: 1;
    margin-right: 0;
    justify-content: flex-end;

  }

  .button-style {
    padding: .10rem 1.1rem;
    height: 45px;
    color: white;
    background-color: #EF6008;
    box-shadow: 0px 0px 15px rgba(58, 58, 58, 0.29);
  }
}

.search-ehs-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin: 10px;
  padding: 5px 10px;
  background-color: #f0f4f7;
  //border-radius: 9px;

  input {
    width: calc(100% - 30px);
    background-color: transparent;
    border: none;
    outline: none;
  }

  img {
    height: 16px;
  }
}

.list-magazines-title {
  margin: 15px 20px 5px 20px;
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.old-issues {
  margin: 4px 0;
  padding: 10px 20px;
  line-height: 1;
  font-size: 18px;
  font-weight: 500;
  color: #00316F;
}

.current-year-issues {
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  background-color: #00316F;
}

.current-year--issue-list {
  display: flex;
  align-items: baseline;
  line-height: 1.5;
  font-size: 15px;
  margin-left: 1rem;
  font-weight: normal;
  font-family: "Fira Sans Condensed", "fira sans", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  img {
    height: 12px;
    margin-right: 10px;
  }
}

@media (min-width: 1920px) {
  .issue-detail-heading {
    font-size: 40px;
  }

  .common-button {
    padding: 12px 30px;
    font-size: 20px;
  }

  .issue-detail-subheading {
    font-size: 24px;
  }

  .issue-detail-points {
    a {
      font-size: 20px;
    }
    img {
      height: 20px;
      margin-right: 10px;
    }
  }
}

@media (max-width: 768px) {
  .issue-block-1 {
    flex-direction: column-reverse;
  }
  .issue-ehs-detail-image, .issue-detail-container {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .issue-custom-list {
    width: 100%;
    margin-top: 20px;
  }

  .search-ehs-container {
    width: 100%;
    margin: 0.5rem;
  }

  .issue-item {
    width: 100%;
  }
  .card-col{
    flex: 0 0 50%;
    max-width: 50%;
    margin: 5px 0;
    padding: 0.5rem;
    padding-top: 0 !important;
  }
}
@media (max-width: 425px){
  .card-col{
    flex: 0 0 100%;
    max-width: 100%;
  }
}


body {
  font-family: Helvetica, sans-serif;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2000;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.widget-title {
  padding: 0;
  text-transform: uppercase;
  color: #000000;

  @media screen and (max-width: breakpoint(lg)) {
    font-size: px-to-rem(20px)
  }
  @media screen and (max-width: breakpoint(md)){
    font-size: px-to-rem(20px)
  }

}


.widget-text {


  .content {
    margin-bottom: 0;
    font-size: px-to-rem(16px);
    color: #000000;
    font-weight: 500;


    @media screen and (max-width: breakpoint(lg)) {
      font-size: px-to-rem(16px);
    }
  }

  .widget-single-link {
    display: block;
  }


}

.top-container {
   display: flex;

  margin-bottom: 4rem;
}

.indiv-image {

  width: 100%;

  @media screen and (max-width: breakpoint(sm)) {
    display: block;
  }

  @media (min-width: breakpoint(sm)) {
    position: sticky;
    top: 30px;
  }
}

.button-container {
  align-items: flex-start;
  display: flex;
  margin-bottom: 1rem;
}

.ehs-search-top-bar {
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 10px;
}

.ehs-checkbox-outer-container {
  padding: 10px;
}

.ehs-publication-date {
  margin-bottom: 0.75rem;
  color: #58595b;
  font-weight: 400;
  font-size: 1.1em;
}

.ehs-featured-outer-content {
  margin-top: 0.5rem;
  padding-bottom: 0;
}

.ehs-sub-card-col {
  padding: 0 0.5rem 0.5rem;
  margin: 0 0 3em 0;
}
.ehs-sub-card-col-for-ie {
  padding: 0 0.5rem 0.5rem;
  margin: 0 3em 6em 0;
}
.filter-bar-container {
  border: 0.07em #ccc solid;
  margin-bottom: 2rem;
  padding-top: 1.5rem !important;
  justify-content: space-between;
}
