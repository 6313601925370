@import '../../../../css/global.variables.scss';

.assign-attendee-form {
  margin-bottom: 2.5rem;

  @media (min-width: breakpoint(sm)) {
    margin-bottom: 3.5rem;
  }

  @media (min-width: breakpoint(md)) {
    margin-bottom: 4rem;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .help {
    color: red;
  }

  label {
    margin-bottom: 1rem;
  }

  form {
    display: flex;
    flex-direction: column;

    .input {
      display: block;
      margin-bottom: 1rem;
      width: 100%;
      height: 41px;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    @media (min-width: breakpoint(md)) {
      flex-direction: row;
    }
  }

  .left,
  .right {

    @media (min-width: breakpoint(md)) {
      width: 50%;
    }
  }

  h2 {
    margin-bottom: .75rem;
  }


  .form-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 0.5rem;
    // margin-bottom: 1.5rem;
  }

  .inner-paragraph {
    margin-bottom: .5rem;
  }

  .form-title {
    font-size: px-to-rem(20px);
    line-height: 26px;
    font-weight: 500;

    .attendee-title {
      color: #f1782e;
    }
  }

  .custom-button {
    display: block;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 2px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #9f9f9f;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #000000;
  }

  .assign-attendee-top-heading {
    color: #000000;
    font-size: 18px;
    font-weight: 500;
  }

  .assign-attendees-listing-outer {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    //min-height: 70vh;
    //max-height: 70vh;
    overflow: auto;
    overflow-x: hidden;

    .icon-outer-view {
      cursor: pointer;
    }

    .assign-attendee-warning {
      color: #EF6008;

    }

    .assign-attendee-course-name {
      color: #000000;
      font-size: 16px;
      font-weight: 500;
    }

    .assign-attendee-course-sub-name {
      color: #3e3e3e;
      font-size: 14px;
    }

    .sub-outer-view {
      background-color: #F0F4F7;
      border-radius: 10px;
      padding: 10px;
      margin-top: 0.6rem;
      margin-bottom: 0.5rem;
      margin-right: 0.5rem;

      .sub-top-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .top-attendee-heading {
          font-weight: 400;
          color: #000000;
          font-size: 16px;
        }
      }

      .sub-data-font {
        font-size: 14px;
        color: #7D847F;
        word-wrap: break-word;
      }

      .sub-bottom-bar-view {
        flex-direction: row;
        display: flex;
        flex: 1;

        @media (max-width: breakpoint(sm)) {
          flex-direction: column;
        }

        .left-bottom-bar-view {
          flex: 0.5;
          display: flex;
          flex-direction: column;

          @media (max-width: breakpoint(sm)) {
            flex: 1;
          }
        }

        .right-bottom-bar-view {
          flex: 0.5;
          display: flex;
          flex-direction: column;

          @media (max-width: breakpoint(sm)) {
            flex: 1;
          }
        }
      }

      .bottom-copy-bar-view {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }

  .bottom-dietary-component {
    padding-top: 1rem;

    .caption-text {
      font-weight: 500;
    }


    .label-text {
      color: #495057;
      margin-left: 0.2rem;
    }
  }

  .radio-bottom-dietary-wrapper {
    .radio-button {
      margin-right: 0.3rem;
    }

    .radio-button-right {
      margin-right: 0.3rem;
      margin-left: 0.5rem;
    }

    .radio-wrapper-diet {
      // margin-left: 1rem;

      @media (max-width: breakpoint(sm)) {
        margin-left: 0rem;
      }
    }
  }

  .dietary-drop-down-block {
    .top-caption {
      color: #495057;
    }
  }

  .dietary-other-instruction-block {
    .top-caption {
      color: #495057;
    }
  }
}