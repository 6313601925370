@import '../../css/global.variables.scss';


.trade-shows .wrapper {
  width: 100%;
}

.trade-shows .widgetBox-wrapper {
  margin-bottom: 2rem;

  a {
    color: #313830;
  }

  .outer-link-wrapper {
    margin-top: 2rem;
    margin-bottom: 2rem;

    @media screen and (min-width: breakpoint(lg)) {
      a {
        font-size: px-to-rem(18px);
      }
    }

    @media screen and (max-width: breakpoint(lg)) {
      a {
        font-size: px-to-rem(18px);
      }
    }

    @media screen and (max-width: breakpoint(md)) {
      a {
        font-size: px-to-rem(16px);
      }
    }

    @media screen and (max-width: breakpoint(sm)) {
      a {
        font-size: px-to-rem(14px);
      }
    }
  }


  .content-wrapper {


    .widget-wrapper {
      // display: flex;

      a.learn-link {
        font-size: px-to-rem(14px);
        color: #F17629;
        margin-top: .5rem;
      }

      &:nth-child(odd) {
        background: #EBEBE8;
      }


      @media screen and (min-width: breakpoint(sm)) {
        display: flex;
        justify-content: space-between;
      }

      @media screen and (max-width: breakpoint(sm)) {
        display: block;
      }

      .left-content {
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding-left: 1rem;
        display: flex;

        .inner-content {
          padding-top: .50rem;

          p {
            padding-top: .25rem;
            margin: 0;
          }
        }

        .image {
          img {
            width: 70px;
            height: 90px;
            margin-right: 2rem;
          }

          @media screen and (min-width: breakpoint(md)) {
            margin-right: 2rem;
          }
        }

        .inside-link {
          text-decoration: none;
          font-weight: 500;
          color: #313830;
        }

        @media screen and (min-width: breakpoint(lg)) {
          .date {
            font-size: px-to-rem(14px);
            margin-bottom: 0;
          }

          .inside-link {
            font-size: px-to-rem(20px);
          }
        }


        @media screen and (max-width: breakpoint(lg)) {
          .date {
            font-size: px-to-rem(14px);
            margin-bottom: 0;
          }

          .inside-link {
            font-size: px-to-rem(20px);
          }
        }

        @media screen and (max-width: breakpoint(sm)) {
          padding-top: 1rem;

          .date {
            font-size: px-to-rem(14px);
            margin-bottom: 0;
            padding-bottom: 0;
          }

          .inside-link {
            font-size: px-to-rem(16px);
          }
        }

        p {
          @media screen and (max-width: breakpoint(md)) {
            font-size: px-to-rem(14px);
            padding-right: 3rem;
          }
        }

      }

      .right-content {
        // margin-bottom: 0;
        display: flex;
        align-items: center;
        padding-right: 3rem;

        @media screen and (min-width: breakpoint(lg)) {
          .location {
            font-size: px-to-rem(20px);
          }
        }

        @media screen and (max-width: breakpoint(lg)) {
          .location {
            font-size: px-to-rem(20px);
          }
        }

        @media screen and (max-width: breakpoint(md)) {
          .location {
            font-size: px-to-rem(16px);
          }
        }

        @media screen and (max-width: breakpoint(sm)) {
          padding-left: 1rem;
        }


      }
    }
  }

  @media screen and (min-width: breakpoint(lg)) {
    font-size: px-to-rem(18px);
  }

}
