@import "../../../../css/global.variables.scss";

.website-problem-widget-container {
    background: $widgetBox;
    padding: 2rem;
    margin-left: 3rem;

    @media screen and (max-width: breakpoint(sm)) {
        position: relative;
        width: 100vw;
        left: calc(-50vw + 50%);
        margin-left: 0;
    }



    .title {
        font-weight: 500;
        text-transform: capitalize;

        @media screen and (min-width: breakpoint(md)) {
            font-size: px-to-rem(26px);
        }

        @media screen and (max-width: breakpoint(md)) {
            font-size: px-to-rem(20px);
        }
    }
}