@import '../../../css/global.variables.scss';

.industrialSecondary-a {

    @media screen and (min-width: breakpoint(lg)) {
        .mobile-title-2 {
            font-size: px-to-rem(40px);
        }

    }

    @media screen and (max-width: breakpoint(lg)) {
        .mobile-description {
            font-size: px-to-rem(16px);
            font-weight: 400;
            margin-bottom: 1rem;
            margin-top: 0;
        }


        .mobile-title-2 {
            font-size: px-to-rem(30px);
        }
    }
}